import Vue from "vue";
import set from "lodash/set";
import {
  UPDATE_TEXT_EDITOR_POST,
  RESET_TEXT_EDITOR_POST,
  UPDATE_TEXT_EDITOR_MEDIA,
  RESET_TEXT_EDITOR_MEDIA,
  RESET_EDITOR_STORE_STATE,
  SET_WORKSPACE_EDITOR_CONTENT,
  SET_ACTIVITY_EDITOR_CONTENT,
  SET_WORKSPACE_EDITOR_MEDIA,
  SET_REPLY_EDITOR_CONTENT,
  SET_WORKSPACE_EDITOR_FOLDER,
  RESET_TEXT_EDITOR_FOLDER,
  SET_TASK_COMMENT_EDITOR_CONTENT,
} from "../mutation-types";

const getDefaultState = () => {
  return {
    posts: {},
    media: {},
    replyData: {},
    folders: {},
    comments: {},
  };
};

const state = getDefaultState();

const getters = {
  workspaceTextEditorPost: (state) => {
    return state.posts;
  },
  workspaceTextEditorMedia: (state) => {
    return state.media;
  },
  workspaceEditorContent: (state) => (companyId, workspaceId) => {
    const key = `${companyId}-Workspace-${workspaceId}`;
    return state.posts[key];
  },
  activityEditorContent: (state) => (
    companyId,
    notificationId,
    workspaceId
  ) => {
    const key = `${companyId}-${notificationId}-Activity-${workspaceId}`;
    return state.posts[key];
  },
  activityReplyData: (state) => (companyId, workspaceId, notificationId) => {
    const key = `${companyId}-${notificationId}-Activity-${workspaceId}`;
    return state.replyData[key];
  },
  workspaceEditorMedia: (state) => (companyId, workspaceId) => {
    const key = `${companyId}-Workspace-${workspaceId}`;
    return state.media[key];
  },
  workspaceEditorFolders: (state) => (companyId, workspaceId) => {
    const key = `${companyId}-Workspace-${workspaceId}`;
    return state.folders[key];
  },
  taskCommentEditorContent: (state) => (companyId, workspaceId, taskId) => {
    const key = `${companyId}-Workspace-${workspaceId}-Task-${taskId}`;
    return state.comments[key];
  },
};

const mutations = {
  [UPDATE_TEXT_EDITOR_POST]: (state, payload) => {
    const posts = state.posts;
    const key = payload.companyId + "-" + payload.type + "-" + payload.typeId;
    set(posts, key, {
      ...payload.post,
    });
    state.posts = {
      ...posts,
    };
  },
  [UPDATE_TEXT_EDITOR_MEDIA]: (state, payload) => {
    const media = state.media;
    const key = payload.companyId + "-" + payload.type + "-" + payload.typeId;
    set(media, key, {
      ...payload.media,
    });
    state.media = {
      ...media,
    };
  },
  [RESET_TEXT_EDITOR_POST]: (state, payload) => {
    const posts = state.posts;
    const key = payload.companyId + "-" + payload.type + "-" + payload.typeId;
    delete posts[key];
    state.posts = {
      ...posts,
    };
  },
  [RESET_TEXT_EDITOR_MEDIA]: (state, payload) => {
    const media = state.media;
    const key = payload.companyId + "-" + payload.type + "-" + payload.typeId;
    delete media[key];
    state.media = {
      ...media,
    };
  },
  [RESET_EDITOR_STORE_STATE]: (state) => {
    Object.assign(state, getDefaultState());
  },
  [SET_WORKSPACE_EDITOR_CONTENT]: (
    state,
    { companyId, workspaceId, value }
  ) => {
    const key = `${companyId}-Workspace-${workspaceId}`;
    Vue.set(state.posts, key, value);
  },
  [SET_ACTIVITY_EDITOR_CONTENT]: (
    state,
    { companyId, workspaceId, notificationId, value }
  ) => {
    const key = `${companyId}-${notificationId}-Activity-${workspaceId}`;
    Vue.set(state.posts, key, value);
  },
  [SET_REPLY_EDITOR_CONTENT]: (
    state,
    { companyId, workspaceId, notificationId, value }
  ) => {
    const key = `${companyId}-${notificationId}-Activity-${workspaceId}`;
    Vue.set(state.replyData, key, value);
  },
  [SET_WORKSPACE_EDITOR_MEDIA]: (state, { companyId, workspaceId, value }) => {
    const key = `${companyId}-Workspace-${workspaceId}`;
    Vue.set(state.media, key, value);
  },
  [SET_WORKSPACE_EDITOR_FOLDER]: (state, { companyId, workspaceId, value }) => {
    const key = `${companyId}-Workspace-${workspaceId}`;
    Vue.set(state.folders, key, value);
  },
  [RESET_TEXT_EDITOR_FOLDER]: (state, { companyId, workspaceId, folderId }) => {
    const folders = state.folders;
    const key = `${companyId}-Workspace-${workspaceId}`;
    delete folders[key][folderId];
    state.folders = {
      ...folders,
    };
  },
  [SET_TASK_COMMENT_EDITOR_CONTENT]: (
    state,
    { companyId, workspaceId, taskId, value }
  ) => {
    const key = `${companyId}-Workspace-${workspaceId}-Task-${taskId}`;
    Vue.set(state.comments, key, value);
  },
};

const actions = {
  updateTextEditorPost: ({ commit }, payload) => {
    commit(UPDATE_TEXT_EDITOR_POST, payload);
  },
  resetTextEditorPost: ({ commit }, payload) => {
    commit(RESET_TEXT_EDITOR_POST, payload);
  },
  updateTextEditorMedia: ({ commit }, payload) => {
    commit(UPDATE_TEXT_EDITOR_MEDIA, payload);
  },
  resetTextEditorMedia: ({ commit }, payload) => {
    commit(RESET_TEXT_EDITOR_MEDIA, payload);
  },
  resetEditorStore: ({ commit }) => {
    commit(RESET_EDITOR_STORE_STATE);
  },
  setWorkspaceEditorFolder: ({ commit }, payload) => {
    commit(SET_WORKSPACE_EDITOR_FOLDER, payload);
  },
  resetTextEditorFolder: ({ commit }, payload) => {
    commit(RESET_TEXT_EDITOR_FOLDER, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
