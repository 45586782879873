export const ADD_TO_COMPANIES = "ADD_TO_COMPANIES";
export const SIGNOUT_FROM_COMPANY = "SIGNOUT_FROM_COMPANY";
export const RESET_COMPANIES = "RESET_COMPANIES";
export const SET_ACTIVE_COMPANY = "SET_ACTIVE_COMPANY";
export const SET_LAST_VISIT = "SET_LAST_VISIT";
export const SET_USER_LANG = "SET_USER_LANG";
export const ADD_TO_CONVERSION_CHANNEL = "ADD_TO_CONVERSION_CHANNEL";
export const ADD_TO_POSTS = "ADD_TO_POSTS";
export const UPDATE_TEXT_EDITOR_POST = "UPDATE_TEXT_EDITOR_POST";
export const UPDATE_TEXT_EDITOR_MEDIA = "UPDATE_TEXT_EDITOR_MEDIA";
export const RESET_TEXT_EDITOR_MEDIA = "RESET_TEXT_EDITOR_MEDIA";
export const RESET_TEXT_EDITOR_FOLDER = "RESET_TEXT_EDITOR_FOLDER";
export const RESET_TEXT_EDITOR_POST = "RESET_TEXT_EDITOR_POST";
export const ADD_TO_MEDIA = "ADD_TO_MEDIA";
export const REMOVE_FROM_MEDIA = "REMOVE_FROM_MEDIA";

export const UPDATE_PRESENCE = "UPDATE_PRESENCE";

export const DELETE_POST = "DELETE_POST";
export const REFRESH_MEMBERS = "REFRESH_MEMBERS";

export const UPDATE_BOARD_DETAILS = "UPDATE_BOARD_DETAILS";
export const BOARD_CARD_ADD = "BOARD_CARD_ADD";
export const BOARD_CARD_UPDATE = "BOARD_CARD_UPDATE";
export const BOARD_CARD_DELETE = "BOARD_CARD_DELETE";
export const BOARD_TASK_ADD = "BOARD_TASK_ADD";
export const BOARD_TASK_UPDATE = "BOARD_TASK_UPDATE";
export const BOARD_TASK_MEMBER_UPDATE = "BOARD_TASK_MEMBER_UPDATE";

export const BOARD_TASK_SUBTASK_ADD = "BOARD_TASK_SUBTASK_ADD";
export const BOARD_TASK_SUBTASK_UPDATE = "BOARD_TASK_SUBTASK_UPDATE";
export const BOARD_TASK_SUBTASK_DELETE = "BOARD_TASK_SUBTASK_DELETE";

export const BOARD_TASK_COMMENT_ADD = "BOARD_TASK_COMMENT_ADD";
export const BOARD_TASK_COMMENT_UPDATE = "BOARD_TASK_COMMENT_UPDATE";
export const BOARD_TASK_COMMENT_DELETE = "BOARD_TASK_COMMENT_DELETE";

export const BOARD_TASK_ADD_ATTACHMENT = "BOARD_TASK_ADD_ATTACHMENT";
export const BOARD_TASK_REMOVE_ATTACHMENT = "BOARD_TASK_REMOVE_ATTACHMENT";

export const BOARD_TASK_DELETE = "BOARD_TASK_DELETE";

//
export const RESET_AUTH_STORE_STATE = "RESET_AUTH_STORE_STATE";
export const RESET_CONVERSATION_STORE_STATE = "RESET_CONVERSATION_STORE_STATE";
export const RESET_MEDIA_STORE_STATE = "RESET_MEDIA_STORE_STATE";
export const RESET_EDITOR_STORE_STATE = "RESET_EDITOR_STORE_STATE";
export const RESET_USER_PRESENCE_STORE_STATE =
  "RESET_USER_PRESENCE_STORE_STATE";
export const RESET_WORKSPCE_STORE_STATE = "RESET_WORKSPCE_STORE_STATE";

export const SET_UNREAD_POST_IDS = "SET_UNREAD_POST_IDS";

export const SET_LAST_ROUTE = "SET_LAST_ROUTE";
export const RESET_LAST_ROUTE = "RESET_LAST_ROUTE";
export const WHATS_NEW_BELL = "WHATS_NEW_BELL";
export const SET_DO_NOT_DISTURB = "SET_DO_NOT_DISTURB";
export const RESET_DO_NOT_DISTURB = "RESET_DO_NOT_DISTURB";
export const SET_WHATS_NEW_NOTIFICATION_OFF = "SET_WHATS_NEW_NOTIFICATION_OFF";
export const SET_WHATS_NEW_NOTIFICATION_ON = "SET_WHATS_NEW_NOTIFICATION_ON";

export const COMPANY_STATUS = "COMPANY_STATUS";
export const SET_WALKTHROUGH = "SET_WALKTHROUGH";
export const SET_WORKSPACE_EDITOR_CONTENT = "SET_WORKSPACE_EDITOR_CONTENT";
export const SET_ACTIVITY_EDITOR_CONTENT = "SET_ACTIVITY_EDITOR_CONTENT";

export const SET_WORKSPACE_EDITOR_MEDIA = "SET_WORKSPACE_EDITOR_MEDIA";
export const SET_WORKSPACE_EDITOR_FOLDER = "SET_WORKSPACE_EDITOR_FOLDER";

export const COMPANY_STORAGE_STATUS = "COMPANY_STORAGE_STATUS";
export const SET_REPLY_EDITOR_CONTENT = "SET_REPLY_EDITOR_CONTENT";

export const SET_ONLINE_MEMBERS = "SET_ONLINE_MEMBERS";

export const SET_DRAWER_STATE = "SET_DRAWER_STATE";
export const SET_INFO_SIDEBAR_ACTIVE_TAB = "SET_INFO_SIDEBAR_ACTIVE_TAB";
export const SET_TASK_SIDEBAR_ACTIVE_TAB = "SET_TASK_SIDEBAR_ACTIVE_TAB";
export const SET_ACTIVITY_LOG_EXPANSION_STATE =
  "SET_ACTIVITY_LOG_EXPANSION_STATE";

export const SET_MEMBER_EXPANSION_STATE = "SET_MEMBER_EXPANSION_STATE";
export const SET_DESCRIPTION_EXPANSION_STATE =
  "SET_DESCRIPTION_EXPANSION_STATE";
export const SET_WORKSPACE_NOTIFICATION_SETTING_EXPANSION_STATE =
  "SET_WORKSPACE_NOTIFICATION_SETTING_EXPANSION_STATE";
export const SET_MY_TASK_TAB_CARD_EXPANSION_STATE =
  "SET_MY_TASK_TAB_CARD_EXPANSION_STATE";

export const SET_TASK_DESCRIPTION_STATE = "SET_TASK_DESCRIPTION_STATE";
export const SET_TASK_ATTACHMENT_STATE = "SET_TASK_ATTACHMENT_STATE";
export const SET_CARD_TOGGLE_STATE = "SET_CARD_TOGGLE_STATE";
export const SET_SUB_TASK_ATTACHMENT_STATE = "SET_SUB_TASK_ATTACHMENT_STATE";
export const SET_TASK_VIEW = "SET_TASK_VIEW";
export const TOGGLE_SAVED_ITEMS_BANNER = "TOGGLE_SAVED_ITEMS_BANNER";
export const SET_CALENDAR_VIEW = "SET_CALENDAR_VIEW";
export const SET_FOLDER_STATE = "SET_FOLDER_STATE";
export const TOGGLE_TIMELINE_BANNER = "TOGGLE_TIMELINE_BANNER";
export const TOGGLE_BOARD_BANNER = "TOGGLE_BOARD_BANNER";
export const TOGGLE_ADD_MEMBER_BANNER = "TOGGLE_ADD_MEMBER_BANNER";
export const SET_MEDIA_VIEW = "SET_MEDIA_VIEW";
export const SET_SUBSCRIPTION_PLAN_DATA = "SET_SUBSCRIPTION_PLAN_DATA";
export const SET_IS_DIALOG_SHOW = "SET_IS_DIALOG_SHOW";
export const SET_ALL_FEATURE = "SET_ALL_FEATURE";

export const SET_MY_WORK_CARD_EXPANSION_STATE =
  "SET_MY_WORK_CARD_EXPANSION_STATE";
export const SET_TASK_COMMENT_EDITOR_CONTENT =
  "SET_TASK_COMMENT_EDITOR_CONTENT";
export const SET_FOLDER_SECTION_TOGGLE_STATE =
  "SET_FOLDER_SECTION_TOGGLE_STATE";

export const TOGGLE_DOCUMENT_EDITOR_SIDEBAR_STATE =
  "TOGGLE_DOCUMENT_EDITOR_SIDEBAR_STATE";
