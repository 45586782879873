import {
  REFRESH_MEMBERS,
  RESET_WORKSPCE_STORE_STATE,
  SET_TASK_DESCRIPTION_STATE,
  SET_TASK_ATTACHMENT_STATE,
  SET_CARD_TOGGLE_STATE,
  SET_SUB_TASK_ATTACHMENT_STATE,
  TOGGLE_TIMELINE_BANNER,
  TOGGLE_BOARD_BANNER,
  SET_FOLDER_SECTION_TOGGLE_STATE,
} from "../mutation-types";

const getDefaultState = () => {
  return {
    update: false,
    member: false,
    memberRefresh: false,
    task: {},
    card: {},
    allSectionList: [],
    subtask: {},
    isTimelineBannerVisible: true,
    isBoardBannerVisible: true,
  };
};

const state = getDefaultState();

const getters = {
  updateWorkspace: (state) => {
    return state.update;
  },
  updateMembers: (state) => {
    return state.member;
  },
  memberRefresh: (state) => {
    return state.memberRefresh;
  },
  showDescription: (state) => (taskId) => {
    const key = `Task-${taskId}`;
    return state.task[key];
  },
  showAttachment: (state) => (taskId) => {
    const key = `Task-${taskId}`;
    return state.task[key];
  },
  showCard: (state) => (cardId) => {
    const key = `Card-${cardId}`;
    return state.card[key];
  },
  showSubtaskAttachment: (state) => (subtaskId) => {
    const key = `Subtask-${subtaskId}`;
    return state.subtask[key];
  },
  isTimelineBannerVisible: (state) => {
    return state.isTimelineBannerVisible;
  },
  isBoardBannerVisible: (state) => {
    return state.isBoardBannerVisible;
  },
  allSectionList:(state)=>{
      return state.allSectionList;
  },
};

const mutations = {
  [REFRESH_MEMBERS]: (state, payload) => {
    state.memberRefresh = payload;
  },
  [RESET_WORKSPCE_STORE_STATE]: (state) => {
    Object.assign(state, getDefaultState());
  },
  [SET_TASK_DESCRIPTION_STATE]: (state, payload) => {
    const task = state.task;
    const key = `Task-${payload.taskId}`;
    task[key] = {
      ...task[key],
      showDescription: payload.flag,
    };
    state.task = {
      ...task,
    };
  },
  [SET_TASK_ATTACHMENT_STATE]: (state, payload) => {
    const task = state.task;
    const key = `Task-${payload.taskId}`;
    task[key] = {
      ...task[key],
      showAttachment: payload.flag,
    };
    state.task = {
      ...task,
    };
  },
  [SET_CARD_TOGGLE_STATE]: (state, payload) => {
    const card = state.card;
    const key = `Card-${payload.cardId}`;
    card[key] = {
      ...card[key],
      showCard: payload.flag,
    };
    state.card = {
      ...card,
    };
  },
  [SET_SUB_TASK_ATTACHMENT_STATE]: (state, payload) => {
    const subtask = state.subtask;
    const key = `Subtask-${payload.subtaskId}`;
    subtask[key] = {
      ...subtask[key],
      showSubtaskAttachment: payload.flag,
    };
    state.subtask = {
      ...subtask,
    };
  },
  [TOGGLE_TIMELINE_BANNER]: (state, payload) => {
    state.isTimelineBannerVisible = payload;
  },
  [TOGGLE_BOARD_BANNER]: (state, payload) => {
    state.isBoardBannerVisible = payload;
  },
  [SET_FOLDER_SECTION_TOGGLE_STATE]: (state, payload) => {
    if (!payload.flag && !state.allSectionList.includes(payload.id)) {
      state.allSectionList.push(payload.id);
    } else if (payload.flag && state.allSectionList.includes(payload.id)) {
      state.allSectionList = state.allSectionList.filter(
        (s) => s !== payload.id
      );
    }
    //state.allSectionList.push(payload);
  },
};

const actions = {
  refreshMembers: ({ commit }, payload) => {
    commit(REFRESH_MEMBERS, payload);
  },
  resetWorkspaceStore: ({ commit }) => {
    commit(RESET_WORKSPCE_STORE_STATE);
  },
  setTaskDescriptionState: ({ commit }, payload) => {
    commit(SET_TASK_DESCRIPTION_STATE, payload);
  },
  setTaskAttachmentState: ({ commit }, payload) => {
    commit(SET_TASK_ATTACHMENT_STATE, payload);
  },
  setCardToggleState: ({ commit }, payload) => {
    commit(SET_CARD_TOGGLE_STATE, payload);
  },
  setSubtaskAttachmentState: ({ commit }, payload) => {
    commit(SET_SUB_TASK_ATTACHMENT_STATE, payload);
  },
  toggleTimelineBanner: ({ commit }, payload) => {
    commit(TOGGLE_TIMELINE_BANNER, payload);
  },
  toggleBoardBanner: ({ commit }, payload) => {
    commit(TOGGLE_BOARD_BANNER, payload);
  },
  allSectionList: ({ commit }, payload) => {
    commit(SET_FOLDER_SECTION_TOGGLE_STATE, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
