import {
  ADD_TO_POSTS,
  ADD_TO_MEDIA,
  UPDATE_BOARD_DETAILS,
  BOARD_CARD_ADD,
  BOARD_CARD_UPDATE,
  BOARD_CARD_DELETE,
  BOARD_TASK_ADD,
  BOARD_TASK_UPDATE,
  BOARD_TASK_DELETE,
  BOARD_TASK_MEMBER_UPDATE,
  BOARD_TASK_SUBTASK_ADD,
  BOARD_TASK_SUBTASK_UPDATE,
  BOARD_TASK_SUBTASK_DELETE,
  BOARD_TASK_COMMENT_ADD,
  BOARD_TASK_COMMENT_UPDATE,
  BOARD_TASK_COMMENT_DELETE,
  BOARD_TASK_ADD_ATTACHMENT,
  BOARD_TASK_REMOVE_ATTACHMENT,
  RESET_CONVERSATION_STORE_STATE,
} from "../mutation-types";

import { set, has } from "lodash";

const getDefaultState = () => {
  return {
    conversions: {},
    media: {},
    boards: {},
  };
};

const state = getDefaultState();

const getters = {
  conversions: (state) => {
    return state.conversions;
  },
  media: (state) => {
    return state.media;
  },
  boards: (state) => {
    return state.boards;
  },
};

const mutations = {
  [ADD_TO_POSTS]: (state, payload) => {
    const conversions = state.conversions;
    const key = payload.companyId + "-" + payload.workspace;
    conversions[key] = { flag: payload.flag, data: payload.payload };
    state.conversions = {
      ...conversions,
    };
  },

  [ADD_TO_MEDIA]: (state, payload) => {
    const media = state.media;
    const key = payload.companyId + "-" + payload.workspace;
    media[key] = { flag: payload.flag, data: payload.payload };
    state.media = {
      ...media,
    };
  },
  [UPDATE_BOARD_DETAILS]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;

    if (has(boards, key)) {
      set(boards[key], "comman", payload.flag);
    } else {
      boards[key] = { comman: payload.flag };
    }
    state.boards = {
      ...boards,
    };
  },
  [BOARD_CARD_ADD]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;
    if (has(boards, key)) {
      set(boards[key], "cardadd", payload.payload);
    } else {
      boards[key] = { cardadd: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_CARD_UPDATE]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;

    if (has(boards, key)) {
      set(boards[key], "cardupdate", payload.payload);
    } else {
      boards[key] = { cardupdate: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_CARD_DELETE]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;

    if (has(boards, key)) {
      set(boards[key], "carddelete", payload.payload);
    } else {
      boards[key] = { carddelete: payload.payload };
    }
    state.boards = {
      ...boards,
    };
  },
  [BOARD_TASK_ADD]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;

    if (has(boards, key)) {
      set(boards[key], "taskadd", payload.payload);
    } else {
      boards[key] = { taskadd: payload.payload };
    }
    // if (has(boards, key)) {
    //   set(boards[key], 'taskadd', payload.payload)
    // } else {
    //   boards[key] = { 'taskadd': payload.payload }
    // }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_TASK_UPDATE]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;
    // console.log(payload)
    if (has(boards, key)) {
      set(boards[key], "taskupdate", payload.payload);
    } else {
      boards[key] = { taskupdate: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_TASK_DELETE]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;
    if (has(boards, key)) {
      set(boards[key], "taskdelete", payload.payload);
    } else {
      boards[key] = { taskdelete: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_TASK_MEMBER_UPDATE]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;
    // console.log(payload)
    if (has(boards, key)) {
      set(boards[key], "taskmember", payload.payload);
    } else {
      boards[key] = { taskmember: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_TASK_SUBTASK_ADD]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;
    // console.log(payload)
    if (has(boards, key)) {
      set(boards[key], "addsubtask", payload.payload);
    } else {
      boards[key] = { addsubtask: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_TASK_SUBTASK_UPDATE]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;
    // console.log(payload)
    if (has(boards, key)) {
      set(boards[key], "subtaskupdate", payload.payload);
    } else {
      boards[key] = { subtaskupdate: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_TASK_SUBTASK_DELETE]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;
    // console.log(payload)
    if (has(boards, key)) {
      set(boards[key], "subtaskdelete", payload.payload);
    } else {
      boards[key] = { subtaskdelete: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_TASK_COMMENT_ADD]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;
    // console.log(payload)
    if (has(boards, key)) {
      set(boards[key], "addtaskcomment", payload.payload);
    } else {
      boards[key] = { addtaskcomment: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_TASK_COMMENT_UPDATE]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;
    // console.log(payload)
    if (has(boards, key)) {
      set(boards[key], "taskcommentupdate", payload.payload);
    } else {
      boards[key] = { taskcommentupdate: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_TASK_COMMENT_DELETE]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;
    // console.log(payload)
    if (has(boards, key)) {
      set(boards[key], "taskcommentdelete", payload.payload);
    } else {
      boards[key] = { taskcommentdelete: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_TASK_ADD_ATTACHMENT]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;
    // console.log(payload)
    if (has(boards, key)) {
      set(boards[key], "taskattachmedia", payload.payload);
    } else {
      boards[key] = { taskattachmedia: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [BOARD_TASK_REMOVE_ATTACHMENT]: (state, payload) => {
    const boards = state.boards;
    const key =
      payload.companyId + "-" + payload.workspace + "-" + payload.boardId;
    // console.log(payload)
    if (has(boards, key)) {
      set(boards[key], "taskdetachmedia", payload.payload);
    } else {
      boards[key] = { taskdetachmedia: payload.payload };
    }

    state.boards = {
      ...boards,
    };
  },
  [RESET_CONVERSATION_STORE_STATE]: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  addToPosts: ({ commit }, payload) => {
    commit(ADD_TO_POSTS, payload);
  },
  addToMedia: ({ commit }, payload) => {
    commit(ADD_TO_MEDIA, payload);
  },
  updateBoard: ({ commit }, payload) => {
    commit(UPDATE_BOARD_DETAILS, payload);
  },
  newCardAdded: ({ commit }, payload) => {
    commit(BOARD_CARD_ADD, payload);
  },
  cardUpdate: ({ commit }, payload) => {
    commit(BOARD_CARD_UPDATE, payload);
  },
  cardDelete: ({ commit }, payload) => {
    commit(BOARD_CARD_DELETE, payload);
  },
  resetConversationStore: ({ commit }) => {
    commit(RESET_CONVERSATION_STORE_STATE);
  },
  newTaskAdded: ({ commit }, payload) => {
    commit(BOARD_TASK_ADD, payload);
  },
  taskUpdate: ({ commit }, payload) => {
    commit(BOARD_TASK_UPDATE, payload);
  },
  taskDelete: ({ commit }, payload) => {
    commit(BOARD_TASK_DELETE, payload);
  },
  taskMembersUpdate: ({ commit }, payload) => {
    commit(BOARD_TASK_MEMBER_UPDATE, payload);
  },
  subtaskAdded: ({ commit }, payload) => {
    commit(BOARD_TASK_SUBTASK_ADD, payload);
  },
  subtaskUpdate: ({ commit }, payload) => {
    commit(BOARD_TASK_SUBTASK_UPDATE, payload);
  },
  subtaskDeleted: ({ commit }, payload) => {
    commit(BOARD_TASK_SUBTASK_DELETE, payload);
  },
  taskCommentAdded: ({ commit }, payload) => {
    commit(BOARD_TASK_COMMENT_ADD, payload);
  },
  taskCommentUpdate: ({ commit }, payload) => {
    commit(BOARD_TASK_COMMENT_UPDATE, payload);
  },
  taskCommentDeleted: ({ commit }, payload) => {
    commit(BOARD_TASK_COMMENT_DELETE, payload);
  },
  taskAddAttachment: ({ commit }, payload) => {
    commit(BOARD_TASK_ADD_ATTACHMENT, payload);
  },
  taskRemoveAttachment: ({ commit }, payload) => {
    commit(BOARD_TASK_REMOVE_ATTACHMENT, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
