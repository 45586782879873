import { SET_DO_NOT_DISTURB, RESET_DO_NOT_DISTURB } from "../mutation-types";

const getDefaultState = () => {
  return {
    disableNotificationCompany: null,
    flag: false
  };
};

const state = getDefaultState();

const getters = {
  disablenotification: state => {
    return {
      disableNotificationCompany: state.disableNotificationCompany,
      flag: state.flag
    };
  }
};

const mutations = {
  [SET_DO_NOT_DISTURB]: (state, payload) => {
    const { companyId } = payload;
    state.disableNotificationCompany = companyId;
    state.flag = true;
  },
  [RESET_DO_NOT_DISTURB]: state => {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  disableNotification: ({ commit }, payload) => {
    commit(SET_DO_NOT_DISTURB, payload);
  },
  enableNotification: ({ commit }) => {
    commit(RESET_DO_NOT_DISTURB);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
