import { store } from "./store";

let lastSocketId = null;
let requestPending;
let requestPromise;
let response;

export const pusher = new window.Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
  cluster: "mt1",
  // wsHost: "164.90.158.10",
  // wsPort: 4343,
  // enabledTransports: ["wss"],
  forceTLS: true,
  authorizer: function(channel) {
    return {
      authorize: async function(socketId, callback) {
        if (socketId !== lastSocketId) {
          lastSocketId = socketId;
          await makeRequest(socketId);
        }
        if (response) {
          const foundChannel = response.data.channels.find(
            (c) => c.name === channel.name
          );
          if (foundChannel) {
            callback(false, JSON.parse(foundChannel.auth));
          } else {
            await makeRequest(socketId);
          }
        } else {
          if (requestPending) {
            await requestPromise;
          } else {
            await makeRequest(socketId);
          }
        }
        const foundChannel = response.data.channels.find(
          (c) => c.name === channel.name
        );
        if (foundChannel) {
          callback(false, JSON.parse(foundChannel.auth));
        } else {
          throw new Error(`Unauthorized channel: ${channel.name}`);
        }
      },
    };
  },
});

async function makeRequest(socketId) {
  response = null;
  requestPending = true;
  requestPromise = fetch(process.env.VUE_APP_GRAPH_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getters.accessToken}`,
      Pusher: pusher.connection.socket_id,
    },
    body: JSON.stringify({
      query: `query ($socket_id: String) {
        channels(socket_id: $socket_id) {
            name
            auth
        }
    }`,
      variables: { socket_id: socketId },
    }),
  }).then((res) => res.json());
  response = await requestPromise;
  requestPending = false;
}

window.x = pusher;
