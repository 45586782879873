import { TOGGLE_DOCUMENT_EDITOR_SIDEBAR_STATE } from "../mutation-types";

const getDefaultState = () => {
  return {
    sidebarState: {},
  };
};
const state = getDefaultState();

const getters = {
  isSidebarOpen: (state) => (documentId) => {
    const key = `Document-${documentId}`;
    return state.sidebarState[key] ? state.sidebarState[key].isOpen : true;
  },
};
const mutations = {
  [TOGGLE_DOCUMENT_EDITOR_SIDEBAR_STATE]: (state, payload) => {
    const sidebarState = state.sidebarState;
    const key = `Document-${payload.documentId}`;
    sidebarState[key] = {
      ...sidebarState[key],
      isOpen: state.sidebarState[key] ? !state.sidebarState[key].isOpen : false,
    };
    state.sidebarState = {
      ...sidebarState,
    };
  },
};

const actions = {
  toggleSidebarState: ({ commit }, payload) => {
    commit(TOGGLE_DOCUMENT_EDITOR_SIDEBAR_STATE, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
