const exception = {
  WorkspaceTitleAlreadyTaken: "Workspace title already taken",
  InvalidWorkspaceTitleError: "Workspace title is invalid",
  UserNotFoundError: "E-mail address does not exist with the company",
  CompanyNotFoundError: "Company does not exist",
  CompanyInactiveError:
    "Company is inactive! Please contact your administrator.",
  CompanyURIAlreadyTaken: "Company URL already taken",
  CompanyURIInvalid: "Company URL is invalid",
  InvalidUsername: "Username is invalid",
  InvalidVerificationCodeError: "Verification code is invalid",
  CardDeclined: "Card Details is invalid",
  APINetwork: "Network problem, perhaps try again.",
  AccessDenied: "Access Denied",
  InvalidRequest: "Invalid Request",
  StripeServerDown: `Stripe's servers are down!`,
  InvitationNotFound: "No Invitation found",
  PermissionDenied: "You have not permission",
  UserAlreadyExists: "User is already exist",
  UsernameAlreadyTaken: "Username is already taken",
  InvalidCouponCodeError: "Coupon is invalid",
  CouponNotApplicableError: "You have already applied coupon",
  UserAlreadyInvited: "You have already already invited this user",
  InvitationExceded: "InvitationExceded",
  StorgeExcessiveUsed:
    "You can not downgrade. Your storage utlization is more than requested plan."
};

export default exception;
