const CollaborationViewRedirect = {
  path: "",
  redirect: (to) => ({
    name: "BoardView",
    params: { company: to.params.company, workspace: 1 },
  }),
};
export const routes = [
  {
    path: "/",
    component: () => import("@/views/PublicView"),
    children: [
      {
        path: "",
        component: () => import("@/views/SigninView"),
        name: "SigninView",
      },
      {
        path: "/signin",
        component: () => import("@/views/SigninView"),
        name: "SigninView",
      },
      {
        path: "/signup",
        component: () => import("@/views/SignupView"),
        name: "SignupView",
      },
      {
        path: "/companies/:company/invitation",
        component: () => import("@/views/InvitationView"),
        name: "InvitationView",
      },
    ],
  },
  {
    meta: { requiresAuth: true },
    path: "/companies/:company",
    component: () => import("@/views/CompanyView"),
    name: "CompanyView",
    children: [
      CollaborationViewRedirect,
      {
        meta: { requiresPremiumPlan: true, slug: "my_work" },
        path: ":userId/tasks",
        name: "MemberView",
        component: () => import("@/views/MyWorkView/MemberView"),
      },
      {
        meta: { requiresPremiumPlan: true, slug: "team_dashboard" },
        path: "my-team",
        name: "AdminView",
        component: () => import("@/views/MyWorkView/AdminView"),
      },
      {
        path: "saved-items",
        name: "SavedItemsView",
        component: () => import("@/views/SavedItemsView"),
      },
      {
        path: "learn",
        name: "LearnView",
        component: () => import("@/views/LearnView"),
      },
      {
        path: "workspaces",
        name: "WorkspacesView",
        component: () => import("@/views/WorkspacesView"),
        children: [
          CollaborationViewRedirect,
          {
            path: ":workspace",
            name: "WorkspaceView",
            component: () => import("@/views/WorkspaceView"),
            children: [
              CollaborationViewRedirect,
              {
                path: "messaging",
                name: "MessagingView",
                component: () => import("@/views/MessagingView"),
              },
              {
                path: "board",
                name: "BoardView",
                component: () => import("@/views/BoardView"),
              },
              {
                path: "media",
                name: "MediaView",
                component: () => import("@/views/MediaView"),
              },
            ],
          },
        ],
      },
      {
        path: "payment",
        name: "PaymentView",
        component: () => import("@/components/PaymentView"),
      },
    ],
  },
  {
    path: "*",
    name: "NotFoundView",
    // component: () => import("@/views/NotFoundView"),
    redirect: "/",
  },
];
