import Plyr from "plyr";
import "plyr/dist/plyr.css";
// import "core-js/modules/web.dom.iterable";
// import "core-js/modules/es7.array.includes";
// import "core-js/modules/es6.string.includes";
// import "core-js/modules/es6.function.name";
// import "core-js/modules/es6.object.keys";
import Vue from "vue";

var script = {
  name: "vue-plyr",
  props: {
    /** Options object for plyr config. */
    options: {
      type: Object,
      required: false,
      default: function _default() {
        return {
          hideYouTubeDOMError: true,
          autoplay: true,
          autopause: false,
          resetOnEnd: true,
          controls: [
            "play-large",
            "play",
            "progress",
            "current-time",
            "mute",
            "volume",
            "pip",
            "airplay",
            "fullscreen",
          ],
        };
      },
    },

    /** Array of events to emit from the plyr object */
    emit: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
    },
  },
  data: function data() {
    return {
      player: {},
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.player = new Plyr(this.$el.firstChild, this.options);
    this.$emit("player", this.player);
    this.emit.forEach(function(element) {
      _this.player.on(element, _this.emitPlayerEvent);
    });
  },
  beforeDestroy: function beforeDestroy() {
    // try {
    this.player.destroy();
    // } catch (e) {
    //   if (!(this.options.hideYouTubeDOMError && e.message === "The YouTube player is not attached to the DOM.")) {
    //     console.error(e);
    //   }
    // }
  },
  methods: {
    emitPlayerEvent: function emitPlayerEvent(event) {
      this.$emit(event.type, event);
    },
  },
};

var vuescript = script;
/* template */

var vuerender = function vuerender() {
  var _vm = this;

  var _h = _vm.$createElement;

  var _c = _vm._self._c || _h;

  return _c("div", [_vm._t("default")], 2);
};

var vuestaticRenderFns = [];
vuerender._withStripped = true;
/* style */

var vueinjectstyles = "undefined";
/* scoped */
var vuescopeid = "undefined";
/* module identifier */

var vuemoduleidentifier = "undefined";
/* functional template */

var vueisfunctionaltemplate = false;
/* component normalizer */

function vuenormalize(
  template,
  style,
  script$$1,
  scope,
  functional
  // moduleIdentifier,
  // createInjector,
  // createInjectorSSR
) {
  let component =
    (typeof script$$1 === "function" ? script$$1.options : script$$1) || {};
  // component.__file = 'VuePlyr.vue';

  if (!component.render) {
    component.render = template.render;
    component.staticRenderFns = template.staticRenderFns;
    component._compiled = true;
    if (functional) component.functional = true;
  }

  component._scopeId = scope;
  return component;
}
/* style inject */
function vuecreateinjector() {
  var head = document.head || document.getElementsByTagName("head")[0];
  var styles = vuecreateinjector.styles || (vuecreateinjector.styles = {});
  var isOldIE =
    typeof navigator !== "undefined" &&
    /msie [6-9]\\b/.test(navigator.userAgent.toLowerCase());
  return function addStyle(id, css) {
    if (document.querySelector('style[data-vue-ssr-id~="' + id + '"]')) return; // SSR styles are present.

    var group = isOldIE ? css.media || "default" : id;
    var style =
      styles[group] ||
      (styles[group] = {
        ids: [],
        parts: [],
        element: undefined,
      });

    if (!style.ids.includes(id)) {
      var code = css.source;
      var index = style.ids.length;
      style.ids.push(id);

      if (isOldIE) {
        style.element =
          style.element ||
          document.querySelector("style[data-group=" + group + "]");
      }

      if (!style.element) {
        var el = (style.element = document.createElement("style"));
        el.type = "text/css";
        if (css.media) el.setAttribute("media", css.media);

        if (isOldIE) {
          el.setAttribute("data-group", group);
          el.setAttribute("data-next-index", "0");
        }

        head.appendChild(el);
      }

      if (isOldIE) {
        index = parseInt(style.element.getAttribute("data-next-index"));
        style.element.setAttribute("data-next-index", index + 1);
      }

      if (style.element.styleSheet) {
        style.parts.push(code);
        style.element.styleSheet.cssText = style.parts
          .filter(Boolean)
          .join("\n");
      } else {
        var textNode = document.createTextNode(code);
        var nodes = style.element.childNodes;
        if (nodes[index]) style.element.removeChild(nodes[index]);
        if (nodes.length) style.element.insertBefore(textNode, nodes[index]);
        else style.element.appendChild(textNode);
      }
    }
  };
}
/* style inject SSR */
var VuePlyr = vuenormalize(
  {
    render: vuerender,
    staticRenderFns: vuestaticRenderFns,
  },
  vueinjectstyles,
  vuescript,
  vuescopeid,
  vueisfunctionaltemplate,
  vuemoduleidentifier,
  vuecreateinjector,
  undefined
);

var Components = {
  VuePlyr: VuePlyr,
};
Object.keys(Components).forEach(function(component) {
  Vue.component(Components[component].name, Components[component]);
});

export default Components;
