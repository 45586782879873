const validation = {
  email: {
    required: "Email field is required",
    email: "Email must be valid",
    alreadyExist: "Email is already register",
  },
  password: {
    required: "Password is required",
    confirm: "Password does not match",
    min: "At least 8 characters required",
    max: "Max 32 characters",
    validPassword:
      "Password must have [A-Z], [a-z], [0-9] and one special character",
  },
  username: {
    required: "Username field is required",
    uniqueUser: "Username must be valid",
    min: "Username must have at least four characters",
    max: "Username must not be more than 40 characters",
    alreadyExist: "Username is already taken",
  },
  verificationCode: {
    required: "Verification code is required",
  },
  userprofile: {
    first: {
      required: "First name is required",
      min: "First name must have at least two characters",
      max: "First name must not be more than 40 characters",
    },
    last: {
      required: "Last name is required",
      min: "Last name must have at least two characters",
      max: "Last name is not more than 40 characters",
    },
    phone: {
      maxLength: "Max 15 characters",
      minLength: "At least 7 digits are required",
      valid: "Phone number must be valid",
    },
    skype: {
      maxLength: "Max 32 characters",
      minLength: "At least 2 characters are required",
    },
    professional_title: {
      maxLength: "Max 60 characters",
    },
    country: {
      valid: `Country name must begin with alpahbets, following alphabets, number or special characters`,
      maxLength: "Max 120 characters",
      minLength: "At least 2 characters are required",
    },
    city: {
      valid: `City name can combination of alphabates, numbers and [~&-':.] between alphabates/numbers.`,
      maxLength: "Max 120 characters",
      minLength: "At least 2 characters are required",
    },
  },
  questions: {
    usage: { required: "Usage is required" },
    kind: { required: "kind is required" },
    strength: { required: "strength is required" },
  },
  company: {
    name: {
      required: "Company name is required field",
      min: "Company name must have at least two characters",
      max: "Company name is not more than 64 characters",
    },
    uri: {
      required: "Company URL is required field",
      validurl:
        "Company URL can have [a-z 0-9] and dash(-) without any special characters",
      min: "Company URL must have at least two characters",
      max: "Company URL is not more than 40 characters",
    },
    agreeToPolicy: {
      required: "Please agree to policy",
    },
    notExist: "Company does not exist.",
  },
  workspace: {
    name: {
      required: "Workspace name is required",
      max: "Maximun characters 32",
      valid:
        "Workspace name can only have [A-Z], [a-z], [0-9] or dash/underscore without any space.",
    },
    description: {
      max: "Maximun characters 700",
    },
  },
  credentialNotMatch: "Could not match credentials",
  lang: {
    required: "Choose your language",
  },
  file: {
    size: "File is too large",
    profileSize: "Image size must be less than 25MB",
  },
  url: {
    required: "Field can not be empty",
    url: "URL is not valid",
  },
};

export default validation;
