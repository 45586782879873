import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import auth from "./modules/auth";
import saveditems from "./modules/saveditems";
import conversation from "./modules/conversation";
import lastroute from "./modules/lastroute";
import disablenotification from "./modules/disablenotification";
import disablewhatsnewnotification from "./modules/disablewhatsnewnotification";
import userpresence from "./modules/userpresence";
import workspace from "./modules/workspace";
import textEditor from "./modules/textEditor";
import drawer from "./modules/drawer";
import taskboard from "./modules/taskboard";
import VuexPersistence from "vuex-persist";
import calendarview from "./modules/calendarview";
import media from "./modules/media";
import subscriptionPlanData from "./modules/subscription";
import mywork from "./modules/mywork";
import documenteditor from "./modules/documenteditor";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export const store = new Vuex.Store({
  state: {},
  getters,
  mutations,
  actions,
  plugins: [vuexLocal.plugin],
  modules: {
    auth,
    conversation,
    saveditems,
    workspace,
    textEditor,
    userpresence,
    lastroute,
    disablenotification,
    disablewhatsnewnotification,
    drawer,
    taskboard,
    calendarview,
    media,
    subscriptionPlanData,
    mywork,
    documenteditor,
  },
});

export function resetState() {}
