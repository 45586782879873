import { SET_TASK_VIEW } from "../mutation-types";

const getDefaultState = () => {
  return {
    taskView: {},
  };
};

const state = getDefaultState();

const getters = {
  currentView: (state) => (boardId) => {
    const key = `Board-${boardId}`;
    return state.taskView[key];
  },
};
const mutations = {
  [SET_TASK_VIEW]: (state, payload) => {
    const taskView = state.taskView;
    const key = `Board-${payload.boardId}`;
    taskView[key] = {
      ...taskView[key],
      view: payload.view,
    };
    state.taskView = {
      ...taskView,
    };
  },
};
const actions = {
  setTaskView: ({ commit }, payload) => {
    commit(SET_TASK_VIEW, payload);
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
