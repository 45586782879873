import { SET_MEDIA_VIEW } from "../mutation-types";

const getDefaultState = () => {
  return {
    mediaView: {},
  };
};

const state = getDefaultState();

const getters = {
  currentMediaView: (state) => (workspaceId) => {
    const key = `Media-${workspaceId}`;
    return state.mediaView[key];
  },
};

const mutations = {
  [SET_MEDIA_VIEW]: (state, payload) => {
    const mediaView = state.mediaView;
    const key = `Media-${payload.workspaceId}`;
    mediaView[key] = {
      ...mediaView[key],
      view: payload.view,
    };
    state.mediaView = {
      ...mediaView,
    };
  },
};

const actions = {
  setMediaView: ({ commit }, payload) => {
    commit(SET_MEDIA_VIEW, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
