const node = `
  __typename
  id
`;

const UserFields = `
  first
  last
  username
  pic
`;
const MediaFields = `
  file
  file_resource
  filename
  type
  size
  thumb
  isbookmarked
  sequence
  width
  document_content
  is_document_section
  document_state
  document_version
  created_at
  updated_at
`;
const SubscriptionFields = `
  base_plan
  storage_plan
  status
  is_downgrade_plan
  plan_id
  current_period_end
  couponcodetext
  plan{
    id
    name
    is_unlimited
    planPricing{
      id
      amount
      validity
      price_id
    }
  }
`;
const CompanyFields = `
  name
  uri
  about
  public_link
  start_task_id
  start_time_log_id
`;
const PostFields = `
  content
  isbookmarked
  is_bot
  can_delete
  updated_at
  created_at
`;
const LabelFields = `
  name
  color
  colorcode
  user_id
`;

const SubtaskFields = `
  title
  completed
  sequence
  subtaskreactionset
  subtaskreactiondata
`;
const TaskFields = `
  title
  details
  completed
  start_date
  end_date
  calllink
  sequence
  card_id
  last_label
  cover_image
  rating
  is_lock
  is_invisible
`;
const WorkspaceFields = `
  title
  peopletitle
  status
  type
  is_general
  description
  details
  show_tasks
  show_messages
  show_files
`;

const RoleFields = `
  name
  label
`;

const OnboardingFields = `
  is_messaging
  is_mywork
  is_private_tasks
  is_public_tasks
  is_files
  is_about
  is_notifications
  is_visual_comments
  is_image_viewer
  is_folder_detail
`;

const AddressFields = `
  street_one: street1
  street_two: street2
  city
  state
  country
  zipcode
`;

const BoardTemplateFields = `
  title
  user_id
  workspace_id
`;
const FolderFields = `
  title
  user_id
`;

const BookmarkFolderFields = `
  title
  user_id
  workspace_id
`;

const BookmarkFields = `
  title
  link
  user_id
  workspace_id
`;
const MediaFolderFields = `
  title
  user_id
  media_count
  created_at
  workspace_id
  bookmarkdate
  isbookmarked
  isgridview
`;
const MediaCardFields = `
      title
      sequence
      user_id
`;

const PlanContactusFields = `
  first
  last
  email
  company_id
  phone
  user_limit
`;
export const UserQuery = `
  query {
    user {
      ${node}
      ${UserFields}
      email
      status
      lang
      status_id
      userStatus {
        id
        name
        icon
      }
      roles {
        ${node}
        ${RoleFields}
      }
      chat_status
      user_status
      designation
      phone
      timezone
      skype
      country
      city
      created_at
      last_session_at
      web_notification
      email_notification
      onboarding {
        ${OnboardingFields}
      }
      onboarding_step,
      hourly_rate
    }
  }
`;

export const CompanyQuery = `
  query($uri: String) {
    company(uri: $uri) {
      ${node}
      ${CompanyFields}
      logo
      status
      subscription {
        ${node}
        ${SubscriptionFields}
        base_plan
        status
        is_downgrade_plan
        plan_id
        current_period_end
      }
      members{
        ${node}
        ${UserFields}
      }
    }
  }
`;

export const ReactionsQuery = `
  query {
    reactions {
      ${node}
      name
      icon
    }
  }
`;

export const TaskLabelsQuery = `
  query {
    taskLabels {
      ${node}
      ${LabelFields}
    }
}
`;

export const RolesQuery = `
  query {
    roles {
      ${node}
      ${RoleFields}
    }
  }
`;

export const MembersQuery = `
  query {
    members {
      ${node}
      ${UserFields}
      fullname
      email
      status
      status_id
      userStatus {
        id
        name
        icon
      }
      roles {
        ${node}
        name
      }
    }
  }
`;

export const MediaQuery = `
  query(
    $workspace_id: Int!
    $limit: Int
    $offset: Int
    $cursor: Int
    $cursor_type: String
  ) {
    media(
      workspace_id: $workspace_id
      limit: $limit
      offset: $offset
      cursor: $cursor
      cursor_type: $cursor_type
    ) {
      ${node}
      ${MediaFields}
      workspace_id
      user_id
      is_read_only
      commentPoint {
        ${node}
        media_id
        user_id
        x
        y
        last_label
      }
      comments {
        ${node}
        comment
        owner {
          ${node}
          ${UserFields}
        }
        media_comments_point_id
        created_at
        updated_at
      }
    }
  }
`;
export const NewMediaQuery = `
  query(
    $workspace_id: Int!
    $limit: Int
    $offset: Int
    $cursor: Int
    $cursor_type: String
    $search: String
  ) {
    newMedia(
      workspace_id: $workspace_id
      limit: $limit
      offset: $offset
      cursor: $cursor
      cursor_type: $cursor_type
      search: $search
    ) {
      ${node}
      ${MediaFields}
      workspace_id
      user_id
      document_state
      media_folder_id
      is_read_only
      commentPoint {
        ${node}
        media_id
        user_id
        x
        y
        last_label
      }
      comments {
        ${node}
        comment
        owner {
          ${node}
          ${UserFields}
        }
        media_comments_point_id
        created_at
        updated_at
      }
    }
  }
`;
export const DocumentsQuery = `
  query(
    $workspace_id: Int!
  ) {
    document(
      workspace_id: $workspace_id
    ) {
      ${node}
      ${MediaFields}
      workspace_id
      user_id
      document_state
      media_folder_id
      is_read_only
    }
  }
`;
const TimeLogFields = `
  user_id
  task_id
  workspace_id
  description
  start_date
  end_date
  is_start
`;

const MyWorkFields = `
  user_id
  task_id
  workspace_id
  user_card_id
  user_sequence
`;
export const BoardQuery = `
  query($id: Int!) {
    board(id: $id) {
      ${node}
      title
      gradient
      bgimg
      gradient_list
      bgimg_list
      background {
        id
        user_id
        board_id
        gradient
        bgimg
        gradient_list
        bgimg_list
      }
      cards {
        ${node}
        title
        board_id
        sequence
        user_id
        color
        is_lock
        is_invisible
        owner {
          ${node}
          ${UserFields}
        }
      }
      tasks {
        ${node}
        ${TaskFields}
        isbookmarked
        board_id
        rating
        timelogs {
          ${node}
          ${TimeLogFields}
          owner {
            ${node}
            ${UserFields}
            hourly_rate
          }
        }
        activetimelog {
          ${node}
          ${TimeLogFields}
        }
        labels {
          ${node}
          ${LabelFields}
        }
        members {
          ${node}
          ${UserFields}
          fullname
        }
        owner {
          ${node}
          ${UserFields}
        }
        customFieldOptionSelection {
          ${node}
          task_id
          c_f_id
          c_f_o_id
          option_text
        }
        subtasks {
          ${node}
          ${SubtaskFields}
          end_date
          details
          childSubtasks{
            ${node},
            ${SubtaskFields}
            end_date
            details
            mediafolder{
              ${node}
              ${MediaFolderFields}
            }
            media {
              ${node}
            }  
          }
          owner {
            ${node}
            first
            last
            pic
          }
          mediafolder{
            ${node}
            ${MediaFolderFields}
          }
          members {
            ${node}
            ${UserFields}
            fullname
          }
          media {
            ${node}
            workspace_id
            user_id
            ${MediaFields}
            commentPoint {
              ${node}
              media_id
              user_id
              x
              y
              last_label
            }
            comments {
              ${node}
              comment
              owner {
                ${node}
                ${UserFields}
              }
              media_comments_point_id
              created_at
              updated_at
            }
          }
        }
        media{
          ${node}
          file
        }
        owner {
          ${node}
          ${UserFields}
        }
        note{
          id
          note
          document_version
        }
      }
    }
  }
`;

export const PrivateTasksQuery = `
query($board_id: Int!) {
  privateTasks(board_id: $board_id){
    ${node}
    ${TaskFields}
    board_id
    isbookmarked
    labels {
      ${node}
      ${LabelFields}
    }
    members {
      ${node}
      ${UserFields}
      fullname
    }
    owner {
      ${node}
      ${UserFields}
    }
    subtasks {
      ${node}
      completed
      members {
        ${node}
        ${UserFields}
        fullname
      }
    }
    timelogs {
      ${node}
      ${TimeLogFields}
      owner {
        ${node}
        ${UserFields}
      }
    }
    activetimelog {
      ${node}
      ${TimeLogFields}
    }
  }
}
`;

export const TaskQuery = `
  query($id: Int!) {
    task(id: $id) {
      ${node}
      ${TaskFields}
      owner {
        ${node}
        first
        last
        pic
      }
      labels {
        ${node}
        ${LabelFields}
      }
      board_id
      card{
        board{
          workspace_id
        }  
      }
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      members {
        ${node}
        ${UserFields}
        fullname
      }
      subtasks {
        ${node}
        ${SubtaskFields}
        end_date
        details
        childSubtasks{
          ${node},
          ${SubtaskFields}
          end_date
          details
          mediafolder{
            ${node}
            ${MediaFolderFields}
          }
          media {
            ${node}
          }  
        }
        owner {
          ${node}
          first
          last
          pic
        }
        mediafolder{
          ${node}
          ${MediaFolderFields}
        }
        members {
          ${node}
          ${UserFields}
          fullname
        }
        media {
          ${node}
          workspace_id
          user_id
          ${MediaFields}
          commentPoint {
            ${node}
            media_id
            user_id
            x
            y
            last_label
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              ${UserFields}
            }
            media_comments_point_id
            created_at
            updated_at
          }
        }
      }
      media {
        ${node}
        workspace_id
        user_id
        created_at
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
      customFieldOptionSelection {
        ${node}
        task_id
        c_f_id
        c_f_o_id
        option_text
      }
      note{
        id
        note
        document_version
      }
    }
  }
`;

export const SubtaskQuery = `
  query($id: Int!) {
    subtask(id: $id) {
      ${node}
      ${SubtaskFields}
      end_date
      details
      childSubtasks{
        ${node}
        ${SubtaskFields}
        end_date
        details
        childSubtasks{
          ${node}
          ${SubtaskFields}
          end_date
          details
        }
        owner {
          ${node}
          first
          last
          pic
        }
        mediafolder{
          ${node}
          ${MediaFolderFields}
        }
        members {
          ${node}
          ${UserFields}
          fullname
        }
        media {
          ${node}
          workspace_id
          user_id
          ${MediaFields}
          commentPoint {
            ${node}
            media_id
            user_id
            x
            y
            last_label
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              ${UserFields}
            }
            media_comments_point_id
            created_at
            updated_at
          }
        }
      }
      owner {
        ${node}
        first
        last
        pic
      }
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      members {
        ${node}
        ${UserFields}
        fullname
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
    }
  }  
`;

export const TaskCommentsQuery = `
  query(
    $task_id: Int!
    $limit: Int
    $offset: Int
    $cursor: Int
    $cursor_type: String
  ) {
    taskComments(
      task_id: $task_id
      limit: $limit
      offset: $offset
      cursor: $cursor
      cursor_type: $cursor_type
    ) {
      ${node}
      comment
      is_active
      task_id
      user_id
      owner {
        ${node}
        ${UserFields}
        userStatus {
          id
          name
          icon
        }
      }
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      commentreactionset
      commentreactiondata
      sharedTaskComment {
        ${node}
        user_id
        owner {
          ${node}
          first
          last
          pic
        }
        comment
        created_at
        media {
          ${node}
          workspace_id
          user_id
          ${MediaFields}
          commentPoint {
            ${node}
            media_id
            user_id
            x
            y
            last_label
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              ${UserFields}
            }
            media_comments_point_id
            created_at
            updated_at
          }
        }
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
      created_at
      updated_at
      is_bot
    }
  }
`;
export const MessagesQuery = `
  query(
    $workspace_id: Int!
    $filter_user_id: Int
    $filter_date_to: String
    $filter_date_from: String
    $limit: Int
    $offset: Int
    $cursor: Int
    $content: String
  ) {
    messages(
      workspace_id: $workspace_id
      filter_user_id: $filter_user_id
      filter_date_to: $filter_date_to
      filter_date_from: $filter_date_from
      limit: $limit
      offset: $offset
      cursor: $cursor
      content: $content
    ) {
      ${node}
      user {
        ${node}
        ${UserFields}
        userStatus {
          id
          name
          icon
        }
      }
      is_task
      is_email_to_task
      isbookmarked
      ispinpost
      seens {
        user {
          ${node}
          fullname
          first
          last
          pic
        }
        seen_at
      }
      ${PostFields}
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      media {
        ${node}
        ${MediaFields}
        workspace_id
        user_id
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
      forwardedPost {
        ${node}
        user_id
        created_at
        owner {
          ${node}
          first
          last
          pic
        }
        content
        workspace_id
        mediafolder{
          ${node}
          ${MediaFolderFields}
        }
        media {
          ${node}
          ${MediaFields}
          workspace_id
          user_id
          commentPoint {
            ${node}
            media_id
            user_id
            x
            y
            last_label
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              first
              last
              username
              pic
            }
            media_comments_point_id
            created_at
            updated_at
          }
        }
      }
      reactiondata
    }
  }
`;
export const PinMessagesQuery = `
  query(
    $workspace_id: Int!
  ) {
    pinMessages(
      workspace_id: $workspace_id
  ) {
      ${node}
      user {
        ${node}
        ${UserFields}
      }
      is_task
      is_email_to_task
      isbookmarked
      ispinpost
      seens {
        user {
          ${node}
          fullname
          first
          last
          pic
        }
        seen_at
      }
      ${PostFields}
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      media {
        ${node}
        ${MediaFields}
        workspace_id
        user_id
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
      forwardedPost {
        ${node}
        user_id
        created_at
        owner {
          ${node}
          first
          last
          pic
        }
        content
        workspace_id
        mediafolder{
          ${node}
          ${MediaFolderFields}
        }
        media {
          ${node}
          ${MediaFields}
          workspace_id
          user_id
          commentPoint {
            ${node}
            media_id
            user_id
            x
            y
            last_label
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              first
              last
              username
              pic
            }
            media_comments_point_id
            created_at
            updated_at
          }
        }
      }
      reactiondata
    }
  }
`;
export const NotificationsQuery = `
  query(
    $limit: Int
    $cursor: Int
    $read: Boolean
  ){
    notifications(
      limit: $limit
      cursor: $cursor
      read: $read
    ) {
      ${node}
      user_id
      action_id
      read
      action{
        id
        type
        created_at
        object_type
        object_id
        scope_type
        scope_id
        object{
          ... on post {
            ${node}
            content
            short_content
            reactiondata
            updated_at
            created_at
            is_replied
            user {
              ${node}
              first
              last
              fullname
              email
              username
              pic
              roles {
                ${node}
                name
                label
              }
            }
            forwardedPost {
              ${node}
              user_id
              created_at
              owner {
                ${node}
                first
                last
                pic
              }
              content
              workspace_id
              media {
                ${node}
                workspace_id
                user_id
                ${MediaFields}
                commentPoint {
                  ${node}
                  media_id
                  user_id
                  x
                  y
                  last_label
                }
                comments {
                  ${node}
                  comment
                  owner {
                    ${node}
                    ${UserFields}
                  }
                  media_comments_point_id
                  created_at
                  updated_at
                }
              }
            }
            mediafolder{
              ${node}
              ${MediaFolderFields}
            }
            media {
              ${node}
              workspace_id
              user_id
              ${MediaFields}
              commentPoint {
                ${node}
                media_id
                user_id
                x
                y
                last_label
              }
              comments {
                ${node}
                comment
                owner {
                  ${node}
                  ${UserFields}
                }
                media_comments_point_id
                created_at
                updated_at
              }
            }
          }
          ... on taskComment {
            ${node}
            comment
            is_active
            task_id
            user_id
            is_replied
            task {
              ${node}
              title
              completed
              end_date
              sequence
              card_id
              completed
              isbookmarked
              media {
                ${node}
                workspace_id
                user_id
                file
                filename
                thumb
                file_resource
                type
                size
              }
              owner {
                ${node}
                first
                last
                fullname
                email
                username
                pic
                roles {
                  ${node}
                  name
                  label
                }
              }
            }
            owner {
              ${node}
              first
              last
              fullname
              email
              username
              pic
              roles {
                ${node}
                name
                label
              }
            }
            commentreactionset
            commentreactiondata
            sharedTaskComment {
              ${node}
              user_id
              created_at
              owner {
                ${node}
                first
                last
                pic
              }
              comment
              media {
                ${node}
                workspace_id
                user_id
                ${MediaFields}
                commentPoint {
                  ${node}
                  media_id
                  user_id
                  x
                  y
                  last_label
                }
                comments {
                  ${node}
                  comment
                  owner {
                    ${node}
                    ${UserFields}
                  }
                  media_comments_point_id
                  created_at
                  updated_at
                }
              }
            }
            mediafolder{
              ${node}
              ${MediaFolderFields}
            }
            media {
              ${node}
              workspace_id
              user_id
              ${MediaFields}
              commentPoint {
                ${node}
                media_id
                user_id
                x
                y
                last_label
              }
              comments {
                ${node}
                comment
                owner {
                  ${node}
                  ${UserFields}
                }
                media_comments_point_id
                created_at
                updated_at
              }
            }
            created_at
            updated_at
            is_bot
          }
          ... on task {
            ${node}
            title
            details
            completed
            sequence
            card_id
            completed
            isbookmarked
            owner {
              ${node}
              first
              last
              username
              pic
            }
          }
          ... on subTask {
            ${node}
            title
            completed
            sequence
            end_date
            task {
              ${node}
              title
              completed
              sequence
              card_id
              completed
              isbookmarked
              owner {
                ${node}
                first
                last
                username
                pic
              }
            }
            owner {
              ${node}
              first
              last
              username
              pic
            }
          }
        }
        scope{
          ... on workspace{
            ${node}
            title
            type
            boards{
              ${node}
            }
          }
        }
        subject{
          id
          first
          last
          fullname
          email
          username
          pic
          roles {
            id
            name
            label
          }
        }
      }
    }
  }
`;
export const ActivityLogsQuery = `
query(
  $scope_id: Int
  $scope_type: String
  $limit: Int
  $cursor:Int
){
activityLog(scope_id: $scope_id, scope_type: $scope_type, limit: $limit,cursor: $cursor){
      id
      type
      subject_id
      scope_id
      scope_type
      object_id
      object_type
      details
      created_at
      subject{
        id
        first
        last
        fullname
        email
        username
        pic
      }
      object{
        ... on company{
          ${node}
        }
        ... on workspace{
          id
          title
        }
        ... on post {
          ${node}
          content
          created_at
          forwardedPost {
            ${node}
            created_at
          }
        }
        ... on board{
          ${node}
          title
          workspace_id
          user_id
        }
        ... on card{
          ${node}
          title
          board_id
          user_id
          sequence
        }
        ... on task {
          ${node}
          title
          completed
          sequence
          card_id
          end_date
          completed
          isbookmarked
          labels{
            ${node}
            name
          }
        }
        ... on taskComment {
          ${node}
          comment
          task_id
          sharedTaskComment {
            ${node}
            user_id
            created_at
          }
          created_at
          is_bot
        }
        ... on subTask{
          ${node}
          title
          completed
          task_id
          sequence
        }
        ... on media{
          ${node}
          id
          workspace_id
          filename
          file_resource
          is_document_section
        }
        ... on mediaCommentPoint{
          ${node}
          media_id
          user_id
        }
        ... on mediaComment{
          ${node}
          media_id
          media_comments_point_id
        }
        ... on bookmark{
          ${node}
          ${BookmarkFields}
        }
        ... on bookmarkFolder{
          ${node}
          ${BookmarkFolderFields}
        }
      }
  }
}
`;
export const Workspaces2Query = `
  query {
    workspaces2 {
      ${node}
      ${WorkspaceFields}
      type
      is_preferred
      hasbookmarked
      owner {
        ${node}
      }
      folder{
        ${node}
      }
      boards {
        workspace_id
        ${node}
        cards{
          ${node}
          title
          board_id
        }
      }
      company {
        owner {
          ${node}
        }
      }
      read_cursor
      notification_level
      unreadMessagePoint {
        ${node}
        post_id
      }
      users{
        ${node}
      }
      timelogs {
        ${node}
        ${TimeLogFields}
        task{
          ${node}
          title
        }
        owner {
          ${node}
          ${UserFields}
        }
      }
    }
  }
`;
export const FoldersQuery = `
query {
  folder{
    ${node}
    ${FolderFields}
  }
}
`;
export const WorkspaceMembersQuery = `
  query($workspace_id: Int!) {
    workspaceMembers(workspace_id: $workspace_id) {
      ${node}
      ${UserFields}
      fullname
      email
      lang
      status_id
      userStatus {
        id
        name
        icon
      }
      roles {
        ${node}
        ${RoleFields}
      }
    }
  }
`;

export const WorkspaceAvailableUsersQuery = `
  query($workspace_id: Int!, $search: String, $limit: Int, $offset: Int) {
    workspaceAvailableUsers(
      workspace_id: $workspace_id
      limit: $limit
      offset: $offset
      search: $search
    ) {
      ${node}
      ${UserFields}
      email
      roles {
        ${node}
        name
      }
    }
  }
`;

export const PayCardQuery = `
  query {
    payCard {
      ${node}
      stripecard {
        name
        last4
      }
      address {
        ${AddressFields}
      }
    }
  }
`;

export const InvoicesQuery = `
  query {
    invoices {
      ${node}
      stripe_invoice_id
      company_id
      period_start
      period_end
      paid
      total
    }
  }
`;

export const InvitationsQuery = `
  query {
    invitations {
      ${node}
      email
      workspaces {
        ${node}
        title
      }
    }
  }
`;

export const InvitationWorkspaceQuery = `
  query {
    invitationWorkspace {
      ${node}
      title
    }
  }
`;
export const PublicWorkspacesQuery = `
  query($cursor: Int, $title: String, $cursor_type: String) {
    publicWorkspaces(
      cursor: $cursor
      title: $title
      cursor_type: $cursor_type
    ) {
      ${node}
      ${WorkspaceFields}
      owner {
        ${node}
      }
      company {
        owner {
          ${node}
        }
      }
      unreadMessagePoint {
        ${node}
        post_id
      }
    }
  }
`;
export const WorkspacePendingInvitesQuery = `
  query(
    $workspace_id: Int!
    $search: String
    $cursor: Int
    $cursor_type: String
  ) {
    workspacePendingInvites(
      workspace_id: $workspace_id
      cursor: $cursor
      search: $search
      cursor_type: $cursor_type
    ) {
      ${node}
      email
      created_at
    }
  }
`;

export const RolePermissionsQuery = `
  query {
    rolePermissions {
      role_id
      permission_id
      permission
      status
    }
  }
`;

export const UserPermissionsQuery = `
  query {
    userPermissions {
      ${node}
      name
      label
    }
  }
`;

export const WhatsNewNotificationsQuery = `
  query($limit: Int, $offset: Int) {
    whatsNewNotification2(limit: $limit, offset: $offset) {
      ${node}
      subject
      short_content
      body
      read
      user_id
      template_id
      created_at
      updated_at
    }
  }
`;
export const SavedItemsQuery = `
  query{
    savedItems{
      task{
        ${node}
        title
        details
        bookmarkdate
        card_id
        completed
        end_date
        sequence
        isbookmarked
        board_id
        is_lock
        is_invisible
        owner {
          ${node}
          ${UserFields}
        }
        mediafolder{
          ${node}
          ${MediaFolderFields}
        }
        activetimelog {
          ${node}
          ${TimeLogFields}
        }
        timelogs{
          ${node}
          ${TimeLogFields}
          owner {
            ${node}
            ${UserFields}
          }
        }
      }
      media_folder{
        ${node}
        ${MediaFolderFields}
      }
      post{
        ${node}
        workspace_id
        bookmarkdate
        isbookmarked
        user {
          ${node}
          ${UserFields}
        }
        mediafolder{
          ${node}
          ${MediaFolderFields}
        }
        ${PostFields}
        media {
          ${node}
          ${MediaFields}
          workspace_id
          user_id
          commentPoint {
            ${node}
            media_id
            user_id
            x
            y
            last_label
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              ${UserFields}
            }
            media_comments_point_id
            created_at
            updated_at
          }
        }
        forwardedPost {
          ${node}
          user_id
          created_at
          owner {
            ${node}
            first
            last
            pic
          }
          content
          workspace_id
          mediafolder{
            ${node}
            ${MediaFolderFields}
          }
          media {
            ${node}
            ${MediaFields}
            workspace_id
            user_id
            commentPoint {
              ${node}
              media_id
              user_id
              x
              y
              last_label
            }
            comments {
              ${node}
              comment
              owner {
                ${node}
                first
                last
                username
                pic
              }
              media_comments_point_id
              created_at
              updated_at
            }
          }
        }
        reactiondata
      }
      media{
        ${node}
        isbookmarked
        bookmarkdate
        ${MediaFields}
        workspace_id
        user_id
        created_at
        updated_at
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
      }
    }
  }
`;
export const MyWorkQuery = `
  query($user_id: Int!) {
    myWorks(user_id: $user_id) {
      ${node}
      ${TaskFields}
      activetimelog {
        ${node}
        ${TimeLogFields}
      }
      isbookmarked
      board_id
      labels {
        ${node}
        ${LabelFields}
      }
      members {
        ${node}
        ${UserFields}
        fullname
      }
      media{
        ${node}
        file
      }
      owner {
        ${node}
        ${UserFields}
      }
      subtasks {
        ${node}
        completed
        title
        sequence
        members {
          ${node}
          ${UserFields}
          fullname
        }
      },
      owner {
        ${node}
        ${UserFields}
      }
      timelogs {
        ${node}
        ${TimeLogFields}
        owner {
          ${node}
          ${UserFields}
        }
      }
      myworks {
        ${MyWorkFields}
      }
      comments {
        ${node}
        comment
        is_active
        task_id
        user_id
        owner {
          ${node}
          ${UserFields}
        }
      }
    }
  }
`;
export const MyCardsQuery = `
  query($user_id: Int!) {
    myCards(user_id: $user_id) {
      ${node}
      title
      sequence
      user_id
      color
      owner {
        ${node}
        ${UserFields}
      }
    }
  }
`;
export const PostCountQuery = `
query($user_id: Int!){
  postCount(user_id: $user_id)
}
`;
export const BoardTemplateQuery = `
  query {
    boardTemplate {
      ${node}
      ${BoardTemplateFields}
    }
}
`;
export const UsersTaskQuery = `
  query {
    usersTask {
      ${node}
      ${UserFields}
      roles {
        ${node}
        name
      }
      task{
        ${node}
        ${TaskFields}
        board_id
        myworks{
          ${MyWorkFields}
        }
        labels {
          ${node}
          ${LabelFields}
        }
        owner {
          ${node}
          ${UserFields}
        }
      }
    }
  }
`;
export const MediaFoldersQuery = `
  query($workspace_id: Int!){
    mediaFolders(workspace_id: $workspace_id){
      ${node}
      title
      media_count
      created_at
      user_id
      isbookmarked
      media{
        ${node}
        is_document_section
        type
      }
    }
  }
`;
export const MediaFolderQuery = `
  query($folder_id: Int!){
    mediaFolder(folder_id: $folder_id){
      ${node}
      title
      media_count
      isgridview
      isbookmarked
      mediaCard {
        ${node}
        ${MediaCardFields}
      }
      media {
        ${node}
        workspace_id
        created_at
        user_id
        ${MediaFields}
        folder_sequence
        media_folder_id
        media_card_id
        is_read_only
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
    }
  }
`;
export const ImportDataQuery = `
  query{
    importData{
      ${node}
      stage
      medium
      completed
      created_at
      importDataHistory{
        id
        title
        import_data_id
        workspace_id
      }
    }
  }
`;
export const WorkspaceFieldsQuery = `
  query($workspace_id: Int!){
    workspaceFields(workspace_id: $workspace_id){
      ${node}
      title
      c_f_id
      isvisible
      customField{
        ${node}
        is_active
      }
    }
  }
`;
export const TasksQuery = `
  query($workspace_id: Int!){
    tasks(workspace_id: $workspace_id){
      ${node}
      card_id
      title
      completed
      sequence
    }
  }
`;
//----------- Mutations ------------

export const CheckCompanyStatusMutation = `
  mutation {
    checkCompanyStatus {
      userType
      company_status
      member_status
      used_storage
      allowed_storage
      trial_over
      subscription_over
      grace_hours
      hasPaycard
    }
  }
`;

export const SendForgotCompanyMailMutation = `
  mutation($email: String!) {
    sendForgotCompanyMail(email: $email)
  }
`;

export const CheckInvitationLinkMutation = `
  mutation($company_uri: String!, $secret: String!) {
    checkInvitationLink(company_uri: $company_uri, secret: $secret) {
      email
    }
  }
`;

export const GetUserByEmailMutation = `
  mutation($email: String!, $company_uri: String!) {
    getUserByEmail(email: $email, company_uri: $company_uri) {
      id
      first
      last
      username
      email
      is_deleted
    }
  }
`;

export const CheckCompanyPublicLinkMutation = `
  mutation($company_uri: String!, $secret: String!) {
    checkCompanyPublicLink(company_uri: $company_uri, secret: $secret)
  }
`;

export const CheckEmailAvailabilityMutation = `
  mutation($company_uri: String!, $email: String!) {
    checkEmailAvaibility(company_uri: $company_uri, email: $email)
  }
`;

export const CheckUsernameMutation = `
  mutation($company_uri: String!, $username: String!, $mod: Boolean) {
    checkUsernameAvaibility(
      company_uri: $company_uri
      username: $username
      mod: $mod
    )
  }
`;

export const MemberSignupMutation = `
  mutation(
    $invite: Boolean!
    $first: String!
    $last: String!
    $email: String!
    $username: String!
    $company_uri: String!
    $password: String!
    $secret: String!
    $allow_occational_mail: Boolean
    $code: Int
    $toc: Boolean!
  ) {
    memberSignUp(
      invite: $invite
      first: $first
      last: $last
      email: $email
      username: $username
      secret: $secret
      company_uri: $company_uri
      password: $password
      allow_occational_mail: $allow_occational_mail
      toc: $toc
      code: $code
    ) {
      user {
        ${node}
        ${UserFields}
        email
        status
        lang
        swatch
      }
      company_id
      workspace_ids
    }
  }
`;

export const MemberRestoreMutation = `
  mutation(
    $invite: Boolean!
    $first: String!
    $last: String!
    $email: String!
    $username: String!
    $company_uri: String!
    $secret: String!
    $password: String!
    $allow_occational_mail: Boolean!
    $toc: Boolean!
    $code: Int!
    $id: Int!
  ) {
    memberRestore(
      invite: $invite
      first: $first
      last: $last
      email: $email
      username: $username
      company_uri: $company_uri
      secret: $secret
      password: $password
      allow_occational_mail: $allow_occational_mail
      toc: $toc
      code: $code
      id: $id
    ) {
      user {
        ${node}
        ${UserFields}
        email
        status
        lang
        swatch
      }
      company_id
      workspace_ids
    }
  }
`;

export const CheckCouponCodeMutation = `
  mutation($coupon_code: String!) {
    checkCouponCode(coupon_code: $coupon_code)
  }
`;

export const ApplyCouponMutation = `
  mutation($coupon_code: String!) {
    applyCoupon(coupon_code: $coupon_code)
  }
`;
export const UpdateReadCursorMutation = `
  mutation($workspace_id: Int!,$read_cursor: Int!){
    updateReadCursor(workspace_id:$workspace_id,read_cursor:$read_cursor)
  }
`;
export const UpdateNotificationLevelMutation = `
  mutation($workspace_id: Int!,$notification_level: String!){
    updateNotificationLevel(workspace_id:$workspace_id,notification_level:$notification_level)
  }
`;
export const MarkPostsAsSeenMutation = `
  mutation($ids: [Int]!) {
    markPostsAsSeen(ids: $ids) {
      ${node}
      seens {
        user {
          ${node}
          first
          last
          fullname
          pic
        }
        seen_at
      }
    }
  }
`;

export const AttachTaskLabelMutation = `
  mutation($task_id: Int!, $label_id: Int!) {
    attachTaskLabel(task_id: $task_id, label_id: $label_id) {
      ${node}
      labels {
        ${node}
        ${LabelFields}
      }
    }
  }
`;
export const DetachTaskLabelMutation = `
  mutation($task_id: Int!, $label_id: Int!) {
    detachTaskLabel(task_id: $task_id, label_id: $label_id) {
      ${node}
      labels {
        ${node}
        ${LabelFields}
      }
    }
  }
`;

export const AddTaskBookmarkMutation = `
  mutation($task_id: Int!) {
    addTaskBookmark(task_id: $task_id) {
      ${node}
      isbookmarked
    }
  }
`;
export const RemoveTaskBookmarkMutation = `
  mutation($task_id: Int!) {
    removeTaskBookmark(task_id: $task_id) {
      ${node}
      isbookmarked
    }
  }
`;

export const UpdateTaskMutation = `
  mutation(
    $id: Int!
    $title: String
    $calllink: String
    $details: String
    $completed: Boolean
    $end_date: String
    $start_date: String
    $tagged_user_ids: [Int]
    $sequence: String
    $card_id: Int
    $board_id: Int
    $cover_image : Int
    $rating : Int
    $is_lock: Boolean
    $is_invisible: Boolean
  ) {
    updateTask(
      id: $id
      card_id: $card_id
      board_id: $board_id
      title: $title
      details: $details
      completed: $completed
      start_date: $start_date
      end_date: $end_date
      tagged_user_ids: $tagged_user_ids
      sequence: $sequence
      cover_image : $cover_image
      rating : $rating
      calllink:$calllink
      is_lock:$is_lock
      is_invisible:$is_invisible
    ) {
      ${node}
      title
      details
      calllink
      card_id
      start_date
      end_date
      completed
      sequence
      cover_image
      rating
      is_lock
      is_invisible
    }
  }
`;

export const DetachSubtaskMediaMutation = `
  mutation($subtask_id: Int!, $media: [Int]) {
    detachSubtaskMedia(subtask_id: $subtask_id, media: $media) {
      ${node}
      title
      completed
      task_id
      sequence
      owner {
        ${node}
        first
        last
        pic
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
    }
  }
`;

export const DetachTaskCommentMediaMutation = `
  mutation($task_comment_id: Int!, $media: [Int]) {
    detachTaskCommentMedia(task_comment_id: $task_comment_id, media: $media) {
      ${node}
      comment
      is_active
      task_id
      owner {
        ${node}
        first
        last
        pic
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
    }
  }
`;

export const AddMemberToWorkspaceMutation = `
  mutation($workspace_id: Int!, $user_id: Int!) {
    addMemberToWorkspace(workspace_id: $workspace_id, user_id: $user_id)
  }
`;

export const InvitePeopleMutation = `
  mutation(
    $emails: [String]
    $workspace_ids: [Int]
    $g_recaptcha_response: String
  ) {
    invitePeople(
      emails: $emails
      workspace_ids: $workspace_ids
      g_recaptcha_response: $g_recaptcha_response
    )
  }
`;

export const AddNewPayCardMutation = `
  mutation(
    $first: String!
    $email: String!
    $token: stripeTokenInput
    $address: billAddressInput
    $coupon_code: String
  ) {
    addNewPayCard(
      first: $first
      email: $email
      token: $token
      coupon_code: $coupon_code
      address: $address
    ) {
      paycard {
        ${node}
      }
      error
    }
  }
`;

export const DeletePostMutation = `
  mutation($id: Int!) {
    deletePost(id: $id)
  }
`;
export const AddMemberToTaskMutation = `
  mutation($workspace_id: Int!, $user_id: Int!, $task_id: Int!) {
    addMemberToTask(
      workspace_id: $workspace_id
      user_id: $user_id
      task_id: $task_id
    ) {
      ${node}
      ${UserFields}
      fullname
    }
  }
`;
export const AddMemberToCardMutation = `
  mutation($workspace_id: Int!, $user_id: Int!, $card_id: Int!) {
    addMemberToCard(
      workspace_id: $workspace_id
      user_id: $user_id
      card_id: $card_id
    ) {
      ${node}
      ${UserFields}
      fullname
    }
  }
`;

export const AddMemberToSubTaskMutation = `
  mutation($workspace_id: Int!, $user_id: Int!, $subtask_id: Int!) {
    addMemberToSubTask(
      workspace_id: $workspace_id
      user_id: $user_id
      subtask_id: $subtask_id
    ) {
      ${node}
      ${UserFields}
      fullname
    }
  }
`;
export const CreatePostMutation = `
  mutation(
    $workspace_id: Int!
    $content: String
    $media: [Int]
    $folder: [Int]
    $post_id: Int
    $media_urls: [mediaUrl]
  ) {
    createPost(
      workspace_id: $workspace_id
      content: $content
      media: $media
      folder: $folder
      post_id: $post_id
      media_urls: $media_urls
    ) {
      ${node}
      user {
        ${node}
        ${UserFields}
      }
      ${PostFields}
      seens {
        user {
          ${node}
          ${UserFields}
        }
        seen_at
      }
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
      reactiondata
      forwardedPost {
        ${node}
        user_id
        created_at
        original_post_id
        mediafolder{
          ${node}
          ${MediaFolderFields}
        }
        owner {
          ${node}
          first
          last
          pic
        }
        content
        workspace_id
        media {
          ${node}
          ${MediaFields}
          workspace_id
          user_id
          commentPoint {
            ${node}
            media_id
            user_id
            x
            y
            last_label
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              ${UserFields}
            }
            media_comments_point_id
            created_at
            updated_at
          }
        }
      }
    }
  }
`;

export const CreatePeopleWorkspaceMutation = `
  mutation($user_id: Int!) {
    createPeopleWorkspace(user_id: $user_id) {
      ${node}
      title
      status
      type
      is_general
      description
      users{
        ${node}
      }
      owner {
        ${node}
      }
      company {
        owner {
          ${node}
        }
      }
    }
  }
`;

export const DeleteTaskMemberMutation = `
  mutation($user_id: Int!, $task_id: Int!) {
    deleteTaskMember(user_id: $user_id, task_id: $task_id)
  }
`;
export const DeleteSubTaskMemberMutation = `
  mutation($user_id: Int!, $subtask_id: Int!) {
    deleteSubTaskMember(user_id: $user_id, subtask_id: $subtask_id)
  }
`;

export const CreateTaskMutation = `
  mutation(
    $card_id: Int
    $board_id: Int
    $title: String
    $details: String
    $sequence: String!
    $post_id: Int
  ) {
    createTask(
      card_id: $card_id
      board_id: $board_id
      title: $title
      details: $details
      sequence: $sequence
      post_id: $post_id
    ) {
      ${node}
      title
      details
      end_date
      card_id
      board_id
      sequence
      last_label
      completed
      isbookmarked
      cover_image
      rating
      labels {
        ${node}
        ${LabelFields}
      }
      members {
        ${node}
        ${UserFields}
      }
      subtasks {
        ${node}
        completed
        title
        sequence
        members {
          ${node}
          ${UserFields}
          fullname
        }
      }
      timelogs {
        ${node}
        ${TimeLogFields}
        owner {
          ${node}
          ${UserFields}
        }
      }
      activetimelog {
        ${node}
        ${TimeLogFields}
      }
      media{
        ${node}
      }
      owner {
        ${node}
        ${UserFields}
      }
    }
  }
`;
export const CreateDuplicateTaskMutation = `
  mutation(
    $board_id: Int!
    $card_id: Int
    $title: String!
    $details: String
    $sequence: String
    $media: [Int]
    $folder: [Int]
    $member: [Int]
    $subtask: [subTaskInput]
    $labels: [taskLabelInput]
    $start_date: String
    $end_date: String
    $media_urls: [mediaUrl]
    $workspace_id: Int
    $calllink: String
    $is_lock: Boolean
    $is_invisible: Boolean
    $note:String
    $cover_image:Int
  ){
    createDuplicateTask(
      board_id:$board_id
      card_id: $card_id
      title: $title
      details: $details
      sequence: $sequence
      media: $media
      folder: $folder
      member: $member
      subtask: $subtask
      start_date: $start_date
      end_date: $end_date
      labels: $labels
      media_urls: $media_urls
      workspace_id: $workspace_id
      calllink: $calllink
      is_lock: $is_lock
      is_invisible: $is_invisible
      note: $note
      cover_image: $cover_image
    ){
      ${node}
      title
      details
      calllink
      start_date
      end_date
      card_id
      sequence
      last_label
      completed
      isbookmarked
      cover_image
      is_lock
      is_invisible
      labels {
        ${node}
        ${LabelFields}
      }
      members {
        ${node}
        ${UserFields}
      }
      customFieldOptionSelection {
        ${node}
        task_id
        c_f_id
        c_f_o_id
        option_text
      }
      subtasks {
        ${node}
        completed
        title
        sequence
        members {
          ${node}
          ${UserFields}
          fullname
        }
      }
      owner {
        ${node}
        ${UserFields}
      }
      myworks {
        ${MyWorkFields}
      }
      activetimelog {
        ${node}
        ${TimeLogFields}
      }
      timelogs {
        ${node}
        ${TimeLogFields}
        owner {
          ${node}
          ${UserFields}
        }
      }
      note{
        id
        note
        document_version
      }
    }
  }
`;

export const CreateDuplicateCardMutation = `
  mutation(
    $title: String!
    $board_id: Int!
    $card_id: Int!
    $sequence: String
    $workspace_id: Int!
    $is_task: Boolean!
    $is_subtask: Boolean!
    $is_attachment: Boolean!
    ){
    createDuplicateCard(
      board_id:$board_id
      card_id: $card_id
      title: $title
      workspace_id: $workspace_id
      sequence: $sequence
      is_task: $is_task
      is_subtask: $is_subtask
      is_attachment: $is_attachment
    ){
      ${node}
      title
      board_id
      sequence
      user_id
      owner {
        ${node}
        ${UserFields}
      }
    }
  }
`;
export const CreateCardMutation = `
  mutation(
    $board_id: Int!
    $workspace_id: Int!
    $title: String!
    $sequence: String!
  ) {
    createCard(
      board_id: $board_id
      workspace_id: $workspace_id
      title: $title
      sequence: $sequence
    ) {
      ${node}
      title
      board_id
      sequence
      user_id
      is_lock
      is_invisible
      owner {
        ${node}
        ${UserFields}
      }
    }
  }
`;

export const UpdateCardMutation = `
  mutation($id: Int!, $title: String, $sequence: String, $is_lock: Boolean, $is_invisible: Boolean) {
    updateCard(id: $id, title: $title, sequence: $sequence, is_lock: $is_lock, is_invisible: $is_invisible) {
      ${node}
      title
      sequence
      is_lock
      is_invisible
    }
  }
`;

export const DeleteCardMutation = `
  mutation($id: Int!) {
    deleteCard(id: $id)
  }
`;

export const UpdateBoardMutation = `
  mutation($id: Int!, $title: String, $gradient: String, $bgimg: String, $gradient_list: String, $bgimg_list: String) {
    updateBoard(id: $id, title: $title, gradient: $gradient, bgimg: $bgimg, gradient_list: $gradient_list, bgimg_list: $bgimg_list) {
      ${node}
      gradient
      bgimg
      gradient_list
      bgimg_list
    }
  }
`;

export const UpdateSubtaskMutation = `
  mutation($id: Int!, $title: String, $sequence: String, $completed: Boolean!,$end_date: String,$media: [Int],$folder: [Int], $details: String) {
    updateSubTask(id: $id, title: $title, sequence: $sequence, completed:$completed, end_date:$end_date,media: $media,folder: $folder, details:$details) {
      ${node}
      title
      sequence
      completed
      end_date
      details
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
    }
  }
`;

export const UpdateTaskCommentMutation = `
  mutation(
    $id: Int!
    $comment: String!
    $tagged_user_ids: [Int]
    $media: [Int]
    $workspace_id: Int
    $media_urls: [mediaUrl]
    $folder: [Int]
  ) {
    updateTaskComment(
      id: $id
      comment: $comment
      tagged_user_ids: $tagged_user_ids
      media: $media
      workspace_id: $workspace_id
      media_urls: $media_urls
      folder: $folder
    ) {
      ${node}
      comment
      created_at
      updated_at
      commentreactionset
      commentreactiondata
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      sharedTaskComment {
        ${node}
        user_id
        owner {
          ${node}
          first
          last
          pic
        }
        comment
        created_at
        media {
          ${node}
          ${MediaFields}
          workspace_id
          user_id
          commentPoint {
            ${node}
            media_id
            user_id
            x
            y
            last_label
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              ${UserFields}
            }
            media_comments_point_id
            created_at
            updated_at
          }
        }
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
    }
  }
`;

export const GetVerificationCodeMutation = `
  mutation($email: String!) {
    getVerificationCode(email: $email)
  }
`;

export const CheckCompanyUriMutation = `
  mutation($company_uri: String!) {
    checkCompanyUri(company_uri: $company_uri)
  }
`;

export const ChangePasswordMutation = `
  mutation($old_password: String!, $password: String!) {
    changePassword(old_password: $old_password, password: $password)
  }
`;

export const CreateCompanyMutation = `
  mutation(
    $first: String!
    $last: String!
    $email: String!
    $username: String!
    $company_name: String!
    $company_uri: String!
    $password: String!
    $code: Int!
    $questions: companyQuestionsInput
    $allow_occational_mail: Boolean
    $toc: Boolean!
    $team_size: String
    $reason_for_used: String
    $tell_about_us: String
  ) {
    createCompany(
      first: $first
      last: $last
      email: $email
      username: $username
      company_name: $company_name
      company_uri: $company_uri
      password: $password
      code: $code
      questions: $questions
      allow_occational_mail: $allow_occational_mail
      toc: $toc
      team_size: $team_size
      reason_for_used: $reason_for_used
      tell_about_us: $tell_about_us
    ) {
      ${node}
      ${CompanyFields}
      subscription {
        ${node}
        ${SubscriptionFields}
      }
    }
  }
`;

export const UpdatePostMutation = `
  mutation(
    $id: Int!
    $content: String!
    $media: [Int]
    $media_urls: [mediaUrl]
    $workspace_id: Int!
    $folder: [Int]
  ) {
    updatePost(
      id: $id
      content: $content
      media: $media
      media_urls: $media_urls
      workspace_id: $workspace_id
      folder: $folder
    ) {
      ${node}
      user {
        ${node}
        ${UserFields}
      }
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      ${PostFields}
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
    }
  }
`;

export const DetachPostMediaMutation = `
  mutation($post_id: Int!, $media: [Int]) {
    detachPostMedia(post_id: $post_id, media: $media) {
      ${node}
      user {
        ${node}
        ${UserFields}
      }
      ${PostFields}
      workspace_id
      user_id
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
      }
    }
  }
`;

export const AddMessageReactionMutation = `
  mutation($id: Int!, $reaction_id: Int!) {
    addMessageReaction(id: $id, reaction_id: $reaction_id) {
      ${node}
      reactiondata
    }
  }
`;
export const RemoveMessageReactionMutation = `
  mutation($id: Int!, $reaction_id: Int!) {
    removeMessageReaction(id: $id, reaction_id: $reaction_id) {
      ${node}
      reactiondata
    }
  }
`;
export const AddMessageBookmarkMutation = `
  mutation($post_id: Int!) {
    addPostBookmark(post_id: $post_id) {
      ${node}
      isbookmarked
    }
  }
`;
export const RemoveMessageBookmarkMutation = `
  mutation($post_id: Int!) {
    removePostBookmark(post_id: $post_id) {
      ${node}
      isbookmarked
    }
  }
`;
export const GetPostReactionUserListMutation = `
  mutation($id: Int!, $reaction_id: Int!) {
    getPostReactionUserList(id: $id, reaction_id: $reaction_id) {
      ${node}
      users {
        ${node}
        first
        last
        pic
      }
    }
  }
`;

export const GetBulkMediaDetailMutation = `
  mutation($ids: [Int]!) {
    getBulkMediaDetail(ids: $ids) {
      ${node}
      ${MediaFields}
      workspace_id
      user_id
      file
      file_resource
      filename
      type
      size
      created_at
      updated_at
      thumb
      document_state
      document_version
      is_read_only
      commentPoint {
        ${node}
        media_id
        user_id
        x
        y
        last_label
      }
      comments {
        ${node}
        comment
        owner {
          ${node}
          ${UserFields}
        }
        media_comments_point_id
        created_at
        updated_at
      }
    }
  }
`;

export const UpdateCompanySettingsMutation = `
  mutation($name: String!, $logo: String, $remove_logo: Boolean,$start_task_id: Int,$start_time_log_id :Int) {
    updateCompanySettings(name: $name, logo: $logo, remove_logo: $remove_logo,start_task_id:$start_task_id,start_time_log_id:$start_time_log_id) {
      ${node}
      name
      logo
      uri
      start_task_id
      start_time_log_id
    }
  }
`;

export const CreateNewPayCardMutation = `
  mutation($token: stripeTokenInput!) {
    createNewPayCard(token: $token) {
      paycard {
        ${node}
        stripecard {
          name
          last4
        }
      }
      error
    }
  }
`;

export const UpdateCustomerAddressMutation = `
  mutation($address: billAddressInput!) {
    updateCustomerAddress(address: $address) {
      ${node}
      address {
        ${AddressFields}
      }
    }
  }
`;

export const DeleteAccountMutation = `
  mutation($reason_for_cancelation: [String]!, $description: String!) {
    deleteAccount(
      reason_for_cancelation: $reason_for_cancelation
      description: $description
    )
  }
`;

export const JoinPublicWorkspaceMutation = `
  mutation($workspace_id: Int!) {
    joinPublicWorkspace(workspace_id: $workspace_id)
  }
`;

export const ChangePermissionMutation = `
  mutation($role_id: Int!, $permission_id: Int!, $status: Boolean!) {
    changePermission(
      role_id: $role_id
      permission_id: $permission_id
      status: $status
    )
  }
`;

export const UpdateWorkspaceMutation = `
  mutation(
    $id: Int!
    $title: String
    $type: String
    $description: String
    $details: String
    $is_general: Boolean
    $show_tasks: Boolean
    $show_messages: Boolean
    $show_files: Boolean
    $members: [Int]
  ) {
    updateWorkspace(
      id: $id
      title: $title
      type: $type
      description: $description
      details: $details
      is_general: $is_general
      show_tasks: $show_tasks
      show_messages: $show_messages
      show_files: $show_files
      members: $members
    ) {
      ${node}
      title
      status
      type
      is_general
      description
      details
      show_tasks
      show_messages
      show_files
      owner {
        ${node}
      }
      company {
        owner {
          ${node}
        }
      }
    }
  }
`;

export const LeaveWorkspaceMutation = `
  mutation($id: Int!) {
    leaveWorkspace(id: $id)
  }
`;

export const DeleteWorkspaceMutation = `
  mutation($id: Int!) {
    deleteWorkspace(id: $id)
  }
`;

export const PreferredWorkspaceMutation = `
  mutation($workspace_id: Int!) {
    preferredWorkspace(workspace_id: $workspace_id)
  }
`;

export const CancelSubscriptionMutation = `
  mutation($company_id: Int!) {
    cancelSubscription(company_id: $company_id)
  }
`;

export const ResendInvitationMutation = `
  mutation($invitation_ids: [Int]!) {
  resendInvitation(invitation_ids: $invitation_ids)
  }
`;

export const RevokeInvitationMutation = `
  mutation($invitation_ids: [Int]!) {
    revokeInvitation(invitation_ids: $invitation_ids)
  }
`;

export const UpdatePlanMutation = `
  mutation($base_plan: String!, $storage_plan: String!) {
    updatePlan(base_plan: $base_plan, storage_plan: $storage_plan) {
      ${node}
      subscription {
        ${node}
        ${SubscriptionFields}
      }
    }
  }
`;

export const UserActionLogsMutation = `
  mutation($action: String, $user_id: Int) {
    userActionLogs(action: $action, user_id: $user_id)
  }
`;

export const EditProfileMutation = `
  mutation(
    $first: String!
    $last: String!
    $username: String!
    $pic: String
    $lang: String!
    $remove_pic: Boolean
    $designation: String
    $timezone: String
    $skype: String
    $phone: String
    $city: String
    $country: String
    $web_notification: Boolean
    $email_notification: Boolean
  ) {
    editProfile(
      first: $first
      last: $last
      username: $username
      pic: $pic
      lang: $lang
      remove_pic: $remove_pic
      designation: $designation
      timezone: $timezone
      skype: $skype
      phone: $phone
      city: $city
      country: $country
      web_notification:$web_notification
      email_notification:$email_notification
    ) {
      ${node}
      ${UserFields}
      email
      status
      lang
      chat_status
      user_status
      designation
      phone
      timezone
      skype
      city
      country
      web_notification
      email_notification
      userStatus {
        id
        name
        icon
      }
    }
  }
`;

export const CreateWorkspaceMutation = `
  mutation(
    $title: String!
    $type: String!
    $is_general: Boolean
    $description: String
    $members: [Int]
    $show_tasks: Boolean
    $show_messages: Boolean
    $show_files: Boolean
  ) {
    createWorkspace(
      title: $title
      type: $type
      is_general: $is_general
      description: $description
      members: $members
      show_tasks: $show_tasks
      show_messages: $show_messages
      show_files: $show_files
    ) {
      ${node}
      ${WorkspaceFields}
      type
      is_preferred
      hasbookmarked
      owner {
        ${node}
      }
      folder{
        ${node}
      }
      boards {
        workspace_id
        ${node}
        cards{
          ${node}
          title
          sequence
          board_id
        }
        tasks{
          ${node}
          title
          completed
          end_date
          sequence
          card_id
          completed
          isbookmarked
          board_id
          created_at
          labels {
            ${node}
            name
            color
          }
          owner {
            ${node}
            ${UserFields}
            email
            roles {
              ${node}
              name
              label
            }
          }
          members {
            ${node}
            ${UserFields}
            fullname
          }
          timelogs {
            ${node}
            ${TimeLogFields}
            owner {
              ${node}
              ${UserFields}
            }
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              ${UserFields}
            }
          }
          subtasks {
            ${node}
            completed
            title
            sequence
            members {
              ${node}
              ${UserFields}
              fullname
            }
          }
          activetimelog {
            ${node}
            ${TimeLogFields}
          }
        }
        privateTasks{
          ${node}
          title
          board_id
          activetimelog {
            ${node}
            ${TimeLogFields}
          }
          owner {
            ${node}
            ${UserFields}
            email
            roles {
              ${node}
              name
              label
            }
          }
          members {
            ${node}
            ${UserFields}
            fullname
          }
          labels {
            ${node}
            name
            color
          }
          timelogs {
            ${node}
            ${TimeLogFields}
            owner {
              ${node}
              ${UserFields}
            }
          }
        }
      }
      company {
        owner {
          ${node}
        }
      }
      read_cursor
      notification_level
      unreadMessagePoint {
        ${node}
        post_id
      }
      users{
        ${node}
      }
      timelogs {
        ${node}
        ${TimeLogFields}
        task{
          ${node}
          title
        }
        owner {
          ${node}
          ${UserFields}
        }
      }
    }
  }
`;

export const SendPasswordResetEmail = `
  mutation($email: String!, $company_uri: String!) {
    sendPasswordResetEmail(email: $email, company_uri: $company_uri)
  }
`;

export const CheckPasswordResetCodeMutation = `
  mutation($email: String!, $code: Int!, $company_uri: String!) {
    checkPasswordResetCode(
      email: $email
      code: $code
      company_uri: $company_uri
    )
  }
`;

export const ResetPasswordMutation = `
  mutation(
    $email: String!
    $code: Int!
    $company_uri: String!
    $password: String!
  ) {
    resetPassword(
      email: $email
      code: $code
      company_uri: $company_uri
      password: $password
    )
  }
`;

export const GetNewWhatsNewNotificationMutation = `
  mutation($id: Int!) {
    getNewWhatsnewNotification2(id: $id) {
      ${node}
      subject
      body
      read
      user_id
      short_content
      template_id
      created_at
      updated_at
    }
  }
`;

export const CheckVerificationCodeMutation = `
  mutation($email: String!, $code: Int!) {
    checkVerificationCode(email: $email, code: $code)
  }
`;

export const GetPostByIdMutation = `
  mutation($id: Int!) {
    getPostById(id: $id) {
      ${node}
      user {
        ${node}
        ${UserFields}
      }
      ${PostFields}
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
      reactiondata
    }
  }
`;

export const GetTaskCommentMutation = `
  mutation($id: Int!) {
    getTaskComment(id: $id) {
      ${node}
      comment
      is_active
      task_id
      user_id
      owner {
        ${node}
        ${UserFields}
      }
      commentreactionset
      commentreactiondata
      sharedTaskComment {
        ${node}
        user_id
        owner {
          ${node}
          first
          last
          pic
        }
        comment
        created_at
        media {
          ${node}
          ${MediaFields}
          workspace_id
          user_id
          commentPoint {
            ${node}
            media_id
            user_id
            x
            y
            last_label
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              ${UserFields}
            }
            media_comments_point_id
            created_at
            updated_at
          }
        }
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
      created_at
      updated_at
      is_bot
    }
  }
`;

export const GetUserMutation = `
  mutation($user_id: Int!) {
    getUser(user_id: $user_id) {
      ${node}
      ${UserFields}
      email
      chat_status
      user_status
      people
    }
  }
`;

export const ReadWhatsNewNotificationMutation = `
  mutation($notification_id: [Int]) {
    readWhatsNewNotification(notification_id: $notification_id)
  }
`;
export const UpdateNotificationsMutation = `
  mutation($notification_ids: [Int],$read:Boolean) {
    updateNotifications(notification_ids: $notification_ids,read:$read)
  }
`;
export const DeleteNotificationsMutation = `
  mutation($notification_ids: [Int]) {
    deleteNotifications(notification_ids: $notification_ids)
  }
`;
export const CheckExistanceMutation = `
  mutation {
    checkExistance
  }
`;

export const GetPostsByIdsMutation = `
  mutation($ids: [Int]!) {
    getPostsByIds(ids: $ids) {
      ${node}
    }
  }
`;

export const EditUserSessionMutation = `
  mutation {
    editUserSession {
      ${node}
      created_at
      last_session_at
    }
  }
`;

export const GetMediaDetailMutation = `
  mutation($id: Int!) {
    getMediaDetail(id: $id) {
      ${node}
      file
      filename
      type
      size
      thumb
      width
      document_state
      document_version
      created_at
      updated_at
      workspace_id
      user_id
      user {
        ${node}
        ${UserFields}
      }
      commentPoint {
        ${node}
        media_id
        user_id
        x
        y
        last_label
      }
      comments {
        ${node}
        media_id
        user_id
        media_comments_point_id
        comment
        created_at
        owner {
          ${node}
          ${UserFields}
        }
      }
    }
  }
`;

export const DeleteMediaMutation = `
  mutation($id: Int!) {
    deleteMedia(id: $id)
  }
`;

export const DeleteBulkMediaMutation = `
  mutation($ids: [Int]!) {
    deleteBulkMedia(ids: $ids)
  }
`;

export const DeleteWorkspaceMemberMutation = `
  mutation($workspace_id: Int!, $user_id: Int!) {
    deleteWorkspaceMember(workspace_id: $workspace_id, user_id: $user_id)
  }
`;

export const CreateBotPostMutation = `
  mutation($workspace_id: Int!, $content: String, $media: [Int]) {
    createBotPost(
      workspace_id: $workspace_id
      content: $content
      media: $media
    ) {
      ${node}
      user {
        ${node}
        ${UserFields}
      }
      seens {
        user {
          ${node}
          first
          last
          fullname
          pic
        }
        seen_at
      }
      ${PostFields}
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
      reactiondata
      forwardedPost {
        ${node}
        user_id
        created_at
        owner {
          ${node}
          first
          last
          pic
        }
        content
        workspace_id
        media {
          ${node}
          workspace_id
          user_id
          ${MediaFields}
          commentPoint {
            ${node}
            media_id
            user_id
            x
            y
            last_label
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              ${UserFields}
            }
            media_comments_point_id
            created_at
            updated_at
          }
        }
      }
    }
  }
`;

export const DetachCommentMediaMutation = `
  mutation($task_comment_id: Int!, $media: [Int]) {
    detachTaskCommentMedia(task_comment_id: $task_comment_id, media: $media) {
      ${node}
      comment
      is_active
      task_id
      owner {
        ${node}
        first
        last
        pic
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
    }
  }
`;

export const CreateTaskCommentMutation = `
  mutation(
    $task_id: Int!
    $comment: String!
    $tagged_user_ids: [Int]
    $media: [Int]
    $workspace_id: Int
    $media_urls: [mediaUrl]
    $task_comment_id: Int
    $folder: [Int]
  ) {
    createTaskComment(
      task_id: $task_id
      comment: $comment
      tagged_user_ids: $tagged_user_ids
      media: $media
      workspace_id: $workspace_id
      media_urls: $media_urls
      task_comment_id: $task_comment_id
      folder:   $folder
    ) {
      ${node}
      comment
      is_active
      task_id
      user_id
      owner {
        ${node}
        ${UserFields}
        userStatus {
          id
          name
          icon
        }
      }
      commentreactionset
      commentreactiondata
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      sharedTaskComment {
        ${node}
        user_id
        original_task_comment_id
        owner {
          ${node}
          first
          last
          pic
        }
        comment
        created_at
        media {
          ${node}
          ${MediaFields}
          workspace_id
          user_id
          commentPoint {
            ${node}
            media_id
            user_id
            x
            y
            last_label
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              ${UserFields}
            }
            media_comments_point_id
            created_at
            updated_at
          }
        }
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
      created_at
      updated_at
      is_bot
    }
  }
`;

export const AddTaskCommentReactionMutation = `
  mutation($id: Int!, $reaction_id: Int!) {
    addTaskCommentReaction(id: $id, reaction_id: $reaction_id) {
      ${node}
      commentreactiondata
    }
  }
`;
export const RemoveTaskCommentReactionMutation = `
  mutation($id: Int!, $reaction_id: Int!) {
    removeTaskCommentReaction(id: $id, reaction_id: $reaction_id) {
      ${node}
      commentreactiondata
    }
  }
`;

export const GetNewMediaListMutation = `
  mutation($ids: [Int]!) {
    getNewMediaList(ids: $ids) {
      ${node}
      workspace_id
      user_id
      file
      file_resource
      filename
      type
      size
      width
      sequence
      created_at
      updated_at
      thumb
      isbookmarked
      document_content
      is_document_section
      commentPoint {
        ${node}
        media_id
        user_id
        x
        y
        last_label
      }
      comments {
        ${node}
        comment
        owner {
          ${node}
          ${UserFields}
        }
        media_comments_point_id
        created_at
        updated_at
      }
    }
  }
`;

export const ChangeUserAccountStatusMutation = `
  mutation($user_ids: [Int]!, $status: String!) {
    changeUserAccountStatus(user_ids: $user_ids, status: $status) {
      ${node}
      ${UserFields}
      status
      roles {
        ${node}
        name
      }
    }
  }
`;

export const ChangeUserRoleMutation = `
  mutation($user_ids: [Int]!, $role_id: Int!) {
    changeUserRole(user_ids: $user_ids, role_id: $role_id) {
      ${node}
      first
      last
      username
      status
      roles {
        ${node}
        name
      }
    }
  }
`;

export const DeleteUsersMutation = `
  mutation($user_ids: [Int]!) {
    deleteUsers(user_ids: $user_ids)
  }
`;

export const GetBoardDetailMutation = `
  mutation($id: Int!) {
    getBoardDetail(id: $id) {
      ${node}
      title
      gradient
      bgimg
    }
  }
`;

export const CreateSubTaskMutation = `
  mutation(
    $task_id: Int!
    $title: String!
    $sequence: String!
    $media: [Int]
    $workspace_id: Int!
    $media_urls: [mediaUrl]
    $folder: [Int]
    $parent_id: Int
  ) {
    createSubTask(
      task_id: $task_id
      title: $title
      sequence: $sequence
      media: $media
      workspace_id: $workspace_id
      media_urls: $media_urls
      folder: $folder
      parent_id: $parent_id
    ) {
      ${node}
      title
      completed
      task_id
      sequence
      parent_id
      members {
        ${node}
        ${UserFields}
        fullname
      }
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      owner {
        ${node}
        first
        last
        pic
      }
      childSubtasks{
        ${node}
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
    }
  }
`;

export const ClearSubTaskMutation = `
  mutation($id: Int!, $completed: Boolean!) {
    clearSubTask(id: $id, completed: $completed)
  }
`;

export const ClearChildSubTaskMutation = `
  mutation($id: Int!, $completed: Boolean!) {
    clearChildSubTask(id: $id, completed: $completed)
  }
`;

export const DeleteTaskMutation = `
  mutation($id: Int! $post_id:Int) {
    deleteTask(id: $id post_id:$post_id)
  }
`;

export const GetTaskMutation = `
  mutation($id: Int!) {
    getTask(id: $id) {
      ${node}
      title
      details
      end_date
      card_id
      sequence
      last_label
      completed
      isbookmarked
      labels {
        ${node}
        ${LabelFields}
      }
      subtasks {
          ${node}
          completed
          members {
            ${node}
            ${UserFields}
            fullname
          }
      }
      owner {
        ${node}
        ${UserFields}
      }
      members {
        ${node}
        ${UserFields}
      }
    }
  }
`;

export const DeleteSubTaskMutation = `
  mutation($id: Int!) {
    deleteSubTask(id: $id)
  }
`;

export const AttachTaskMediaMutation = `
  mutation($task_id: Int!, $media: [Int]!) {
    attachTaskMedia(task_id: $task_id, media: $media) {
      ${node}
      file_resource
      size
      workspace_id
      user_id
      file
      thumb
      filename
      type
      commentPoint {
        ${node}
        media_id
        user_id
        x
        y
        last_label
      }
      comments {
        ${node}
        media_id
        user_id
        media_comments_point_id
        comment
        created_at
        owner {
          ${node}
          ${UserFields}
        }
      }
    }
  }
`;

export const DetachTaskMediaMutation = `
  mutation($task_id: Int!, $media: [Int]) {
    detachTaskMedia(task_id: $task_id, media: $media)
  }
`;

export const DeleteTaskCommentMutation = `
  mutation($id: Int!) {
    deleteTaskComment(id: $id)
  }
`;

export const GetNewCardMutation = `
  mutation($id: Int!, $board_id: Int!) {
    getNewCard(id: $id, board_id: $board_id) {
      ${node}
      title
      board_id
      sequence
      user_id
    }
  }
`;

export const GetCardMutation = `
  mutation($id: Int!) {
    getCard(id: $id) {
      ${node}
      title
      board_id
      sequence
      user_id
    }
  }
`;

export const GetNewTaskMutation = `
  mutation($id: Int!, $board_id: Int!) {
    getNewTask(id: $id, board_id: $board_id) {
      ${node}
      title
      details
      end_date
      card_id
      sequence
      last_label
      completed
      isbookmarked
      labels {
        ${node}
        ${LabelFields}
      }
      members {
        ${node}
        ${UserFields}
      }
      owner {
        ${node}
        ${UserFields}
      }
      subtasks {
        ${node}
        completed
        members {
          ${node}
          ${UserFields}
          fullname
        }
      }
      comments {
        ${node}
        comment
      }
      media {
        ${node}
      }
    }
  }
`;

export const GetNewSubTaskMutation = `
  mutation($id: Int!, $task_id: Int!) {
    getNewSubtask(id: $id, task_id: $task_id) {
      ${node}
      title
      completed
      sequence
      owner {
        ${node}
        first
        last
        pic
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }

      }
    }
  }
`;

export const GetSubTaskMutation = `
  mutation($id: Int!) {
    getSubtask(id: $id) {
      ${node}
      title
      completed
      sequence
      owner {
        ${node}
        first
        last
        pic
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
    }
  }
`;

export const ClearBookmarkTaskMutation = `
  mutation($task_ids: [Int]!) {
    clearBookmarkTask(task_ids: $task_ids)
  }
`;

export const DeleteTasksMutation = `
  mutation($ids: [Int]!) {
    deleteTasks(ids: $ids)
  }
`;

export const CreateMediaCommentPointMutation = `
  mutation($media_id: Int!, $x: Int!, $y: Int!) {
    createMediaCommentPoint(media_id: $media_id, x: $x, y: $y) {
      ${node}
      media_id
      user_id
      x
      y
      last_label
    }
  }
`;

export const DeleteMediaCommentPointMutation = `
  mutation($id: Int!) {
    deleteMediaCommentPoint(id: $id)
  }
`;

export const UpdateMediaCOmmentPointMutation = `
  mutation($id: Int!, $x: Int!, $y: Int!) {
    updateMediaCommentPoint(id: $id, x: $x, y: $y) {
      ${node}
      media_id
      user_id
      last_label
      x
      y
    }
  }
`;

export const UpdateMediaCommentPointLabelMutation = `
  mutation($id: Int!, $last_label: String!) {
    updateMediaCommentPointLabel(id: $id, last_label: $last_label) {
      ${node}
      media_id
      user_id
      last_label
    }
  }
`;

export const DeleteMediaCommentMutation = `
  mutation($id: Int!) {
    deleteMediaComment(id: $id)
  }
`;

export const UpdateMediaCommentMutation = `
  mutation($id: Int!, $comment: String!) {
    updateMediaComment(id: $id, comment: $comment) {
      ${node}
      media_id
      user_id
      media_comments_point_id
      comment
      owner {
        ${node}
        ${UserFields}
      }
      created_at
      updated_at
    }
  }
`;

export const CreateMediaCommentMutation = `
  mutation($media_comments_point_id: Int!, $comment: String) {
    createMediaComment(
      media_comments_point_id: $media_comments_point_id
      comment: $comment
    ) {
      ${node}
      media_id
      user_id
      media_comments_point_id
      comment
      created_at
      updated_at
      owner {
        ${node}
        ${UserFields}
      }
    }
  }
`;

export const GetNewMediaPointMutation = `
  mutation($media_id: Int!, $id: Int!) {
    getNewMediaPoint(media_id: $media_id, id: $id) {
      ${node}
      media_id
      user_id
      x
      y
      last_label
    }
  }
`;

export const GetMediaPointMutation = `
  mutation($id: Int!) {
    getMediaPoint(id: $id) {
      ${node}
      media_id
      user_id
      x
      y
      last_label
    }
  }
`;

export const GetMediaCommentMutation = `
  mutation($id: Int!) {
    getMediaComment(id: $id) {
      id
      comment
      owner {
        id
        ${UserFields}
      }
      media_comments_point_id
      created_at
      updated_at
    }
  }
`;

export const GetNewMediaCommentMutation = `
  mutation($media_comments_point_id: Int!, $id: Int!) {
    getNewMediaComment(
      media_comments_point_id: $media_comments_point_id
      id: $id
    ) {
      ${node}
      media_id
      user_id
      media_comments_point_id
      comment
      created_at
      updated_at
      owner {
        ${node}
        ${UserFields}
      }
    }
  }
`;
export const CheckWorkspaceTitleAvaibilityMutation = `
  mutation($title: String!) {
    checkWorkspaceTitleAvaibility(title: $title){
      ${node}
    }
  }
`;

export const GetSignedDownloadUrlMutation = `
  mutation($id: Int!, $type: String) {
    getSignedDownloadUrl(id: $id, type: $type)
  }
`;

export const CreateTaskLabelMutation = `
  mutation($name: String, $colorcode: String) {
    createTaskLabel(name: $name, colorcode: $colorcode){
      ${node}
      ${LabelFields}
    }
  }
`;

export const DeleteTaskLabelMutation = `
  mutation($id: Int!) {
    deleteTaskLabel(id: $id)
  }
`;

export const CreateTaskTimeLogMutation = `
  mutation($task_id: Int!, $workspace_id: Int!, $start_date: String, $end_date: String, $is_start:Boolean) {
    createTaskTimeLog(task_id: $task_id, workspace_id: $workspace_id, start_date: $start_date, end_date: $end_date, is_start:$is_start){
      ${node}
      ${TimeLogFields}
      owner {
        ${node}
        ${UserFields}
      }
      task{
        ${node}
        title
      }
    }
  }
`;

export const DeleteTaskTimeLogMutation = `
  mutation($id: Int!) {
    deleteTaskTimeLog(id: $id)
  }
`;

export const DeleteTaskTimeLogsMutation = `
  mutation($workspace_id: Int,$task_id: Int, $user_id: Int!) {
    deleteTaskTimeLogs(workspace_id: $workspace_id, task_id: $task_id, user_id: $user_id)
  }
`;

export const UpdateTaskTimeLogMutation = `
  mutation($id: Int!,$description:String, $start_date: String, $end_date: String, $is_start: Boolean) {
    updateTaskTimeLog(id: $id description:$description start_date: $start_date end_date: $end_date is_start: $is_start){
      ${node}
      ${TimeLogFields}
      owner {
        ${node}
        ${UserFields}
      }
    }
  }
`;

export const CreateTasksMutation = `
  mutation(
    $task: [taskInput],
    $workspace_id: Int!
  ){
    createTasks(
      task: $task
      workspace_id: $workspace_id
    ){
      ${node}
      title
      details
      end_date
      card_id
      sequence
      last_label
      completed
      isbookmarked
      cover_image
    }
  }
`;

export const UpdateMediaMutation = `
  mutation(
    $id: Int!
    $sequence: String
    $title: String
    $folder_sequence: String
    $media_card_id: Int
    $is_read_only:Boolean
  ) {
    updateMedia(
      id: $id
      sequence: $sequence
      title: $title
      folder_sequence: $folder_sequence
      media_card_id: $media_card_id
      is_read_only: $is_read_only
    ) {
      ${node}
      ${MediaFields}
      folder_sequence
      media_card_id
      is_read_only
    }
  }
`;
export const GetUserCardMutation = `
  mutation($id: Int!) {
    getUserCard(id: $id) {
      ${node}
      title
      sequence
      user_id
    }
  }
`;

export const UpdateUserCardMutation = `
  mutation($id: Int!, $title: String, $sequence: String) {
    updateUserCard(id: $id, title: $title, sequence: $sequence) {
      ${node}
      title
      sequence
    }
  }
`;

export const UpdateMyTaskMutation = `
  mutation(
    $task_id: Int!
    $user_id: Int!
    $user_card_id: Int
    $user_sequence: String
  ) {
    updateMyTask(
      task_id: $task_id
      user_id: $user_id
      user_card_id: $user_card_id
      user_sequence: $user_sequence
    ) {
      task_id
      user_card_id
      user_sequence
    }
  }
`;

export const CreateUserCardMutation = `
  mutation(
    $title: String!
    $sequence: String!
    $user_id: Int!
  ) {
    createUserCard(
      title: $title
      sequence: $sequence
      user_id: $user_id
    ) {
      ${node}
      title
      sequence
      user_id
      color
    }
  }
`;

export const CompanyBackgroundsQuery = `
  query {
    companyBackgrounds{
      ${node}
      colorcode
      path
      file
    }
  }
`;

export const AddMediaBookmarkMutation = `
  mutation($media_id: Int!) {
    addMediaBookmark(media_id: $media_id) {
      ${node}
      isbookmarked
    }
  }
`;
export const RemoveMediaBookmarkMutation = `
  mutation($media_id: Int!) {
    removeMediaBookmark(media_id: $media_id) {
      ${node}
      isbookmarked
    }
  }
`;
export const CreateBoardTemplateMutation = `
  mutation(
    $workspace_id: Int!
    $title: String!
  ){
    createBoardTemplate(
      workspace_id:$workspace_id
      title: $title
    ){
      ${node}
      ${BoardTemplateFields}
      owner {
        ${node}
        ${UserFields}
      }
    }
  }
`;

export const DeleteBoardTemplateMutation = `
  mutation($id: Int!) {
    deleteBoardTemplate(id: $id)
  }
`;

export const ApplyBoardTemplateMutation = `
  mutation(
    $template_id: Int!
    $workspace_id: Int!
  ){
    applyBoardTemplate(
      template_id:$template_id
      workspace_id:$workspace_id
    ){
      ${node}
      ${WorkspaceFields}
    }
  }
`;
export const CreateFolderMutation = `
  mutation(
    $title: String!
  ){
    createFolder(title:$title){
      ${node}
      ${FolderFields}
    }
  }
`;
export const UpdateFolderMutation = `
  mutation(
    $title: String!
    $id: Int!
  ){
    updateFolder(
      id: $id
      title: $title
    ){
      ${node}
      ${FolderFields}
    }
  }
`;
export const DeleteFolderMutation = `
  mutation($id: Int!,$delete_workspaces:Boolean){
    deleteFolder(id:$id,delete_workspaces:$delete_workspaces)
  }
`;
export const AddWorkspaceToFolderMutation = `
  mutation(
    $workspace_ids :  [Int]!
    $folder_id: Int!
  ){
    addWorkspaceFolder(
      folder_id: $folder_id
      workspace_ids : $workspace_ids
    ){
      ${node}
    }
  }
`;

export const UpdateWorkspaceFolderMutation = `
  mutation(
    $workspace_id: Int!
    $folder_id: Int!
    $old_folder_id: Int!
  ){
    updateWorkspaceFolder(
      workspace_id: $workspace_id
      folder_id: $folder_id
      old_folder_id: $old_folder_id
    ){
      ${node}
    }
  }
`;
export const RemoveWorkspaceFromFolderMutation = `
  mutation(
    $workspace_id: Int!
    $folder_id: Int!
  ){
    removeWorkspaceFolder(
      workspace_id: $workspace_id
      folder_id: $folder_id
    ){
      ${node}
    }
  }
`;
export const DeleteUserCardMutation = `
  mutation($id: Int!) {
    deleteUserCard(id: $id)
  }
`;
export const UpdateUserOnboardingMutation = `
  mutation($flag_name: String!, $flag_value: Boolean) {
    updateUserOnboarding(flag_name: $flag_name, flag_value: $flag_value) {
      ${OnboardingFields}
    }
  }
`;
export const MoveTaskMutation = `
  mutation(
    $board_id: Int!
    $card_id: Int
    $title: String!
    $details: String
    $sequence: String
    $media: [Int]
    $subtask: [subTaskInput]
    $labels: [taskLabelInput]
    $start_date: String
    $end_date: String
    $note: String
    $is_lock: Boolean
    $is_invisible: Boolean
  ){
    moveTask(
      board_id:$board_id
      card_id: $card_id
      title: $title
      details: $details
      sequence: $sequence
      media: $media
      subtask: $subtask
      start_date: $start_date
      end_date: $end_date
      labels: $labels
      note: $note
      is_lock: $is_lock
      is_invisible: $is_invisible
    ){
      ${node}
      title
      details
      start_date
      end_date
      card_id
      sequence
      last_label
      completed
      isbookmarked
      cover_image
      is_lock
      is_invisible
      labels {
        ${node}
        ${LabelFields}
      }
      members {
        ${node}
        ${UserFields}
      }
      subtasks {
        ${node}
        completed
        title
        sequence
        members {
          ${node}
          ${UserFields}
          fullname
        }
      }
      media{
        ${node}
      }
      owner {
        ${node}
        ${UserFields}
      }
      note{
        id
        note
        document_version
      }
    }
  }
`;
export const UpdateOnboardingStepMutation = `
  mutation($onboarding_step : Int!) {
    updateOnboardingStep(onboarding_step: $onboarding_step) {
      ${UserFields}
      onboarding_step
    }
  }
`;

export const CreateMediaFolderMutation = `
  mutation(
    $title : String!
    $workspace_id: Int!
  ) {
    createMediaFolder(title: $title, workspace_id: $workspace_id) {
      ${node}
      ${MediaFolderFields}
      owner{
        ${UserFields}
      }
    }
  }
`;

export const RenameMediaFolderMutation = `
  mutation(
    $title : String!
    $id: Int!
  ) {
    updateMediaFolder(id: $id,title: $title) {
      ${node}
      title
      owner{
        ${UserFields}
      }
    }
  }
`;

export const DeleteMediaFolderMutation = `
  mutation(
    $id: Int!
  ) {
    deleteMediaFolder(id: $id)
  }
`;

export const MoveMediaFolderMutation = `
  mutation(
    $folder_id : [Int]
    $workspace_id: Int!
  ) {
    moveMediaFolder(folder_id: $folder_id,workspace_id: $workspace_id)
  }
`;
export const CopyMediaFolderMutation = `
  mutation(
    $folder_id : [Int]
    $workspace_id: Int!
  ) {
    createDuplicateMediaFolder(folder_id: $folder_id,workspace_id: $workspace_id)
  }
`;

export const MoveMediaMutation = `
  mutation(
    $media_id : [Int]
    $workspace_id: Int!
    $target_folder_id: Int
  ) {
    moveMedia(media_id: $media_id,workspace_id: $workspace_id,target_folder_id: $target_folder_id)
  }
`;
export const CopyMediaMutation = `
  mutation(
    $media_id : [Int]
    $workspace_id: Int!
    $target_folder_id: Int
  ) {
    createDuplicateMedia(media_id: $media_id,workspace_id: $workspace_id,target_folder_id: $target_folder_id){
      ${node}
      ${MediaFields}
      media_folder_id
      workspace_id
      user_id
      document_state
      commentPoint {
        ${node}
        media_id
        user_id
        x
        y
        last_label
      }
      comments {
        ${node}
        comment
        owner {
          ${node}
          ${UserFields}
        }
        media_comments_point_id
        created_at
        updated_at
      }
    }
  }
`;
export const DeleteBulkFolderMutation = `
  mutation($folder_id: [Int]!) {
    deleteMediaFolders(folder_id: $folder_id)
  }
`;
export const AttachFolderMediaMutation = `
  mutation(
    $folder_id: Int!,
    $media: [Int],
    $workspace_id : Int
    $media_urls: [mediaUrl]
  ) {
    attachFolderMedia(
      folder_id: $folder_id,
      media: $media,
      workspace_id:$workspace_id,
      media_urls:$media_urls,
    ) {
      ${node}
      ${MediaFields}
      workspace_id
      user_id
      created_at
      updated_at
      folder_sequence
      commentPoint {
        ${node}
        media_id
        user_id
        x
        y
        last_label
      }
      comments {
        ${node}
        comment
        owner {
          ${node}
          ${UserFields}
        }
        created_at
        updated_at
      }
    }
  }
`;
export const ClearBoardMutation = `
  mutation($id: Int!) {
    clearBoard(
      id: $id
    )
  }
`;
export const AddSubtaskReactionMutation = `
  mutation($id : Int!, $reaction_id: Int!) {
    addSubtaskReaction(id: $id, reaction_id: $reaction_id ) {
      subtaskreactionset
      subtaskreactiondata
    }
  }
`;
export const RemoveSubtaskReactionMutation = `
  mutation($id : Int!, $reaction_id: Int!) {
    removeSubtaskReaction(id: $id, reaction_id: $reaction_id ) {
      subtaskreactionset
      subtaskreactiondata
    }
  }
`;

export const DetachMessageFolderMutation = `
  mutation($post_id: Int!, $folder: [Int]) {
    detachMessageFolder(post_id: $post_id, folder: $folder) {
      ${node}
      user {
        ${node}
        ${UserFields}
      }
      ${PostFields}
      workspace_id
      user_id
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
      }
    }
  }
`;
export const DetachSubtaskFolderMutation = `
  mutation($subtask_id: Int!, $folder: [Int]) {
    detachSubtaskFolder(subtask_id: $subtask_id, folder: $folder) {
      ${node}
    }
  }
`;
export const DetachCommentFolderMutation = `
  mutation($task_comment_id: Int!, $folder: [Int]) {
    detachTaskCommentFolder(task_comment_id: $task_comment_id, folder: $folder) {
      ${node}
      owner {
        ${node}
        ${UserFields}
      }
      comment
      user_id
      task_id
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
      }
    }
  }
`;
export const UpgradeSubscriptionMutation = `
  mutation($company_id: Int!) {
    upgradeSubscription(company_id: $company_id)
  }
`;
export const AttachTaskMediaFolderMutation = `
  mutation($task_id: Int!, $folder: [Int]!) {
    attachTaskMediaFolder(task_id: $task_id, folder: $folder) {
      ${node}
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
    }
  }
`;
export const DetachTaskMediaFolderMutation = `
  mutation($task_id: Int!, $folder: [Int]) {
    detachTaskMediaFolder(task_id: $task_id, folder: $folder)
  }
`;

export const CreateMediaCardMutation = `
  mutation($media_folder_id: Int!, $sequence: String!, $title : String!) {
    createMediaCard(
      media_folder_id: $media_folder_id,
      sequence: $sequence,
      title: $title,
    ) {
      ${node}
      ${MediaCardFields}
    }
  }
`;
export const UpdateMediaCardMutation = `
  mutation($id: Int!, $sequence: String, $title : String) {
    updateMediaCard(
      id: $id,
      sequence: $sequence,
      title: $title,
    ) {
      ${node}
      ${MediaCardFields}
    }
  }
`;
export const DeleteMediaCardMutation = `
  mutation($id: Int!,$delete_media: Boolean! ) {
    deleteMediaCard(id: $id, delete_media: $delete_media)
  }
`;
export const AttachMediaToSectionMutation = `
  mutation(
    $folder_id: Int!,
    $media: [Int],
    $workspace_id : Int
    $media_card_id: Int
    $media_urls: [mediaUrl]
  ) {
    updateMediaWithCard(
      folder_id: $folder_id,
      media: $media,
      workspace_id:$workspace_id,
      media_urls:$media_urls,
      media_card_id:$media_card_id
    ) {
      ${node}
      ${MediaFields}
      folder_sequence
      media_card_id
      media_folder_id
      user_id
      workspace_id
      created_at
      updated_at
      commentPoint {
        ${node}
        media_id
        user_id
        x
        y
        last_label
      }
      comments {
        ${node}
        comment
        owner {
          ${node}
          ${UserFields}
        }
        media_comments_point_id
        created_at
        updated_at
      }
    }
  }
`;

export const PlansQuery = `
  query($filter_id: Int) {
      plans(filter_id: $filter_id){
        id
        name
        name_tag
        product_id
        color
        user_limit
        display_text
        display_sub_text
        description
        status
        type
        is_popular
        coupon_code_visible
        is_unlimited
        isInputShow
        coupenCode
        couponCodeInputVisible
        planPricing{
          id
          amount
          price_id
          validity
        }
      }
    }
`;

export const UpdatePlanNewMutation = `
  mutation($plan_id: Int!, $pricing_id: Int!) {
    updatePlanNew(plan_id: $plan_id, pricing_id: $pricing_id) {
      ${node}
      subscription {
        ${node}
        ${SubscriptionFields}
      }
    }
  }
`;

export const CreatePlanContactusMutation = `
  mutation($first: String!,$last: String!,$email: String!,$phone: String!,$user_limit: String!) {
    createPlanContactus(first: $first, last: $last, email:$email, phone:$phone, user_limit:$user_limit){
      ${node}
      ${PlanContactusFields}
    }
  }
`;

export const CheckDowngradePlanMutation = `
  mutation($plan_id: Int!, $pricing_id: Int!) {
    checkDowngradePlan(plan_id: $plan_id, pricing_id: $pricing_id)
  }
`;

export const PlanQuery = `
  query($plan_id: Int!) {
    plan(plan_id: $plan_id) {
      id
      name
      product_id
      color
      user_limit
      display_text
      display_sub_text
      description
      status
      type
      is_popular
      coupon_code_visible
      is_unlimited
      isInputShow
      coupenCode
      couponCodeInputVisible
      planPricing{
        id
        amount
        price_id
      }
      featurePlan {
        id
        name
        key
        video_url
        description
      }
    }
  }
`;

export const FeaturesQuery = `
  query {
    features {
      id
      name
      key
      video_url
      description
    }
  }
`;

export const CheckCouponMutation = `
  mutation($coupon_code: String!) {
    checkCoupon(coupon_code: $coupon_code)
  }
`;

export const UpdateMediaFolderViewMutation = `
  mutation($folder_id: Int!,$is_grid_view:Boolean!) {
    updateMediaFolderView(folder_id: $folder_id,is_grid_view:$is_grid_view)
  }
`;
export const AddMediaFolderBookmarkMutation = `
  mutation($media_folder_id: Int!) {
    addMediaFolderBookmark(media_folder_id: $media_folder_id) {
      ${node}
      isbookmarked
    }
  }
`;
export const RemoveMediaFolderBookmarkMutation = `
  mutation($media_folder_id: Int!) {
    removeMediaFolderBookmark(media_folder_id: $media_folder_id) {
      ${node}
      isbookmarked
    }
  }
`;

export const CustomFieldQuery = `
  query($workspace_id: Int) {
    customFields(workspace_id: $workspace_id) {
      ${node}
      workspace_id
      user_id
      title
      details
      type
      icon
      sequence
      is_active
      is_suggested
      show_in_task
      customFieldOption {
        ${node}
        c_f_id
        colorcode
        option
        label
        sequence
        customFieldOptionSelection{
          ${node}
        }
      }
    }
  }
`;

export const AddNewFieldMutation = `
  mutation($title: String, $type: String, $details: String, $workspace_id: Int!, $sequence: String, $show_in_task: Boolean!, $options: [customFieldOptionInput]!) {
    createCustomField(title: $title, type: $type, details: $details, workspace_id: $workspace_id, sequence: $sequence, show_in_task: $show_in_task, options:$options) {
      ${node}
      workspace_id
      user_id
      title
      details
      type
      icon
      sequence
      is_active
      is_suggested
      show_in_task
      customFieldOption {
        ${node}
        c_f_id
        colorcode
        option
        label
        sequence
        customFieldOptionSelection{
          ${node}
        }
      }
    }
  }
`;

export const CreateCustomFieldOptionMutation = `
  mutation($c_f_id: Int!, $option: String!, $label: String!, $sequence: String!) {
    createCustomFieldOption(c_f_id: $c_f_id, option: $option, label: $label, sequence: $sequence) {
      ${node}
      workspace_id
      user_id
      title
      details
      type
      icon
      sequence
      is_active
      is_suggested
      customFieldOption {
        ${node}
        c_f_id
        colorcode
        option
        label
        sequence
        customFieldOptionSelection{
          ${node}
        }
      }
    }
  }
`;

export const DeleteCustomFieldOptionMutation = `
  mutation($id: Int!) {
    deleteCustomFieldOption(id: $id)
  }
`;

export const UpdateCustomFieldMutation = `
mutation($id: Int!, $title: String, $status: Boolean,$show_in_task:Boolean){
  updateCustomField(id: $id, title: $title, status: $status, show_in_task:$show_in_task) {
    ${node}
    title
    user_id
    sequence
    show_in_task
  }
}
`;

export const DeleteCustomFieldMutation = `
  mutation($id: Int!) {
    deleteCustomField(id: $id)
  }
`;

export const CreateCustomFieldOptionSelectionMutation = `
mutation($task_id: Int!, $c_f_id: Int!, $c_f_o_id: Int, $option_text: String,){
  createCustomFieldOptionSelection(task_id: $task_id, c_f_id: $c_f_id, c_f_o_id: $c_f_o_id, option_text: $option_text) {
    ${node}
    task_id
    c_f_id
    c_f_o_id
    option_text
  }
}
`;

export const UpdateCustomFielOptiondMutation = `
  mutation($id: Int!, $colorcode: String, $label: String, $sequence:String){
    updateCustomFieldOption(id: $id, colorcode: $colorcode, label: $label, sequence:$sequence) {
      ${node}
      colorcode
      label
      c_f_id
      option
      sequence
    }
  }
`;
export const DeleteImportDataMutation = `
  mutation($id: Int!) {
    deleteImportDataHistory(id: $id) 
  }
`;
export const DeleteCustomFieldOptionSelectionMutation = `
  mutation($c_f_o_s_id: Int!) {
    deleteCustomFieldOptionSelection(c_f_o_s_id: $c_f_o_s_id) 
  }
`;
export const GenerateJwtTokenForUser = `
  mutation {
    generateJwtTokenForUser 
  }
`;
export const SendVideoCallInvitation = `
  mutation($workspace_id: Int!, $room_name: String!) {
    sendVideoCallInvitation(workspace_id: $workspace_id,room_name: $room_name){
      ${node}
      user {
        ${node}
        ${UserFields}
      }
      ${PostFields}
      seens {
        user {
          ${node}
          ${UserFields}
        }
        seen_at
      }
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
      reactiondata
      forwardedPost {
        ${node}
        user_id
        created_at
        original_post_id
        owner {
          ${node}
          first
          last
          pic
        }
        content
        workspace_id
        media {
          ${node}
          ${MediaFields}
          workspace_id
          user_id
          commentPoint {
            ${node}
            media_id
            user_id
            x
            y
            last_label
          }
          comments {
            ${node}
            comment
            owner {
              ${node}
              ${UserFields}
            }
            media_comments_point_id
            created_at
            updated_at
          }
        }
      }
    } 
  }`;

export const AttachWorkspaceFieldMutation = `
  mutation($w_f_id: Int!) {
    attachWorkspaceField(w_f_id: $w_f_id) {
      ${node}
      isvisible
    }
  }
`;
export const DetachWorkspaceFieldMutation = `
  mutation($w_f_id: Int!) {
    detachWorkspaceField(w_f_id: $w_f_id) {
      ${node}
      isvisible
    }
  }
`;
export const UpdateCardColorMutation = `
  mutation($card_id: Int!,$color: String) {
    updateCardColor(card_id: $card_id,color:$color) {
      ${node}
      title
      color
    }
  }
`;
export const UpdateCustomCardColorMutation = `
  mutation($user_card_id: Int!,$color: String) {
    updateCustomCardColor(user_card_id: $user_card_id,color:$color) {
      ${node}
      title
      color
    }
  }
`;
export const UpdateHourlyRateMutation = `
  mutation($hourly_rate : Int!) {
    updateHourlyRate(hourly_rate: $hourly_rate) {
      ${UserFields}
      hourly_rate
    }
  }
`;

export const MoveCardMutation = `
  mutation(
    $card_id:Int!
    $board_id: Int!
    $title: String!
    $sequence: String
  ){
    moveCard(
      card_id:$card_id
      board_id:$board_id
      title: $title
      sequence: $sequence
      ){
        ${node}
        title
        board_id
        sequence
        user_id
        owner {
          ${node}
          ${UserFields}
        }
    }
  }
`;
export const AddMessagePinMutation = `
  mutation($post_id: Int!) {
    addPostPin(post_id: $post_id) {
      ${node}
      ispinpost
    }
  }
`;
export const RemoveMessagePinMutation = `
  mutation($post_id: Int!) {
    removePostPin(post_id: $post_id) {
      ${node}
      ispinpost
    }
  }
`;
export const CreateBookmarkMutation = `
  mutation(
    $title: String!
    $link: String!
    $workspace_id: Int!
    $bookmark_folder_id: Int
  ){
    createBookmark(
      title:$title
      link:$link
      workspace_id:$workspace_id
      bookmark_folder_id:$bookmark_folder_id
    ){
      ${node}
      ${BookmarkFields}
    }
  }
`;

export const BookmarksQuery = `
  query($workspace_id: Int!) {
    bookmarks(workspace_id: $workspace_id) {
      ${node}
      ${BookmarkFields}
    }
  }
`;

export const CreateBookmarkFolderMutation = `
  mutation(
    $title: String!
    $workspace_id: Int!
  ){
    createBookmarkFolder(title:$title, workspace_id:$workspace_id){
      ${node}
      ${BookmarkFolderFields}
      bookmarks {
        ${node}
        ${BookmarkFields}  
      }
    }
  }
`;

export const BookmarkFoldersQuery = `
query($workspace_id: Int!){
  bookmarkFolders(workspace_id: $workspace_id){
    ${node}
    ${BookmarkFolderFields}
    bookmarks {
      ${node}
      ${BookmarkFields}  
    }
  }
}
`;
export const UpdateBookmarkMutation = `
  mutation(
    $title: String
    $link: String
    $id: Int!
  ){
    updateBookmark(
      id: $id
      title:$title
      link:$link
    ){
      ${node}
      ${BookmarkFields}
    }
  }
`;
export const DeleteBookmarkMutation = `
  mutation($id: Int!){
    deleteBookmark(id:$id)
  }
`;
export const UpdateBookmarkFolderMutation = `
  mutation(
    $title: String!
    $id: Int!
  ){
    updateBookmarkFolder(
      id: $id
      title: $title
    ){
      ${node}
      ${BookmarkFolderFields}
    }
  }
`;
export const DeleteBookmarkFolderMutation = `
  mutation($id: Int!){
    deleteBookmarkFolder(id:$id)
  }
`;
export const MoveMediaCardMutation = `
  mutation(
    $media_id : [Int]
    $media_card_id: Int
  ) {
    moveMediaCard(media_card_id: $media_card_id,media_id: $media_id)
  }
`;
export const CreateDocumentMutation = `
  mutation(
    $title : String
    $workspace_id: Int!
    $folder_id: Int
  ) {
    createDocument(title: $title,workspace_id: $workspace_id,folder_id: $folder_id){
      ${node}
      ${MediaFields}
      commentPoint {
        ${node}
        media_id
        user_id
        x
        y
        last_label
      }
      comments {
        ${node}
        media_id
        user_id
        media_comments_point_id
        comment
        created_at
        owner {
          ${node}
          ${UserFields}
        }
      }
      folder_sequence
    }
  }
`;
export const UpdateDocumentMutation = `
  mutation(
    $id: Int
    $title : String
    $sequence : String
    $folder_sequence : String
    $document_state : String
    $document_content : String
    $document_version: Int
  ) {
    updateDocument(id: $id,title: $title,sequence: $sequence,folder_sequence: $folder_sequence,document_state: $document_state,document_version:$document_version,document_content:$document_content){
      ${node}
      ${MediaFields}
      folder_sequence
    }
  }
`;
export const CreateDuplicateCustomFieldMutation = `
  mutation(
    $c_f_id: Int
    $title : String
    $workspace_id : Int
  ){
    createDuplicateCustomField(
      c_f_id: $c_f_id
      title : $title 
      workspace_id : $workspace_id
    ){
      ${node}
      workspace_id
      user_id
      title
      details
      type
      icon
      sequence
      is_active
      is_suggested
      show_in_task
      customFieldOption {
        ${node}
        c_f_id
        colorcode
        option
        label
        sequence
        customFieldOptionSelection{
          ${node}
        }
      }
    }
  }
`;
export const CreateTaskNoteMutation = `
  mutation(
    $note : String
    $workspace_id: Int!
    $task_id: Int!
  ) {
    createTaskNote(note: $note, workspace_id: $workspace_id,task_id: $task_id){
      ${node}
      note
      task_id
      user_id
      document_version
    }
  }
`;
export const UpdateTaskNoteMutation = `
  mutation(
    $id: Int
    $note : String
    $document_version: Int
  ) {
    updateTaskNote(id: $id,note: $note,document_version:$document_version){
      ${node}
      note
      task_id
      user_id
      document_version
    }
  }
`;
export const TaskNoteQuery = `
  query($id: Int!) {
    taskNote(id: $id) {
      ${node}
      note
      task_id
      user_id
      document_version
    }
  }
`;

export const StatusesQuery = `
  query {
    userStatuses {
      id
      name
      icon
    }
  }
`;
export const UpdateUserStatusMutation = `
  mutation($status : Int) {
    updateUserStatus(status: $status) {
      id
      ${UserFields}
      userStatus{
        id
        name
        icon
      }
    }
  }
`;
export const MoveSubtaskMutation = `
  mutation(
    $workspace_id: Int!
    $task_id: Int!
    $title: String!
    $details: String
    $media: [Int]
    $folder: [Int]
    $end_date: String
    $childSubtasks: [subTaskInput]
  ){
    moveSubtask(
      workspace_id:$workspace_id
      task_id: $task_id
      title: $title
      details: $details
      media: $media
      folder: $folder
      end_date: $end_date
      childSubtasks: $childSubtasks
    ){
      ${node}
      title
      completed
      task_id
      sequence
      parent_id
      members {
        ${node}
        ${UserFields}
        fullname
      }
      mediafolder{
        ${node}
        ${MediaFolderFields}
      }
      owner {
        ${node}
        first
        last
        pic
      }
      childSubtasks{
        ${node}
      }
      media {
        ${node}
        workspace_id
        user_id
        ${MediaFields}
        commentPoint {
          ${node}
          media_id
          user_id
          x
          y
          last_label
        }
        comments {
          ${node}
          comment
          owner {
            ${node}
            ${UserFields}
          }
          media_comments_point_id
          created_at
          updated_at
        }
      }
    }
  }
`;

export const CreateBoardBackgroundMutation = `
  mutation(
    $board_id: Int!
    $workspace_id: Int!
    $gradient: String
    $bgimg: String
    $gradient_list: String
    $bgimg_list: String
  ) {
    createBoardBackground(board_id: $board_id, workspace_id: $workspace_id, gradient: $gradient, bgimg: $bgimg, gradient_list: $gradient_list, bgimg_list: $bgimg_list){
      ${node}
      user_id
      board_id
      gradient
      gradient_list
      bgimg
      bgimg_list
    }
  }
`;

export const UpdateBoardBackgroundMutation = `
  mutation(
    $id: Int!
    $gradient: String
    $bgimg: String
    $gradient_list: String
    $bgimg_list: String
  ) {
    updateBoardBackground(id: $id, gradient: $gradient, bgimg: $bgimg, gradient_list: $gradient_list, bgimg_list: $bgimg_list){
      ${node}
      user_id
      board_id
      gradient
      gradient_list
      bgimg
      bgimg_list
    }
  }
`;

export const AttachMediaMutation = `
  mutation($workspace_id: Int!, $media_urls: [mediaUrl]!) {
    attachMedia(workspace_id: $workspace_id, media_urls: $media_urls) {
      ${node}
      file_resource
      size
      workspace_id
      user_id
      file
      thumb
      filename
      type
      created_at
      commentPoint {
        ${node}
        media_id
        user_id
        x
        y
        last_label
      }
      comments {
        ${node}
        media_id
        user_id
        media_comments_point_id
        comment
        created_at
        owner {
          ${node}
          ${UserFields}
        }
      }
    }
  }
`;