import { set } from "lodash";
import Vue from "vue";
import {
  ADD_TO_COMPANIES,
  RESET_COMPANIES,
  SIGNOUT_FROM_COMPANY,
  SET_ACTIVE_COMPANY,
  SET_LAST_VISIT,
  SET_USER_LANG,
  RESET_AUTH_STORE_STATE,
  WHATS_NEW_BELL,
  COMPANY_STATUS,
  SET_WALKTHROUGH,
  COMPANY_STORAGE_STATUS,
  SET_ONLINE_MEMBERS,
} from "../mutation-types";
import { app } from "@/main";

const getDefaultState = () => {
  return {
    companies: {},
    activeCompany: null,
    notifications: {},
    onlineMembers: null,
  };
};

const state = getDefaultState();

const getters = {
  auth: (state) => {
    return state.companies;
  },
  activeCompany: (state) => {
    return state.activeCompany;
  },
  companyNotification: (state) => {
    return state.notifications;
  },
  onlineMembers: () => {
    return state.onlineMembers;
  },
  accessToken: (state) => {
    try {
      const companies = state.companies;
      const activeCompany = state.activeCompany;
      return companies[activeCompany].accessToken;
    } catch (error) {
      return null;
    }
  },
};

const mutations = {
  [ADD_TO_COMPANIES]: (state, payload) => {
    Vue.set(state.companies, payload.companyUri, payload);
  },
  [RESET_COMPANIES]: (state, payload) => {
    state.companies = payload;
  },
  [SIGNOUT_FROM_COMPANY]: (state, payload) => {
    state.activeCompany = null;
    Vue.delete(state.companies, payload);
  },
  [SET_ACTIVE_COMPANY]: (state, payload) => {
    state.activeCompany = payload;
  },
  [SET_ONLINE_MEMBERS]: (state, payload) => {
    state.onlineMembers = payload;
  },
  [SET_LAST_VISIT]: (state, payload) => {
    const companies = state.companies;
    set(companies[state.activeCompany], "lastVisit", payload);
    state.companies = companies;
  },
  [SET_USER_LANG]: (state, payload) => {
    app.$i18n.locale = payload;
  },
  [RESET_AUTH_STORE_STATE]: (state) => {
    Object.assign(state, getDefaultState());
  },
  [WHATS_NEW_BELL]: (state, payload) => {
    const companies = state.companies;
    set(companies[state.activeCompany], "whatsnewbell", payload);
    state.companies = companies;
  },
  [COMPANY_STATUS]: (state, payload) => {
    const companies = state.companies;
    set(companies[state.activeCompany], "status", payload);
    state.companies = companies;
  },
  [COMPANY_STORAGE_STATUS]: (state, payload) => {
    const companies = state.companies;
    set(companies[state.activeCompany], "storage", payload);
    state.companies = companies;
  },
  [SET_WALKTHROUGH]: (state, payload) => {
    const companies = state.companies;
    set(companies[state.activeCompany], "walkthrough", payload);
    state.companies = companies;
  },
};

const actions = {
  addToCompanies: ({ commit }, payload) => {
    commit(ADD_TO_COMPANIES, payload);
  },
  resetCompanies: ({ commit }, payload) => {
    commit(RESET_COMPANIES, payload);
  },
  signoutFromCompany: ({ commit }, payload) => {
    commit(SIGNOUT_FROM_COMPANY, payload);
  },
  activeCompany: ({ commit }, payload) => {
    commit(SET_ACTIVE_COMPANY, payload);
  },
  setOnlineMembers: ({ commit }, payload) => {
    commit(SET_ONLINE_MEMBERS, payload);
  },
  setLastVisit: ({ commit }, payload) => {
    commit(SET_LAST_VISIT, payload);
  },
  setLang: ({ commit }, payload) => {
    commit(SET_USER_LANG, payload);
  },
  setWhatsnewBell: ({ commit }, payload) => {
    commit(WHATS_NEW_BELL, payload);
  },
  resetAuth: ({ commit }) => {
    commit(RESET_AUTH_STORE_STATE);
  },
  companyStatus: ({ commit }, payload) => {
    commit(COMPANY_STATUS, payload);
  },
  companyStorage: ({ commit }, payload) => {
    commit(COMPANY_STORAGE_STATUS, payload);
  },
  setWalkthrough: ({ commit }, payload) => {
    commit(SET_WALKTHROUGH, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
