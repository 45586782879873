import { SET_LAST_ROUTE, RESET_LAST_ROUTE } from "../mutation-types";

const getDefaultState = () => {
  return {
    path: null,
    params: null
  };
};

const state = getDefaultState();

const getters = {
  path: state => {
    return state.path;
  },
  params: state => {
    return state.params;
  },
  lastroute: state => {
    return {
      path: state.path,
      params: state.params
    };
  }
};

const mutations = {
  [SET_LAST_ROUTE]: (state, payload) => {
    const { fullPath, params } = payload;
    state.path = fullPath;
    state.params = params;
  },
  [RESET_LAST_ROUTE]: state => {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  setLastRoute: ({ commit }, payload) => {
    commit(SET_LAST_ROUTE, payload);
  },
  resetLastRoute: ({ commit }) => {
    commit(RESET_LAST_ROUTE);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
