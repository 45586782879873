import Vue from "vue";
import Router from "vue-router";
import { routes } from "./routes";
import { store } from "@/store";
// import { app } from "@/main";
Vue.use(Router);

const router = new Router({
  routes,
  mode: "history",
  scrollBehviour(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
});

router.onReady(() => {
  if (process.env.NODE_ENV === "development") {
    window.ga("set", "sendHitTask", null);
  }
});

router.beforeEach((to, _from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const { company } = to.params;
    store.dispatch("activeCompany", company);
    if (!store.getters.auth[company]) {
      next({
        name: "SigninView",
        path: "/",
      });
    } else if (
      to.matched.some((record) => record.meta.requiresPremiumPlan) &&
      !Vue.prototype.$checkSpecificFeature(to.matched[1].meta.slug)
    ) {
      next({
        name: _from.name,
        path: _from.path,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  window.ga("set", "page", to.fullPath);
  window.ga("send", "pageview");
});

export default router;
