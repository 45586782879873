import Vue from "vue";

export default Vue.directive("dragScroll", {
  bind: el => {
    let lastX = null;
    let lastY = null;

    const down = event => {
      if (event.target !== el) return;
      lastX = event.clientX;
      lastY = event.clientY;
      el.addEventListener("mouseup", up);
      el.addEventListener("mousemove", move);
    };

    const move = event => {
      if (!lastX || !lastY) return;
      el.scrollLeft -= event.clientX - lastX;
      el.scrollTop -= event.clientY - lastY;
      lastX = event.clientX;
      lastY = event.clientY;
    };

    const up = () => {
      lastX = null;
      lastY = null;
      el.removeEventListener("mouseup", up);
      el.removeEventListener("mousemove", move);
    };

    el.addEventListener("mousedown", down);

    el.$destroy = () => {
      el.removeEventListener("mousedown", down);
      el.removeEventListener("mousemove", move);
      el.removeEventListener("mouseup", up);
    };
  },
  unbind: el => {
    el.$destroy();
  }
});
