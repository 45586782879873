import {
  UPDATE_PRESENCE,
  RESET_USER_PRESENCE_STORE_STATE,
} from "../mutation-types";

const getDefaultState = () => {
  return {
    presentUsers: {},
  };
};

const state = getDefaultState();

const getters = {
  presentUsers: (state) => {
    return state.presentUsers;
  },
};

const mutations = {
  [UPDATE_PRESENCE]: (state, payload) => {
    state.presentUsers = payload.map((user) => parseInt(user));
  },
  [RESET_USER_PRESENCE_STORE_STATE]: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  updatePresenceList: ({ commit }, payload) => {
    commit(UPDATE_PRESENCE, payload);
  },
  resetUserPresenceStore: ({ commit }) => {
    commit(RESET_USER_PRESENCE_STORE_STATE);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
