import Vue from "vue";

import "./styles/quasar.scss";
import iconSet from "quasar/icon-set/mdi-v4.js";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/mdi-v4/mdi-v4.css";
import {
  Quasar,
  QTooltip,
  QBtn,
  QIcon,
  QList,
  QItem,
  QItemSection,
  QMenu,
  QDate,
  QAvatar,
  QCircularProgress,
  QLinearProgress,
  QInput,
  QDialog,
  QCard,
  QCardSection,
  QCardActions,
  QSpace,
  QBadge,
  QToolbar,
  QToolbarTitle,
  QSelect,
  QItemLabel,
  QBtnToggle,
  QSeparator,
  QCheckbox,
  QExpansionItem,
  QImg,
  QSpinner,
  QToggle,
  QFab,
  QFabAction,
  QLayout,
  QPageContainer,
  QPage,
  QHeader,
  QFooter,
  QDrawer,
  QChip,
  QTabs,
  QTab,
  QTabPanels,
  QInfiniteScroll,
  QTabPanel,
  QOptionGroup,
  Notify,
  ClosePopup,
  Ripple,
  Scroll,
  QColor,
  QTable,
  QTh,
  QTr,
  QTd,
  QTime,
  QPopupProxy,
  QPopupEdit,
  QRating,
  QSlider,
  QBar,
  QKnob,
  LoadingBar,
  QSpinnerDots,
  QSkeleton,
  QBanner,
  QRadio,
} from "quasar";

Vue.use(Quasar, {
  config: {
    loadingBar: {
      "skip-hijack": true,
      color: "green",
      position: "top",
      size: "5px",
    },
  },
  components: {
    QTooltip,
    QBtn,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QMenu,
    QDate,
    QAvatar,
    QCircularProgress,
    QLinearProgress,
    QInput,
    QDialog,
    QCard,
    QCardSection,
    QCardActions,
    QSpace,
    QBadge,
    QToolbar,
    QSelect,
    QItemLabel,
    QToolbarTitle,
    QBtnToggle,
    QSeparator,
    QCheckbox,
    QExpansionItem,
    QImg,
    QSpinner,
    QToggle,
    QFab,
    QFabAction,
    QLayout,
    QPageContainer,
    QPage,
    QHeader,
    QFooter,
    QDrawer,
    QChip,
    QTabs,
    QTab,
    QTabPanels,
    QTabPanel,
    QInfiniteScroll,
    QOptionGroup,
    QColor,
    QTable,
    QTh,
    QTr,
    QTd,
    QTime,
    QPopupProxy,
    QPopupEdit,
    QRating,
    QSlider,
    QBar,
    QKnob,
    QSpinnerDots,
    QSkeleton,
    QBanner,
    QRadio,
  },
  directives: {
    Ripple,
    ClosePopup,
    Scroll,
  },
  plugins: {
    Notify,
    LoadingBar,
  },
  iconSet: iconSet,
});
