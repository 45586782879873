import { message as EnMessages } from "./en/index";
import { message as FrMessages } from "./fr/index";

const messages = {
  en: {
    message: EnMessages
  },
  fr: {
    message: FrMessages
  }
};

const i18n = {
  locale: "en",
  messages
};

export default i18n;
