import { TOGGLE_SAVED_ITEMS_BANNER } from "../mutation-types";

const getDefaultState = () => {
  return {
    isBannerVisible: true,
  };
};

const state = getDefaultState();

const getters = {
  isBannerVisible: (state) => {
    return state.isBannerVisible;
  },
};
const mutations = {
  [TOGGLE_SAVED_ITEMS_BANNER]: (state, payload) => {
    state.isBannerVisible = payload;
  },
};

const actions = {
  toggleSavedItemsBanner: ({ commit }, payload) => {
    commit(TOGGLE_SAVED_ITEMS_BANNER, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
