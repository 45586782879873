import { SET_SUBSCRIPTION_PLAN_DATA,SET_IS_DIALOG_SHOW,SET_ALL_FEATURE } from "../mutation-types";

const getDefaultState = () => {
  return {
    subscriptionPlanData: {},
    videoPlayerData : {
        isDialogShow:false,
        videoUrl:'',
        description:''
    },
    allFeatureList:[]
  };
};

const state = getDefaultState();

const getters = {
    subscriptionPlanData:(state)=>{
      return {...state.subscriptionPlanData};
    },
    videoPlayerData:(state)=>{
      return {...state.videoPlayerData};
    },
    allFeatureList:(state)=>{
        return {...state.allFeatureList};
    }
  };
const mutations = {
    [SET_SUBSCRIPTION_PLAN_DATA]: (state, payload) => {
      state.subscriptionPlanData = payload;
    },
    [SET_IS_DIALOG_SHOW]: (state, payload) => {
      state.videoPlayerData = payload;
    },
    [SET_ALL_FEATURE]: (state, payload) => {
      state.allFeatureList = payload;
    },
};
const actions = {
    subscriptionPlanData: ({ commit }, payload) => {
      commit(SET_SUBSCRIPTION_PLAN_DATA, payload);
    },
    videoPlayerData: ({ commit }, payload) => {
      commit(SET_IS_DIALOG_SHOW, payload);
    },
    allFeatureList: ({ commit }, payload) => {
      commit(SET_ALL_FEATURE, payload);
    },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
