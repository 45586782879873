const messages = {
  profileUpdated: "User profile updated",
  passwordUpdated: "Password succesfully changed",
  company: {
    login: "Company login succesfully",
    alreadyLogin: `You're already logged in to company.`,
    create: "Company succesfully created",
    forgot: {
      name: "Team Name(s) were sent to your e-mail account",
      password: "Your password has been succesfully reset",
    },
  },
  invitation: {
    sent: "Invitation sent",
    resent: "Invitation resent",
    revoke: "Invitation revoked",
  },
  companySettingUpdate: "Company Settings updated",
  profileSettingUpdate: "Profile updated",
  accountSettingUpdate: "Password reset successfully",
  notificationSettingUpdate: "Notification settings updated",
  fileupload: {
    dictFileTooBig: "File is too large",
    dictFileTooSmall: "File is too small",
    dictCancelUploadConfirmation: "Are you sure?",
  },
  users: {
    deleteConfirmation:
      "Are you sure you want to delete the user? Once deleted, the user will not be able to join the company anymore.",
  },
  member: {
    searchNoMatchFound: "Could not find member",
  },
  oneCommentAlreadyInEditMod: "One comment is already in process.",
  AccessDenied: "Access Denied",
  coudlNotAssignTaskToMember: "You could not assign the task to this user.",
  UserIsInactive: "Your account is suspended! Contact your administrator.",
  AdminIsInactive:
    "Your account is suspended. Contact Heycollab for further assistance.",
  newPaycardAdded: "Payment added successfully.",
  taskAlreadyStarted: "You cannot have two timers playing at the same time.",
  planDowngraded: "Your plan was successfully downgraded.",
};

export default messages;
