import Vue from "vue";
import VueI18n from "vue-i18n";
import { get } from "lodash";
import "./stylus/main.styl";
import App from "./App";
import router from "./router";
import { store } from "./store";
import lang from "./lang";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import fromUnixTime from "date-fns/fromUnixTime";
import subDays from "date-fns/subDays";
import "@/directives/AutoGrow";
import "@/directives/DragScroll";
import Toasted from "vue-toasted";
import "@/components/VuePlayer/vue-plyr.es.js";
import ApiPlugin from "./ApiPlugin";
import Sortable from "sortablejs";
import "./quasar";
import { pusher } from "./pusher";
import "./icons";
import Plugin from "@quasar/quasar-ui-qoverlay";
import "@quasar/quasar-ui-qoverlay/dist/index.css";
import "./styles/quasar.variables.scss";
import VueDragscroll from "vue-dragscroll";
import { apiUrl } from "@/config";
Vue.use(Plugin);

const copyToclip = function (text, message = null) {
  let eligible = checkEligiblityOfTask("copy_duplicate_tasks");
  if (eligible) {
    const el = document.createElement("textarea");
    el.addEventListener("focusin", (e) => e.stopPropagation());
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.$q.notify({
      classes: "success-notification",
      position: "top-right",
      message: message ? message : "Link copied successfully",
      timeout: 2500,
      icon: this.$icons.matAnnouncement,
      actions: [{ icon: this.$icons.matClose, color: "white" }],
    });
  }
};

const copyContentToClip = function (text) {
  var doc = new DOMParser().parseFromString(text, "text/html");
  var texts = doc.body.innerHTML;
  const el = document.createElement("textarea");
  el.addEventListener("focusin", (e) => e.stopPropagation());
  el.value = texts;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  this.$q.notify({
    classes: "success-notification",
    position: "top-right",
    message: "Text copied successfully",
    timeout: 2500,
    icon: this.$icons.matAnnouncement,
    actions: [{ icon: this.$icons.matClose, color: "white" }],
  });
};

Vue.use(VueDragscroll);

Vue.use(ApiPlugin, {
  debug: true,
  endpoint: process.env.VUE_APP_GRAPH_API,
  headers: () => {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getters.accessToken}`,
      Pusher: pusher.connection.socket_id,
    };
  },
  errorHandler: (errors) => {
    errors.forEach(({ message }) => {
      if (message === "AuthenticationRequired") {
        const companies = store.state.auth.companies;
        const currentCompany = store.state.auth.activeCompany;
        if (companies && currentCompany) {
          let company = get(companies, currentCompany);
          store.dispatch("signoutFromCompany", company.companyUri);
          window.location.reload();
        }
      }
    });
  },
});

Vue.directive("infinite-scroll", {
  bind(el, binding) {
    el.infiniteScrollHandler = () => {
      if (
        el.infiniteScrollLastPosition < el.scrollTop &&
        el.scrollTop + el.clientHeight >= el.scrollHeight - 0.5
      ) {
        binding.value();
      }
      el.infiniteScrollLastPosition = el.scrollTop;
    };
    el.addEventListener("scroll", el.infiniteScrollHandler);
  },
  unbind(el) {
    el.removeEventListener("scroll", el.infiniteScrollHandler);
  },
});
Vue.directive("scroll-hover", {
  bind(el) {
    const threshold = 12;
    el.scrollHoverHandler = (event) => {
      const rect = el.getBoundingClientRect();
      if (
        event.clientX > rect.right - threshold &&
        event.clientX < rect.right
      ) {
        el.classList.add("scrollbar-hover-thumb");
      } else {
        el.classList.remove("scrollbar-hover-thumb");
      }
    };
    el.addEventListener("mousemove", el.scrollHoverHandler);

    el.addEventListener("mouseleave", () => {
      el.classList.remove("scrollbar-hover-thumb");
    });
  },
  unbind(el) {
    el.removeEventListener("mousemove", el.scrollHoverHandler);
  },
});
Vue.directive("sortable", {
  bind(el, binding) {
    el.sortable = Sortable.create(el, {
      ...binding.value,
      // forceFallback: true,
      // fallbackOnBody: true,
      // fallbackTolerance: 5,
      animation: 0,
      scrollSpeed: 20,
      scrollSensitivity: 100,
    });
  },
  unbind(el) {
    el.sortable.destroy();
  },
});

Vue.use(VueI18n);
Vue.use(Toasted);
Vue.config.productionTip = false;
Vue.prototype.$copyTextToClipboard = copyToclip;

Vue.prototype.$copyContentToClip = copyContentToClip;
Vue.prototype.$resizeMedia = (url, width = null, height = null) => {
  return [
    "https://img.heycollab.com",
    "insecure",
    "rt:fill", //resizing type
    "q:75", //quality
    width && `w:${width}`,
    height && `h:${height}`,
    btoa(encodeURI(url)) + ".jpg",
  ]
    .filter(Boolean)
    .join("/");
};

Vue.prototype.$trello = {
  ...window.Trello,
  getToken: function () {
    return localStorage.getItem("trello_token");
  },
};

const checkEligiblityOfTask = (slug) => {
  let allFeatures =
    store.state.subscriptionPlanData.allFeatureList.data.features;
  let data = store.state.subscriptionPlanData.subscriptionPlanData.plan;
  const companies = store.state.auth.companies;
  const currentCompany = store.state.auth.activeCompany;
  let company = get(companies, currentCompany);

  if (company.status.subscriptionOver) {
    let getUrl = allFeatures.filter((o) => {
      return o.key === slug;
    });
    store.dispatch("videoPlayerData", {
      isDialogShow: true,
      videoUrl: getUrl[0].video_url,
      description: getUrl[0].description,
    });
    return false;
  }

  if (data.id > 2) {
    return true;
  } else {
    if (!company.status.trialOver) {
      return true;
    }

    let getData = data.featurePlan.filter((o) => {
      return o.key === slug;
    });
    if (getData.length) {
      return true;
    } else {
      let getUrl = allFeatures.filter((o) => {
        return o.key === slug;
      });
      store.dispatch("videoPlayerData", {
        isDialogShow: true,
        videoUrl: getUrl[0].video_url,
        description: getUrl[0].description,
      });
      return false;
    }
  }
};

const getQueryParameterByName = (name, url) => {
  var match = RegExp("[?&]" + name + "=([^&]*)").exec(url);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};
// program to generate random strings

// declare all characters

const generateRandomString = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
};
const checkSpecificFeature = (slug) => {
  const companies = store.state.auth.companies;
  const currentCompany = store.state.auth.activeCompany;
  let company = get(companies, currentCompany);
  if (!company.status.trialOver) {
    return true;
  }

  let data = store.state.subscriptionPlanData.subscriptionPlanData.plan;

  let getData = data.featurePlan.filter((o) => {
    return o.key === slug;
  });
  if (getData.length) {
    return true;
  } else {
    return false;
  }
};
const mixpanelEvent = (data, object = {}) => {
  let body = {
    type: data,
    object: object,
    subject_id: store.getters.accessToken,
  };

  fetch(`${apiUrl}/api/track-event`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then(() => {});
};

Vue.prototype.$mixpanelEvent = mixpanelEvent;

Vue.prototype.$checkEligiblity = checkEligiblityOfTask;

Vue.prototype.$getQueryParameterByName = getQueryParameterByName;

Vue.prototype.$generateString = generateRandomString;

Vue.prototype.$checkSpecificFeature = checkSpecificFeature;

Vue.prototype.$track = {
  page(path) {
    window.ga("set", "page", path);
    window.ga("send", "pageview");
  },
};
Vue.directive("tooltip", {
  bind(el, binding, vnode) {
    el.tooltipHandler = (evt) => {
      let targetEl = evt.target;
      if (targetEl.offsetWidth < targetEl.scrollWidth) {
        vnode.context.isTruncated = true;
      } else {
        vnode.context.isTruncated = false;
      }
    };
    el.addEventListener("mouseover", el.tooltipHandler);
  },
  unbind(el) {
    el.removeEventListener("mouseover", el.tooltipHandler);
  },
});

// window.componentsCount = {};
// Vue.mixin({
//   created() {
//     console.log("created", this.$options.name);
//     if (window.componentsCount[this.$options.name]) {
//       window.componentsCount[this.$options.name]++;
//     } else {
//       window.componentsCount[this.$options.name] = 1;
//     }
//   },
//   destroyed() {
//     window.componentsCount[this.$options.name]--;
//   },
//   updated() {
//     console.log("updated", this.$options.name);
//   },
// });

Vue.config.optionMergeStrategies.api = function (toVal, fromVal) {
  return { ...toVal, ...fromVal };
};
const emitter = new Vue();

Object.defineProperties(Vue.prototype, {
  $eventBus: {
    get: function () {
      return emitter;
    },
  },
});

Vue.filter("capitalize", function (str) {
  return str && str.charAt(0).toUpperCase() + str.slice(1);
});
Vue.filter("lowercase", function (str) {
  return str.charAt(0).toLowerCase() + str.slice(1);
});
Vue.filter("firstChar", function (str) {
  return str && str.charAt(0).toUpperCase();
});
Vue.filter("first2Char", function (str) {
  return str.substring(0, 2).toUpperCase();
});
Vue.filter("first3Char", function (str) {
  return str.substring(0, 3).toUpperCase();
});
Vue.filter("gettime", function (str) {
  return format(fromUnixTime(str), "h:mm a");
});
Vue.filter("forwardPostTime", function (str) {
  return format(fromUnixTime(str), "MMM. d @h:mma");
});

Vue.filter("truncateWorkspaceTitle", function (str) {
  if (str.length >= 14) {
    return str.substr(0, 11) + "...";
  } else {
    return str;
  }
});

Vue.filter("truncateCompanyTitle", function (str) {
  if (str.length >= 13) {
    return str.substr(0, 11) + "...";
  } else {
    return str;
  }
});

Vue.filter("dateMonthDate", function (str) {
  return format(fromUnixTime(str), "MMM. D");
});

Vue.filter("dateMonthDateTime", function (str) {
  return format(fromUnixTime(str), "EEEE MMM. d @h:mma");
});

Vue.filter("mediaCommentDate", function (str) {
  return format(fromUnixTime(str), "MMM. d @h:mma");
});

Vue.filter("dateMonthDateDefault", function (str) {
  return format(parseISO(str), "MMM. d");
});

Vue.filter("tableDateFormate", function (str) {
  return format(fromUnixTime(str), "MMM. do");
});

Vue.filter("billdate", function (str) {
  return format(fromUnixTime(str), "MMMM do, yyyy");
});

Vue.filter("forwardpostdate", function (str) {
  return format(fromUnixTime(str), "h:mm a | MMMM do, yyyy");
});

Vue.filter("currencyFormat", (str) => str.toFixed(2));
Vue.filter("filesizeFormat", (str) => (str * 0.001).toFixed(3));

Vue.filter("emojiparser", function (str) {
  if (!str) return null;
  const html = str.replace(
    new RegExp("data-class-add", "g"),
    'class="member-tag"'
  );

  return html;
});
Vue.filter("formatPhoneNumber", function (str) {
  const cleaned = ("" + str).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
});
Vue.filter("formatDateTime", function (str) {
  if (
    format(new Date(), "MM/dd/yyyy") === format(fromUnixTime(str), "MM/dd/yyyy")
  ) {
    return format(fromUnixTime(str), "kk:mm");
  } else if (
    format(fromUnixTime(str), "MM/dd/yyyy") ===
    format(subDays(new Date(), 1), "MM/dd/yyyy")
  ) {
    return "Yesterday";
  }
  return format(fromUnixTime(str), "MMM d");
});

const i18n = new VueI18n(lang);
/* eslint-disable no-new */
export const app = new Vue({
  el: "#app",
  i18n,
  store,
  router,
  render: (h) => h(App),
});
