import Vue from "vue";

export default Vue.directive("autoGrow", {
  bind: el => {
    const updateHeight = () => {
      el.style.height = "auto";
      el.style.height = el.scrollHeight + "px";
    };
    Vue.nextTick(() => {
      updateHeight();
    });
    el.addEventListener("input", updateHeight);
    el.$destroy = () => el.removeEventListener("input", updateHeight);
  },
  unbind: el => {
    el.$destroy();
  }
});
