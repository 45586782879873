import {
  SET_INFO_SIDEBAR_ACTIVE_TAB,
  SET_TASK_SIDEBAR_ACTIVE_TAB,
  SET_DRAWER_STATE,
  SET_MEMBER_EXPANSION_STATE,
  SET_DESCRIPTION_EXPANSION_STATE,
  SET_MY_TASK_TAB_CARD_EXPANSION_STATE,
  SET_WORKSPACE_NOTIFICATION_SETTING_EXPANSION_STATE,
  SET_ACTIVITY_LOG_EXPANSION_STATE,
  SET_FOLDER_STATE,
  TOGGLE_ADD_MEMBER_BANNER,
} from "../mutation-types";

const getDefaultState = () => {
  return {
    infoSidebar: {},
    taskSidebar: {},
    drawerRight: null,
    myTaskTab: {},
    foldersState: {},
    isAddMemberBannerVisible: true,
  };
};
const state = getDefaultState();

const getters = {
  drawerState: (state) => {
    return state.drawerRight;
  },
  activeInfoTab: (state) => (workspaceId) => {
    const key = `Workspace-${workspaceId}`;
    return state.infoSidebar[key];
  },
  activeTaskTab: (state) => (workspaceId) => {
    const key = `Workspace-${workspaceId}`;
    return state.taskSidebar[key];
  },
  isMemberExpanded: (state) => (workspaceId) => {
    const key = `Workspace-${workspaceId}`;
    return state.infoSidebar[key]
      ? state.infoSidebar[key].isMemberExpanded
      : false;
  },
  isDescriptionExpanded: (state) => (workspaceId) => {
    const key = `Workspace-${workspaceId}`;
    return state.infoSidebar[key]
      ? state.infoSidebar[key].isDescriptionExpanded
      : false;
  },
  isWorkspaceNotificationExpanded: (state) => (workspaceId) => {
    const key = `Workspace-${workspaceId}`;
    return state.infoSidebar[key]
      ? state.infoSidebar[key].isWorkspaceNotificationExpanded
      : false;
  },
  isActivityLogExpanded: (state) => (workspaceId) => {
    const key = `Workspace-${workspaceId}`;
    return state.infoSidebar[key]
      ? state.infoSidebar[key].isActivityLogExpanded
      : false;
  },
  isMyTaskCardExpanded: (state) => (workspaceId, cardId) => {
    const key = `Workspace-${workspaceId}-Card-${cardId}`;
    return state.myTaskTab[key];
  },
  isFolderOpen: (state) => (folderId) => {
    const key = `Folder-${folderId}`;
    return state.foldersState[key];
  },
  isAddMemberBannerVisible: (state) => {
    return state.isAddMemberBannerVisible;
  },
};

const mutations = {
  [SET_DRAWER_STATE]: (state, payload) => {
    state.drawerRight = payload;
  },
  [SET_INFO_SIDEBAR_ACTIVE_TAB]: (state, payload) => {
    const infoSidebar = state.infoSidebar;
    const key = `Workspace-${payload.workspaceId}`;
    infoSidebar[key] = {
      ...infoSidebar[key],
      infoSidebarActiveTab: payload.flag,
    };
    state.infoSidebar = {
      ...infoSidebar,
    };
  },
  [SET_TASK_SIDEBAR_ACTIVE_TAB]: (state, payload) => {
    const taskSidebar = state.taskSidebar;
    const key = `Workspace-${payload.workspaceId}`;
    taskSidebar[key] = {
      ...taskSidebar[key],
      taskSidebarActiveTab: payload.flag,
    };
    state.taskSidebar = {
      ...taskSidebar,
    };
  },
  [SET_MEMBER_EXPANSION_STATE]: (state, payload) => {
    const infoSidebar = state.infoSidebar;
    const key = `Workspace-${payload.workspaceId}`;
    infoSidebar[key] = {
      ...infoSidebar[key],
      isMemberExpanded: payload.flag,
    };
    state.infoSidebar = {
      ...infoSidebar,
    };
  },
  [SET_DESCRIPTION_EXPANSION_STATE]: (state, payload) => {
    const infoSidebar = state.infoSidebar;
    const key = `Workspace-${payload.workspaceId}`;
    infoSidebar[key] = {
      ...infoSidebar[key],
      isDescriptionExpanded: payload.flag,
    };
    state.infoSidebar = {
      ...infoSidebar,
    };
  },
  [SET_WORKSPACE_NOTIFICATION_SETTING_EXPANSION_STATE]: (state, payload) => {
    const infoSidebar = state.infoSidebar;
    const key = `Workspace-${payload.workspaceId}`;
    infoSidebar[key] = {
      ...infoSidebar[key],
      isWorkspaceNotificationExpanded: payload.flag,
    };
    state.infoSidebar = {
      ...infoSidebar,
    };
  },
  [SET_ACTIVITY_LOG_EXPANSION_STATE]: (state, payload) => {
    const infoSidebar = state.infoSidebar;
    const key = `Workspace-${payload.workspaceId}`;
    infoSidebar[key] = {
      ...infoSidebar[key],
      isActivityLogExpanded: payload.flag,
    };
    state.infoSidebar = {
      ...infoSidebar,
    };
  },
  [SET_MY_TASK_TAB_CARD_EXPANSION_STATE]: (state, payload) => {
    const myTaskTab = state.myTaskTab;
    const key = `Workspace-${payload.workspaceId}-Card-${payload.cardId}`;
    myTaskTab[key] = {
      ...myTaskTab[key],
      isExpanded: payload.flag,
    };
    state.myTaskTab = {
      ...myTaskTab,
    };
  },
  [SET_FOLDER_STATE]: (state, payload) => {
    const foldersState = state.foldersState;
    const key = `Folder-${payload.folderId}`;
    foldersState[key] = {
      ...foldersState[key],
      isOpen: payload.flag,
    };
    state.foldersState = {
      ...foldersState,
    };
  },
  [TOGGLE_ADD_MEMBER_BANNER]: (state, payload) => {
    state.isAddMemberBannerVisible = payload;
  },
};

const actions = {
  setDrawerState: ({ commit }, payload) => {
    commit(SET_DRAWER_STATE, payload);
  },
  setInfoSidebarActiveTab: ({ commit }, payload) => {
    commit(SET_INFO_SIDEBAR_ACTIVE_TAB, payload);
  },
  setTaskSidebarActiveTab: ({ commit }, payload) => {
    commit(SET_TASK_SIDEBAR_ACTIVE_TAB, payload);
  },
  setMemberExpansionState: ({ commit }, payload) => {
    commit(SET_MEMBER_EXPANSION_STATE, payload);
  },
  setDescriptionExpansionState: ({ commit }, payload) => {
    commit(SET_DESCRIPTION_EXPANSION_STATE, payload);
  },
  setWorkspaceNotificationSettingExpansionState: ({ commit }, payload) => {
    commit(SET_WORKSPACE_NOTIFICATION_SETTING_EXPANSION_STATE, payload);
  },
  setActivityLogExpansionState: ({ commit }, payload) => {
    commit(SET_ACTIVITY_LOG_EXPANSION_STATE, payload);
  },
  setMyTaskTabCardExpansionState: ({ commit }, payload) => {
    commit(SET_MY_TASK_TAB_CARD_EXPANSION_STATE, payload);
  },
  setFolderState: ({ commit }, payload) => {
    commit(SET_FOLDER_STATE, payload);
  },
  toggleAddMemberBanner: ({ commit }, payload) => {
    commit(TOGGLE_ADD_MEMBER_BANNER, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
