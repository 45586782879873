import Vue from "vue";
import { evaActivityOutline } from "@quasar/extras/eva-icons";
import {
  mdiDotsHorizontal,
  mdiCircleEditOutline,
  mdiExitToApp,
  mdiAt,
  mdiDeleteOutline,
  mdiCommentPlus,
  mdiPlaylistEdit,
  mdiChevronRightBoxOutline,
  mdiChevronLeftBoxOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClockFast,
  mdiCheckBold,
  mdiAccountMultiple,
  mdiCommentCheck,
  mdiFileMultiple,
  mdiHelp,
  mdiHelpCircle,
  mdiFolderOutline,
  mdiFolderPlus,
  mdiFolderOpen,
  mdiInformationVariant,
  mdiInformationOutline,
  mdiCursorMove,
  mdiContentCopy,
  mdiFolderPlusOutline,
  mdiSchool,
  mdiDownload,
  mdiMagnifyPlusOutline,
  mdiMagnifyMinusOutline,
  mdiCloseThick,
  mdiAccount,
  mdiDragVertical,
  mdiClipboardTextMultipleOutline,
  mdiChevronDoubleUp,
  mdiPound,
  mdiTrello,
  mdiSlack,
  mdiFormatListBulletedSquare,
  mdiWindowMinimize,
  mdiToggleSwitchOffOutline,
  mdiToggleSwitchOutline,
  mdiChevronDoubleLeft,
  mdiPlusThick,
  mdiPlus,
  mdiChevronDoubleRight,
  mdiBell,
  mdiBellOutline,
  mdiCheckOutline,
  mdiAccountMultipleOutline,
  mdiPlay,
  mdiUndo,
  mdiRedo,
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiFormatStrikethrough,
  mdiFormatSubscript,
  mdiFormatSuperscript,
  mdiFormatAlignLeft,
  mdiFormatAlignRight,
  mdiFormatAlignCenter,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiCheckboxMarkedOutline,
  mdiLinkVariant,
  mdiFormatQuoteClose,
  mdiCodeTags,
  mdiCodeNotEqualVariant,
  mdiBorderHorizontal,
  mdiTable,
  mdiKeyboard,
  mdiEmailOutline,
  mdiSquareEditOutline,
  mdiPlusBox,
  mdiChevronRightCircleOutline,
  mdiDockLeft,
  mdiArrowRight,
  mdiArrowLeft,
  mdiPlusBoxOutline,
  mdiAttachment,
  mdiArrowExpand,
  mdiFileUpload,
  mdiFileVideoOutline,
} from "@quasar/extras/mdi-v5";
import {
  matLock,
  matLockOpen,
  matPeople,
  matFavorite,
  matFavoriteBorder,
  matInfo,
  matFilterList,
  matTextsms,
  matFiberManualRecord,
  matAdd,
  matSearch,
  matMoreVert,
  matCheckCircle,
  matCheck,
  matPersonAdd,
  matAnnouncement,
  matClose,
  matBookmark,
  matBookmarkBorder,
  matDashboard,
  matReportProblem,
  matAccessTime,
  matEmail,
  matPhone,
  matSend,
  matLocationOn,
  matAddCircle,
  matAddCircleOutline,
  matMail,
  matLocalOffer,
  matArrowBack,
  matEdit,
  matDeleteForever,
  matBackup,
  matArrowDropDown,
  matDone,
  matViewList,
  matHelp,
  matPhotoCamera,
  matPlayCircleFilled,
  matComputer,
  matImage,
  matVideoCall,
  matMoreHoriz,
  matFormatBold,
  matFormatItalic,
  matTagFaces,
  matMusicNote,
  matPlayCircleOutline,
  matSentimentSatisfied,
  matRemoveCircle,
  matKeyboardArrowDown,
  matDescription,
  matDragHandle,
  matBorderColor,
  matDelete,
  matComment,
  matQuestionAnswer,
  matKeyboardArrowUp,
  matDeleteSweep,
  matNotificationsOff,
  matRemoveRedEye,
  matNotifications,
  matNotificationsActive,
  matGrade,
  matPlaylistAddCheck,
  matVerticalAlignBottom,
  matPerson,
  matDateRange,
  matTimer,
  matCreate,
  matStop,
  matPlayArrow,
  matPauseCircleFilled,
  matPeopleOutline,
  matToday,
  matKeyboardArrowRight,
  matSettings,
  matSchedule,
  matLink,
  matPalette,
  matFolder,
  matAccountCircle,
  matTune,
  matChatBubble,
  matSms,
  matBusinessCenter,
  matArrowForward,
  matPlaylistAdd,
  matArrowUpward,
  matArrowDropUp,
  matOpenInNew,
  matPriorityHigh,
  matFlag,
  matCropSquare,
  matLaunch,
  matFullscreenExit,
  matFullscreen,
  matAttachMoney,
  matVisibility,
  matVisibilityOff,
  matFileCopy,
  matAddBox,
} from "@quasar/extras/material-icons";
import {
  fasBullhorn,
  fasOutdent,
  fasCheck,
  fasImage,
  fabSkype,
  fasFile,
  fasCopy,
  fabGoogleDrive,
  fabDropbox,
  fasPlus,
  fasPaperPlane,
  fasCloudDownloadAlt,
  fasReply,
  fasCheckCircle,
  fasPaperclip,
  fasShare,
  fasCalendarAlt,
  fasTrashAlt,
  fasTrash,
  fasSortDown,
  fasMinus,
  fasCompressAlt,
  fasAngleLeft,
  fasAngleRight,
  fasAt,
  fasComment,
  fasDotCircle,
  fasQuestion,
  fasTasks,
  fasThumbtack,
  fasUserCircle,
  fasUser,
  fasBriefcase,
  fasCalendar,
} from "@quasar/extras/fontawesome-v5";
Vue.prototype.$icons = {
  //Eva Icons
  evaActivityOutline,

  //Material Design Icons
  mdiDotsHorizontal,
  mdiCircleEditOutline,
  mdiExitToApp,
  mdiAt,
  mdiDeleteOutline,
  mdiCommentPlus,
  mdiPlaylistEdit,
  mdiCheckBold,
  mdiAccountMultiple,
  mdiCommentCheck,
  mdiFileMultiple,
  mdiHelp,
  mdiHelpCircle,
  mdiFolderOutline,
  mdiFolderPlus,
  mdiFolderOpen,
  mdiInformationVariant,
  mdiInformationOutline,
  mdiCursorMove,
  mdiContentCopy,
  mdiFolderPlusOutline,
  mdiSchool,
  mdiDownload,
  mdiMagnifyPlusOutline,
  mdiMagnifyMinusOutline,
  mdiCloseThick,
  mdiAccount,
  mdiDragVertical,
  mdiClipboardTextMultipleOutline,
  mdiChevronRight,
  mdiChevronDoubleUp,
  mdiPound,
  mdiTrello,
  mdiSlack,
  mdiFormatListBulletedSquare,
  mdiToggleSwitchOffOutline,
  mdiToggleSwitchOutline,
  mdiChevronDoubleLeft,
  mdiPlusThick,
  mdiPlus,
  mdiChevronDoubleRight,
  mdiBell,
  mdiBellOutline,
  mdiCheckOutline,
  mdiAccountMultipleOutline,
  mdiPlay,
  mdiUndo,
  mdiRedo,
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiFormatStrikethrough,
  mdiFormatSubscript,
  mdiFormatSuperscript,
  mdiFormatAlignLeft,
  mdiFormatAlignRight,
  mdiFormatAlignCenter,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiCheckboxMarkedOutline,
  mdiLinkVariant,
  mdiFormatQuoteClose,
  mdiCodeTags,
  mdiCodeNotEqualVariant,
  mdiBorderHorizontal,
  mdiTable,
  mdiKeyboard,
  mdiEmailOutline,
  mdiSquareEditOutline,
  mdiPlusBox,
  mdiChevronRightCircleOutline,
  mdiDockLeft,
  mdiArrowRight,
  mdiArrowLeft,
  mdiPlusBoxOutline,
  mdiAttachment,
  mdiArrowExpand,
  mdiFileUpload,
  mdiFileVideoOutline,
  //Matrial Icons
  matLock,
  matLockOpen,
  matFavorite,
  matFavoriteBorder,
  matInfo,
  matFilterList,
  matPeople,
  matTextsms,
  matAdd,
  matSearch,
  matMoreVert,
  matFiberManualRecord,
  matCheckCircle,
  matCheck,
  matPersonAdd,
  matAnnouncement,
  matClose,
  matBookmark,
  matBookmarkBorder,
  matReportProblem,
  matDashboard,
  matAddCircle,
  matAddCircleOutline,
  matMail,
  matLocationOn,
  matAccessTime,
  matEmail,
  matPhone,
  matSend,
  matArrowBack,
  matLocalOffer,
  matEdit,
  matDeleteForever,
  matBackup,
  matArrowDropDown,
  matDone,
  matViewList,
  matHelp,
  matPhotoCamera,
  matPlayCircleFilled,
  matStop,
  matComputer,
  matImage,
  matVideoCall,
  matMoreHoriz,
  matFormatBold,
  matFormatItalic,
  matTagFaces,
  matMusicNote,
  matPlayCircleOutline,
  matSentimentSatisfied,
  matRemoveCircle,
  matKeyboardArrowDown,
  matDescription,
  matDragHandle,
  matBorderColor,
  matDelete,
  matComment,
  matQuestionAnswer,
  matKeyboardArrowUp,
  matDeleteSweep,
  matNotificationsOff,
  matRemoveRedEye,
  matNotifications,
  matNotificationsActive,
  matGrade,
  matPlaylistAddCheck,
  matVerticalAlignBottom,
  mdiChevronRightBoxOutline,
  mdiChevronLeftBoxOutline,
  mdiChevronLeft,
  mdiClockFast,
  matPerson,
  matDateRange,
  matTimer,
  matCreate,
  matPlayArrow,
  matPauseCircleFilled,
  matPeopleOutline,
  matToday,
  matKeyboardArrowRight,
  matSettings,
  matSchedule,
  matLink,
  matPalette,
  matFolder,
  matAccountCircle,
  matTune,
  matChatBubble,
  matSms,
  matBusinessCenter,
  matArrowForward,
  matPlaylistAdd,
  matArrowUpward,
  matArrowDropUp,
  matOpenInNew,
  matPriorityHigh,
  matFlag,
  //FontAwseome
  fasBullhorn,
  fasOutdent,
  fasCheck,
  fasImage,
  fabSkype,
  fasFile,
  fasCopy,
  fabGoogleDrive,
  fabDropbox,
  fasPlus,
  fasPaperPlane,
  fasCloudDownloadAlt,
  fasReply,
  fasCheckCircle,
  fasPaperclip,
  fasShare,
  fasCalendarAlt,
  fasTrashAlt,
  fasTrash,
  fasSortDown,
  fasMinus,
  fasCompressAlt,
  fasAngleLeft,
  fasAngleRight,
  fasAt,
  fasComment,
  fasDotCircle,
  fasQuestion,
  fasTasks,
  mdiWindowMinimize,
  matCropSquare,
  matLaunch,
  matFullscreenExit,
  matFullscreen,
  matAttachMoney,
  fasThumbtack,
  matVisibility,
  matVisibilityOff,
  fasUserCircle,
  fasUser,
  fasBriefcase,
  matFileCopy,
  matAddBox,
  fasCalendar,
};
