const exception = {
  WorkspaceTitleAlreadyTaken: "Workspace title already taken",
  InvalidWorkspaceTitleError: "Workspace title is invalid",
  UserNotFoundError: "E-mail address does not exist with the company",
  CompanyNotFoundError: "Company does not exist",
  CompanyURIAlreadyTaken: "Company URL already taken",
  CompanyURIInvalid: "Company URL is invalid",
  InvalidUsername: "Username is invalid",
  InvalidVerificationCodeError: "Verification code is invalid",
  InvitationNotFound: "No Invitation found",
  PermissionDenied: "You have not permission",
  UserAlreadyExists: "User is already exist",
  UsernameAlreadyTaken: "Username is already taken"
};

export default exception;
