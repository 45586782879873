<template>
  <div>
    <q-overlay v-model="showOverlay" background-color="rgba(66, 82, 110, 0.7)">
      <router-view
        @minimiseNaviagationSidebar="setNavaigationSidebarState"
      ></router-view>
    </q-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { get } from "lodash";
import { CompanyQuery, CheckCompanyStatusMutation } from "@/gql";

export default {
  name: "app",
  api: {
    company: {
      query: CompanyQuery,
      cacheKey: "CompanyQuery",
      defaultValue: null,
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  mounted() {
    const isElectron = navigator.userAgent.indexOf("Electron") !== -1;

    if (isElectron) document.body.classList.add("is-electron");
    this.checkCompanyStatus();
    const oldDragBox = document.querySelector("body > div[style*=app-region]");
    if (oldDragBox) {
      oldDragBox.remove();
    }
  },
  data() {
    return {
      hello: "",
      navigationDrawerState: "",
    };
  },
  beforeCreate() {
    this.$eventBus.$off("checkCompanyStatus");
  },
  created() {
    this.$eventBus.$on("checkCompanyStatus", this.checkCompanyStatus);
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    getPopperElement() {
      let elem = document.getElementById("anchorEl-workspace-0");
      return elem;
    },
    showOverlay() {
      return (
        this.user &&
        this.user.onboarding_step > 0 &&
        this.user.onboarding_step < 4 &&
        this.navigationDrawerState !== "mini"
      );
    },
  },
  beforeMount() {
    this.hello = setInterval(() => {
      if (this.$route.params.company) {
        this.checkCompanyStatus();
        // 2. If API says company is active then redirect to inner page
        // In case, someone has opent this page, we need to check if card is already exists
        // then don't charge.Replace the card. @Hitaishi

        // 3 API says company is inactive the force to PaymentView and set value in Vuex.
        // So User press back button without payment then we can throw him back on PaymentView

        // 4. Logout Member
        // 5. Lock the Admin (Company Owner)
      }
    }, 600000);
  },
  beforeDestroy() {
    clearInterval(this.hello);
  },
  methods: {
    setNavaigationSidebarState(value) {
      this.navigationDrawerState = value;
    },
    async checkCompanyStatus() {
      const activeCompanyObj = get(this.companies, this.activeCompany);
      if (activeCompanyObj) {
        try {
          const res = await this.$api.mutate({
            mutation: CheckCompanyStatusMutation,
          });

          if (res.errors && res.errors[0].message === "UserIsInactive") {
            this.signOut({ companyUri: this.activeCompany });
            return false;
          }
          const status = {
            isActive: res.data.checkCompanyStatus.company_status === "active",
            subscriptionOver: res.data.checkCompanyStatus.subscription_over,
            trialOver: res.data.checkCompanyStatus.trial_over,
            graceHours: res.data.checkCompanyStatus.grace_hours,
            hasPaycard: res.data.checkCompanyStatus.hasPaycard,
          };
          this.$store.dispatch("companyStatus", status);
          this.$store.dispatch(
            "companyStorage",
            res.data.checkCompanyStatus.used_storage
          );

          if (
            res &&
            !status.isActive &&
            this.company &&
            this.company.susubscription.status !== "free"
          ) {
            this.$router.push({
              name: "PaymentView",
            });
          } else if (
            this.$route.name === "PaymentView" &&
            activeCompanyObj.status.isActive === true
          ) {
            this.$router.push(`/companies/${this.activeCompany}/1/messaging`);
          }
          // If company is on & is payment route then redirect to in
        } catch (error) {
          console.log("error", error);
          if (error.graphQLErrors[0].message === "UserIsInactive") {
            this.signOut({ companyUri: this.activeCompany });
          }
        }
      }
    },
    async signOut(data) {
      if (data.companyUri === this.activeCompany) {
        this.$router.push({
          name: "SigninView",
        });
      }
      // TODO test logout on socket
      this.$store.dispatch("signoutFromCompany", data.companyUri);
    },
  },
};
</script>
