const validation = {
  required: "Required",
  email: {
    required: "Please enter a valid e-mail address",
    email: "Email must be valid",
    alreadyExist: "Email is already register",
    notExist: "This email is not registered with us",
  },
  password: {
    required: "Password is required",
    confirm: "Password does not match",
    min: "At least 8 characters required",
    max: "Max 128 characters",
    validPassword: "Password security score is very low",
    lowscorePassword: "Password security score is very low",
  },
  username: {
    required: "Username field is required",
    uniqueUser: "Username must be valid",
    min: "Username must have at least 4 characters",
    max: "Username must not be more than 40 characters",
    alreadyExist: "Username is already taken",
  },
  verificationCode: {
    required: "Verification code is required",
  },
  userprofile: {
    first: {
      required: "First name is required",
      firstname: "First Name must be valid",
      min: "Must have at least two characters",
      max: "Must not be more than 60 characters",
    },
    last: {
      required: "Last name is required",
      lastname: "Last Name must be valid",
      min: "Must have at least two characters",
      max: "Not more than 60 characters",
    },
    phone: {
      maxLength: "Max 15 characters",
      minLength: "At least 7 digits are required",
      valid: "Phone number must be valid",
    },
    skype: {
      maxLength: "Max 32 characters",
      minLength: "At least 2 characters are required",
    },
    professional_title: {
      maxLength: "Max 60 characters",
    },
    country: {
      valid: `Invalid country name.`,
      maxLength: "Max 120 characters",
      minLength: "At least 2 characters are required",
    },
    city: {
      valid: `Invalid city name.`,
      maxLength: "Max 120 characters",
      minLength: "At least 2 characters are required",
    },
  },
  questions: {
    usage: { required: "Required" },
    kind: { required: "Required" },
    strength: { required: "Required" },
  },
  company: {
    name: {
      required: "Company name is required field",
      min: "Company name must have at least two characters",
      max: "Company name is not more than 64 characters",
    },
    uri: {
      required: "Team Name is a required field",
      //validurl: "Team Name can only have alphabets and numbers",
      validurl:
        "Team name can have alphanumeric(a-z A-z 0-9) and dash(-) between characters only",
      min: "Team Name must have at least two characters",
      max: "Team Name is not more than 40 characters",
    },
    agreeToPolicy: {
      required: "Please agree to policy",
    },
    notExist: "Company does not exist.",
  },
  workspace: {
    name: {
      required: "Workspace name is required",
      max: "Maximun characters 32",
      valid:
        "Workspace name can have alphanumeric(a-z A-z 0-9) and dash(-) between characters only",
    },
    description: {
      max: "Maximun characters 3000",
    },
  },
  credentialNotMatch: "Could not match credentials",
  memberInactive:
    "Your account is inactive. Please contact your administrator.",
  ownerInactive:
    "Your account is suspended. Please contact Heycollab for further information.",
  lang: {
    required: "Choose your language",
  },
  file: {
    size: "File is too large",
    profileSize: "Image size must be less than 25MB",
  },
  cardinfo: {
    nameoncard: {
      required: "Name on card is required",
      minLength: "Name on card must have at least 2 characters",
      maxLength: "Name on card must not be more than 40 characters",
    },
    cardnumber: {
      required: "Card number is required",
      minLength: "Card number must have at least 17 characters",
      maxLength: "Card number is not more than 17 characters",
    },
    expiredate: {
      required: "Expiry is required",
      minLength: "Expiry must have at least 4 characters",
      maxLength: "Expiry is not more than 4 characters",
    },
    cvv: {
      required: "CVV is required",
      minLength: "Minimum 3 digits",
      maxLength: "Maximum 3 digits",
    },
    zipcode: {
      required: "Zip code is required",
      minLength: "Zip code must have at least 4 characters",
      maxLength: "Zip code is not more than 10 characters",
    },
  },
  billingaddress: {
    street_one: {
      required: "Address is required",
      minLength: "Address must have at least 2 characters",
      maxLength: "Address must not be more than 40 characters",
    },
    city: {
      required: "City is required",
      minLength: "Minimum 2 characters",
      maxLength: "Maximum 50 characters",
    },
    zipcode: {
      required: "Zipcode is required",
      minLength: "Minimum 5 characters",
      maxLength: "Maximum 15 characters",
    },
    state: {
      required: "State is required",
      minLength: "State must have at least 2 characters",
      maxLength: "State is not more than 15 characters",
    },
    country: {
      required: "Country is required",
      minLength: "Country must have at least 2 characters",
      maxLength: "Country is not more than 20 characters",
    },
  },
  invalidCouponCode: "Coupon code is not valid",
  task: {
    required: "Please enter a valid task name",
  },
  template: {
    required: "Please enter a valid template name",
  },
  bookmark: {
    title: {
      required: "Bookmark is required",
    },
    url: {
      required: "URL is required",
      url: "Please enter a valid URL",
    },
  },
  url: {
    required: "Field can not be empty",
    url: "URL is not valid",
  },
};

export default validation;
