import { isObject, isArray, mapValues, map } from "lodash";

export default {
  install(Vue, options = {}) {
    const { debug, endpoint, headers, errorHandler } = options;
    const log = debug ? console.log : () => {};

    const queries = Vue.observable({});
    const entities = Vue.observable({});

    function lazyValue(value, thisArg) {
      return typeof value === "function" ? value.call(thisArg) : value;
    }

    function getQuery(cacheKey) {
      const emptyQuery = {
        data: undefined,
        errors: undefined,
        loading: false,
      };

      if (queries[cacheKey] === undefined) {
        Vue.set(queries, cacheKey, emptyQuery);
      }

      return queries[cacheKey];
    }

    function getEntity(type, id) {
      try {
        return entities[type][id];
      } catch (error) {
        return undefined;
      }
    }

    function setEntity(type, id, data = {}) {
      if (entities[type] === undefined) {
        Vue.set(entities, type, {});
      }
      Vue.set(entities[type], id, data);
    }

    function updateEntity(type, id, data) {
      const entity = getEntity(type, id);

      if (entity) {
        Object.keys(data).forEach((key) => {
          if (entity[key] !== data[key]) {
            Vue.set(entity, key, data[key]);
          }
        });
      } else {
        setEntity(type, id, data);
      }
    }

    function normalize(data) {
      if (isArray(data)) {
        return map(data, normalize);
      }

      if (isObject(data)) {
        const { __typename: type, id } = data;
        const newData = mapValues(data, normalize);

        if (type && id !== undefined) {
          updateEntity(type, id, newData);
          return getEntity(type, id);
        } else {
          return newData;
        }
      }

      return data;
    }

    async function fetchApi({ query, variables = {}, skipUpdates = false }) {
      const response = await fetch(lazyValue(endpoint), {
        method: "POST",
        headers: lazyValue(headers),
        body: JSON.stringify({ query, variables }),
      });

      const { data, errors } = await response.json();

      if (errors) errorHandler(errors);

      return skipUpdates
        ? { data, errors }
        : {
            data: normalize(data),
            errors,
          };
    }

    Vue.mixin({
      beforeCreate() {
        const { $options, $parent } = this;
        $options.computed = $options.computed || {};

        // add cache objects to $root node
        if ($parent === undefined) {
          $options.computed["queries"] = () => queries;
          $options.computed["entities"] = () => entities;
        }

        const getQueryByName = (queryName) => {
          try {
            const config = this.$options.api[queryName];
            const cacheKey = lazyValue(config.cacheKey, this);
            return getQuery(cacheKey);
          } catch (error) {
            return undefined;
          }
        };

        const query = async (config) => {
          const cacheKey = lazyValue(config.cacheKey, this);
          const variables = lazyValue(config.variables, this);

          if (cacheKey === undefined) {
            return fetchApi({ ...config, variables });
          }

          const query = getQuery(cacheKey);
          query.loading = true;

          const response = await fetchApi({ ...config, variables });

          query.data = response.data;
          query.errors = response.errors;
          query.loading = false;

          return response;
        };

        const mutate = (config) => {
          const variables = lazyValue(config.variables, this);
          config.query = config.mutation;
          delete config.mutation;
          return fetchApi({ ...config, variables });
        };

        // add methods
        this.$api = {
          query,
          mutate,
          getQuery,
          getQueryByName,
          getEntity,
          setEntity,
          updateEntity,
        };

        if ($options.api === undefined) return;

        for (const queryName in $options.api) {
          const config = $options.api[queryName];

          if (config.cacheKey === undefined) {
            throw new Error("`cacheKey` property must be defined");
          }

          $options.computed[queryName] = function() {
            const query = this.$api.getQueryByName(queryName);

            if (query.data === undefined)
              return lazyValue(config.defaultValue, this);

            const values = Object.values(query.data);
            return values.length === 1 ? values[0] : query.data;
          };

          $options.computed[`${queryName}Loading`] = function() {
            const query = this.$api.getQueryByName(queryName);

            return query.data !== undefined ? false : query.loading;
          };
        }
      },
      created() {
        const { $options } = this;
        if ($options.api === undefined) return;
        for (const queryName in $options.api) {
          const config = $options.api[queryName];

          // skip fetch if query is not provided
          if (config.query === undefined) continue;

          const watcher = () => lazyValue(config.variables, this);
          const handler = () => {
            log("fetching", queryName, this.$options.__file);
            this.$api.query(config);
          };

          this.$watch(watcher, handler, { deep: true, immediate: true });
        }
      },
    });
  },
};
