import { SET_MY_WORK_CARD_EXPANSION_STATE } from "../mutation-types";

const getDefaultState = () => {
  return {
    myWorkCardExpansion: {},
  };
};
const state = getDefaultState();

const getters = {
  isMyWorkCardExpanded: (state) => (userId, cardId) => {
    const key = `Mywork-${userId}-${cardId}`;
    return state.myWorkCardExpansion[key];
  },
};

const mutations = {
  [SET_MY_WORK_CARD_EXPANSION_STATE]: (state, payload) => {
    const myWorkCardExpansion = state.myWorkCardExpansion;
    const key = `Mywork-${payload.userId}-${payload.cardId}`;
    myWorkCardExpansion[key] = {
      ...myWorkCardExpansion[key],
      isExpanded: payload.flag,
    };
    state.myWorkCardExpansion = {
      ...myWorkCardExpansion,
    };
  },
};

const actions = {
  setMyWorkCardExpansionState: ({ commit }, payload) => {
    commit(SET_MY_WORK_CARD_EXPANSION_STATE, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
