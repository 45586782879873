const messages = {
  profileUpdated: "User profile updated",
  passwordUpdated: "Password succesfully changed",
  company: {
    login: "Company login succesfully",
    alreadyLogin: `You're already logged in to company.`,
    create: "Company succesfully created",
    forgot: {
      name: "Company name(s) were sent to your e-mail account",
      password: "Your password has been succesfully reset"
    }
  },
  invitation: {
    sent: "Invitation sent",
    resent: "Invitation resent",
    revoke: "Invitation revoked"
  },
  companySettingUpdate: "Company Settings updated",
  profileSettingUpdate: "Profile updated",
  accountSettingUpdate: "Password reset successfully",
  notificationSettingUpdate: "Notification settings updated",
  fileupload: {
    dictFileTooBig: "File is too large",
    dictFileTooSmall: "File is too small",
    dictCancelUploadConfirmation: "Are you sure?"
  }
};

export default messages;
