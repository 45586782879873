import {
  SET_WHATS_NEW_NOTIFICATION_ON,
  SET_WHATS_NEW_NOTIFICATION_OFF
} from "../mutation-types";

const getDefaultState = () => {
  return {
    flag: false
  };
};

const state = getDefaultState();

const getters = {
  disablewhatsnewnotification: state => {
    return {
      flag: state.flag
    };
  }
};

const mutations = {
  [SET_WHATS_NEW_NOTIFICATION_OFF]: state => {
    state.flag = true;
  },
  [SET_WHATS_NEW_NOTIFICATION_ON]: state => {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  turnOffNotification: ({ commit }) => {
    commit(SET_WHATS_NEW_NOTIFICATION_OFF);
  },
  turnOnfNotification: ({ commit }) => {
    commit(SET_WHATS_NEW_NOTIFICATION_ON);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
